import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import {isPlainObject} from 'lodash'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'

import {cssPropType, classNamePropType, childrenPropType} from 'util/propTypes'
import {BreakpointSwitch} from 'style/mediaQueries'
import {fmapValuesWithKey} from 'util/fp'
import AudioHighlight from 'components/AudioHighlight'

const triangleSize = 10

const getCornerStyles = ({corner, backgroundColor, offset = 0}) => {
  if (!corner) return {}
  return {
    bottomRight: {
      borderBottomRightRadius: offset > 0 ? undefined : 0,
      '&:before': {
        borderLeftColor: backgroundColor,
        borderBottomColor: backgroundColor,
        right: -(2 * triangleSize),
        bottom: offset,
      },
    },
    bottomLeft: {
      borderBottomLeftRadius: offset > 0 ? undefined : 0,
      '&:before': {
        borderRightColor: backgroundColor,
        borderBottomColor: backgroundColor,
        left: -(2 * triangleSize),
        bottom: offset,
      },
    },
    rightBottom: {
      borderBottomRightRadius: offset > 0 ? undefined : 0,
      '&:before': {
        borderRightColor: backgroundColor,
        borderTopColor: backgroundColor,
        bottom: -(2 * triangleSize),
        right: offset,
      },
    },
    leftBottom: {
      borderBottomLeftRadius: offset > 0 ? undefined : 0,
      '&:before': {
        borderLeftColor: backgroundColor,
        borderTopColor: backgroundColor,
        bottom: -(2 * triangleSize),
        left: offset,
      },
    },
  }[corner]
}

const Contents = posed.div({
  enter: {scale: 1, opacity: 1, transition: {duration: 100}},
  preEnter: {scale: 0.93, opacity: 0},
})

const SingleSpeechBubble = flow(
  addProps(({text, audioClipKey}) => ({
    text:
      text && audioClipKey ? (
        <AudioHighlight clipKey={audioClipKey}>{text}</AudioHighlight>
      ) : (
        text
      ),
  })),
  ({
    corner,
    text,
    backgroundColor,
    className,
    textCss,
    children,
    offset,
    TextTag = 'span',
  }) => (
    <div
      className={className}
      css={[
        styles.container,
        {backgroundColor},
        getCornerStyles({corner, backgroundColor, offset}),
      ]}
    >
      <Contents css={styles.contentContainer}>
        {children || <TextTag css={textCss}>{text}</TextTag>}
      </Contents>
    </div>
  )
)

SingleSpeechBubble.propTypes = {
  corner: PropTypes.string,
  text: PropTypes.string,
  audioClipKey: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  className: classNamePropType,
  textCss: cssPropType,
  children: childrenPropType,
  offset: PropTypes.number,
  TextTag: PropTypes.node,
}

const SpeechBubble = ({corner, offset, ...props}) =>
  isPlainObject(corner) ? (
    <BreakpointSwitch
      {...fmapValuesWithKey((singleCorner, breakpoint) => (
        <SingleSpeechBubble
          {...props}
          corner={singleCorner}
          offset={isPlainObject(offset) ? offset[breakpoint] : offset}
        />
      ))(corner)}
    />
  ) : (
    <SingleSpeechBubble {...props} corner={corner} offset={offset} />
  )

SpeechBubble.propTypes = {
  ...SingleSpeechBubble.propTypes,
  corner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default SpeechBubble

const styles = {
  container: {
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:before': {
      content: "''",
      width: 0,
      height: 0,
      position: 'absolute',
      borderWidth: triangleSize,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}
