import amplitude from 'amplitude-js'

// eslint-disable-next-line import/prefer-default-export
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

amplitude.getInstance().init(AMPLITUDE_API_KEY, null, {
  trackingOptions: {
    ip_address: false,
  },
})
