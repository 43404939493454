import {toast, Slide} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import colors from 'style/colors'

toast.configure({
  transition: Slide,
  hideProgressBar: true,
})

const toastWrapper = ({cssHelper}) => message =>
  toast(message, {
    className: cssHelper({
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: colors.white,
      paddingLeft: 24,
    }),
  })

export default toastWrapper
