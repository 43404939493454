import React from 'react'

import IntroPage, {AdditionalContentLink} from 'components/IntroPage'
import BackButton from 'components/BackButton'
import {mq} from 'style/mediaQueries'

const ReplayVideoLink = () => (
  <AdditionalContentLink ButtonComponent={BackButton} clipKey="replay" />
)

const IntroReview = props => (
  <IntroPage
    {...props}
    linkTo="/intro/key-messages/1"
    translationKey="introReviewPage"
    noHeader
    questionKey="header"
    additionalContentAfterButton={<ReplayVideoLink />}
    hardyCss={styles.hardy}
    audioSectionId="introReviewPage"
    buttonKey="letsBegin"
    name="Intro review"
  />
)

export default IntroReview

const styles = {
  hardy: {
    [mq.tablet]: {
      left: '29%',
      bottom: 418,
    },
  },
}
