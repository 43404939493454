export const getWistiaVideoId = ({idKey, t}) => t(`videoIds.${idKey}`)

export const getWistiaUrl = ({idKey, t}) =>
  `https://home.wistia.com/medias/${getWistiaVideoId({idKey, t})}`

export const disableWistiaTracking = () => {
  window._wq = window._wq || []
  window._wq.push(W => {
    W.consent(false)
  })
}
