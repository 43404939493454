import {flow, pick as fpick, trimCharsStart as ftrimStart} from 'lodash/fp'
import qs from 'qs'
import {navigate} from '@reach/router'

export const pluckQueryParams = names =>
  flow(
    () => window.location.search,
    ftrimStart('?'),
    qs.parse,
    names ? fpick(names) : params => params
  )()

export const addQueryParams = names => props => ({
  ...props,
  ...pluckQueryParams(names),
})

export const updateQueryString = flow(
  updatedParams => ({...pluckQueryParams(), ...updatedParams}),
  params => {
    navigate(`${window.location.pathname}?${qs.stringify(params)}`)
  }
)
