import React from 'react'
import Overlay from 'react-bootstrap/Overlay'
import {addRef, flowMax} from 'ad-hok'
import PropTypes from 'prop-types'
import {ClassNames} from '@emotion/core'

import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import {childrenPropType, classNamePropType} from 'util/propTypes'
import colors from 'style/colors'
import {disableArrowPopperConfig} from 'util/popper'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import AudioSection from 'components/AudioSection'
import withAudioModeSectionRestoration from 'hocs/withAudioModeSectionRestoration'
import AudioHighlight from 'components/AudioHighlight'
import {addPageRef} from 'components/Page'

const enhance = withAudioModeSectionRestoration

const QuestionTooltip = flowMax(
  addRef('buttonRef'),
  addPageRef,
  addEffectOnPropChange(
    'showing',
    (
      {showing, onAudioModeStart, onAudioModeRestore},
      {showing: previousShowing}
    ) => {
      if (showing && !previousShowing) {
        onAudioModeStart()
      } else if (!showing && previousShowing) {
        onAudioModeRestore()
      }
    }
  ),
  ({
    text,
    children,
    showing,
    onClick,
    buttonRef,
    pageRef,
    className,
    audioSectionId,
  }) => (
    <AudioSection id={audioSectionId}>
      <ClassNames>
        {({css, cx}) => (
          <>
            <Button
              mode="plain"
              className={cx(className, css(styles.tooltipLink))}
              title={children[0]}
              onClick={onClick}
              ref={buttonRef}
            />
            <Overlay
              target={buttonRef.current}
              show={showing}
              placement="bottom"
              popperConfig={disableArrowPopperConfig}
              container={pageRef.current}
            >
              {({show, ...props}) => (
                <Tooltip
                  {...props}
                  backgroundColor={colors.blue}
                  borderColor={colors.blue}
                  css={styles.tooltip}
                >
                  <AudioHighlight clipKey="tooltip" dark>
                    {text}
                  </AudioHighlight>
                </Tooltip>
              )}
            </Overlay>
          </>
        )}
      </ClassNames>
    </AudioSection>
  )
)

QuestionTooltip.propTypes = {
  children: childrenPropType.isRequired,
  text: PropTypes.string.isRequired,
  showing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: classNamePropType,
  audioSectionId: PropTypes.string.isRequired,
}

export default enhance(QuestionTooltip)

const styles = {
  tooltipLink: {
    textDecoration: 'underline',
    color: colors.blue,
    marginBottom: 0,
  },
  tooltip: {
    color: colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 16,
    width: '98%',
    maxWidth: 432,
  },
}
