import React from 'react'
import {addProps, flowMax} from 'ad-hok'
import PropTypes from 'prop-types'
import {flow, omit as fomit} from 'lodash/fp'

import {addTranslationHelpers} from 'util/i18n'
import {imageSourcePropType} from 'util/propTypes'
import Page from 'components/Page'
import colors from 'style/colors'
import Button from 'components/Button'
import strokeIllustration from 'assets/images/key-message-stroke.svg'
import bloodThinnerIllustration from 'assets/images/key-message-blood-thinner.svg'
import choiceIllustration from 'assets/images/key-message-choice.svg'
import benefitsIllustration288 from 'assets/images/key-message-benefits-288w.png'
import benefitsIllustration480 from 'assets/images/key-message-benefits-480w.png'
import benefitsIllustration960 from 'assets/images/key-message-benefits-960w.png'
import benefitsIllustration1920 from 'assets/images/key-message-benefits-1920w.png'
import benefitsIllustration3840 from 'assets/images/key-message-benefits-3840w.png'
import BackButton from 'components/BackButton'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import {getImgPropsFromSizes} from 'util/responsive'
import Image from 'components/Image'
import {addTrackingProps} from 'util/tracking'

const messages = [
  {
    key: 'stroke',
    image: strokeIllustration,
  },
  {
    key: 'bloodThinner',
    image: bloodThinnerIllustration,
  },
  {
    key: 'benefits',
    imageSizes: '(min-width: 1116px) 1052px, 95vw',
    imageSrcSet: {
      288: benefitsIllustration288,
      480: benefitsIllustration480,
      960: benefitsIllustration960,
      1920: benefitsIllustration1920,
      3840: benefitsIllustration3840,
    },
  },
  {
    key: 'choice',
    image: choiceIllustration,
  },
]

const IllustrationBox = ({
  text,
  header,
  image,
  imageSizes,
  imageSrcSet,
  buttonLinkTo,
}) => (
  <section css={styles.boxContainer}>
    <h1 css={styles.headerOuterContainer}>
      <div css={styles.headerContainer}>
        <span css={styles.header}>
          <AudioHighlight clipKey="message">{header}</AudioHighlight>
        </span>
        <span css={styles.messageText}>
          <AudioHighlight clipKey="message">{text}</AudioHighlight>
        </span>
      </div>
    </h1>
    <div css={styles.imageContainer}>
      <Image
        {...(image
          ? {src: image}
          : getImgPropsFromSizes({sizes: imageSizes, srcSet: imageSrcSet}))}
        css={styles.image}
        alt=""
        aspectRatio={1920 / 817}
        ensureImageStretchesFullyAcrossContainer
      />
      <div css={styles.imageOverlayContainer} aria-hidden="true">
        <Button
          mode="plain"
          css={styles.imageOverlayButton}
          onClick={() => {
            window.history.back()
          }}
          tabIndex="-1"
        />
        <Button
          mode="plain"
          css={styles.imageOverlayButton}
          linkTo={buttonLinkTo}
          tabIndex="-1"
        />
      </div>
    </div>
  </section>
)

IllustrationBox.propTypes = {
  text: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  image: imageSourcePropType,
  imageSizes: PropTypes.string,
  imageSrcSet: PropTypes.object,
  buttonLinkTo: PropTypes.string.isRequired,
}

const addCurrentMessage = flow(
  addProps(({number}) => ({currentMessageIndex: Number(number - 1)})),
  addProps(({currentMessageIndex}) => ({
    currentMessage: {
      ...messages[currentMessageIndex],
      index: currentMessageIndex,
    },
  })),
  addProps(({currentMessage}) => ({
    currentMessage: {
      ...currentMessage,
      key: `introKeyMessagesPage.messages.${currentMessage.key}`,
    },
  }))
)

const IntroKeyMessages = flowMax(
  addCurrentMessage,
  addProps(({currentMessage}) => ({
    isLastMessage: currentMessage.index === messages.length - 1,
  })),
  addProps(({currentMessage, isLastMessage}) => ({
    buttonLinkTo: isLastMessage
      ? '/steps/2'
      : `/intro/key-messages/${currentMessage.index + 2}`,
  })),
  addTranslationHelpers,
  addProps(({isLastMessage}) => ({
    buttonKey: isLastMessage ? 'continueJourney' : 'next',
  })),
  addTrackingProps(({currentMessage: {index: currentMessageIndex}}) => ({
    'Key message number': currentMessageIndex + 1,
  })),
  ({t, currentMessage, buttonLinkTo, buttonKey}) => (
    <Page
      css={styles.container}
      background={colors.lighterBlueBackground}
      audioSectionId={`introKeyMessagesPage${currentMessage.index + 1}`}
      name="Key messages"
    >
      <BackButton />
      <IllustrationBox
        text={t(currentMessage.key)}
        header={t('introKeyMessagesPage.header', {
          number: currentMessage.index + 1,
        })}
        {...fomit(['key'])(currentMessage)}
        buttonLinkTo={buttonLinkTo}
      />
      <Button
        responsive
        title={t(`buttons.${buttonKey}`)}
        css={styles.nextButton}
        linkTo={buttonLinkTo}
        data-testid={`key-messages-next-${currentMessage.index + 1}`}
        audioClipKey={buttonKey}
      />
    </Page>
  )
)

IntroKeyMessages.propTypes = {
  number: PropTypes.string,
}

export default IntroKeyMessages

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingTop: 152,
    paddingLeft: 30,
    paddingRight: 31,
    paddingBottom: 49,
  },
  header: {
    [mq.mobile]: {
      marginBottom: 12,
    },
    [mq.desktop]: {
      marginBottom: 0,
    },
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  boxContainer: {
    [mq.mobile]: {
      maxWidth: 900,
    },
    [mq.biggerThan1024]: {
      maxWidth: 1052,
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 33,
    position: 'relative',
    alignSelf: 'center',
  },
  headerOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    [mq.desktop]: {
      alignItems: 'center',
    },
    backgroundColor: colors.periwinkle,
    minHeight: 96,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 22,
    paddingRight: 18,
    paddingTop: 16,
    paddingBottom: 16,
  },
  headerContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.desktop]: {
      flexDirection: 'row',
    },
    display: 'flex',
    fontSize: 26,
    lineHeight: 'normal',
    fontWeight: 600,
  },
  messageText: {
    [mq.desktop]: {
      marginLeft: 18,
    },
  },
  nextButton: {
    [mq.mobile]: {
      minWidth: 162,
    },
    [mq.tablet]: {
      minWidth: 212,
    },
    alignSelf: 'flex-end',
    fontWeight: 600,
  },
  image: {
    width: '100%',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  imageContainer: {
    position: 'relative',
  },
  imageOverlayContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  imageOverlayButton: {
    flex: 1,
  },
}
