import React, {forwardRef} from 'react'
import BaseTooltip from 'react-bootstrap/Tooltip'
import PropTypes from 'prop-types'
import memoize from 'memoizee'

import {childrenPropType, classNamePropType} from 'util/propTypes'
import colors from 'style/colors'
import shadows from 'style/shadows'

const arrowSize = 20
// https://codepen.io/jhawes/pen/yNXaLV
let getContainerStyles = (borderWidth, borderColor, backgroundColor) => {
  const arrowBorderWidth = borderWidth * 1.5
  return {
    backgroundColor,
    border: `solid ${borderWidth}px ${borderColor}`,
    padding: '38px 45px 41px 66px',
    maxWidth: 522,
    borderRadius: 10,
    '&:before': {
      position: 'absolute',
      height: 0,
      width: 0,
      left: '50%',
      borderLeft: `solid ${arrowSize + arrowBorderWidth}px transparent`,
      borderRight: `solid ${arrowSize + arrowBorderWidth}px transparent`,
      borderBottom: `solid ${arrowSize + arrowBorderWidth}px ${borderColor}`,
      top: `-${arrowSize + arrowBorderWidth - borderWidth}px`,
      marginLeft: `-${arrowSize + arrowBorderWidth * 2}px`,
      content: "''",
    },
    '&:after': {
      position: 'absolute',
      height: 0,
      width: 0,
      left: '50%',
      borderLeft: `solid ${arrowSize}px transparent`,
      borderRight: `solid ${arrowSize}px transparent`,
      borderBottom: `solid ${arrowSize}px ${backgroundColor}`,
      top: `-${arrowSize - borderWidth}px`,
      marginLeft: `-${arrowSize + arrowBorderWidth}px`,
      content: "''",
    },
    fontSize: 26,
    lineHeight: '38px',
    color: colors.black,
    boxShadow: shadows.card,
  }
}
getContainerStyles = memoize(getContainerStyles)

const Tooltip = forwardRef(
  (
    {
      children,
      borderWidth = 3,
      borderColor = colors.black,
      backgroundColor = colors.lightGreenBackground,
      className,
      ...props
    },
    ref
  ) => (
    <BaseTooltip {...props} ref={ref}>
      <div css={styles.wrapper}>
        <div
          className={className}
          css={getContainerStyles(borderWidth, borderColor, backgroundColor)}
        >
          {children}
        </div>
      </div>
    </BaseTooltip>
  )
)

Tooltip.propTypes = {
  ...BaseTooltip.propTypes,
  borderWidth: PropTypes.number,
  children: childrenPropType.isRequired,
  className: classNamePropType,
  backgroundColor: PropTypes.string,
}

export default Tooltip

const styles = {
  wrapper: {
    position: 'relative',
    top: 23,
  },
}
