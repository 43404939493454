import React from 'react'
import {ClassNames} from '@emotion/core'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import {flow} from 'lodash/fp'

import Steps from 'pages/Steps'
import {BreakpointSwitch, mq} from 'style/mediaQueries'
import LanguageSelector from 'components/LanguageSelector'
import zIndices from 'style/zIndices'
import Button from 'components/Button'
import {addTranslationHelpers} from 'util/i18n'
import closeButtonIcon from 'assets/images/close-menu.svg'
import PoseGroup from 'components/PoseGroup'
import AudioModeToggle from 'components/AudioModeToggle'
import Modal from 'components/Modal'
import modalStyles from 'style/modalStyles'
import AudioHighlight from 'components/AudioHighlight'

const CloseMenuButtonPosed = posed(Button)({
  preEnterMenu: {opacity: 0},
  enterMenu: {opacity: 1, delay: 100, transition: {duration: 350}},
})

const CloseMenuButton = flow(
  addTranslationHelpers,
  ({onClick, t}) => (
    <PoseGroup animateOnMount suffix="menu">
      <CloseMenuButtonPosed
        mode="plain"
        css={styles.closeMenuButtonContainer}
        onClick={onClick}
        data-testid="menu-close"
        key="closeButton"
      >
        <span css={styles.closeMenuButtonLabel}>
          <AudioHighlight clipKey="closeMenu" />
        </span>
        <img src={closeButtonIcon} alt={t('buttons.close')} />
      </CloseMenuButtonPosed>
    </PoseGroup>
  )
)

CloseMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Menu = ({isOpen, onClose}) => (
  <ClassNames>
    {({css}) => (
      <Modal
        show={isOpen}
        dialogClassName={css([modalStyles.dialogReset, styles.container])}
        onHide={onClose}
        audioSectionId="menu"
      >
        <div css={styles.scrollableContainer}>
          <CloseMenuButton onClick={onClose} />
          <Steps isMenu onMenuClose={onClose} />
          <BreakpointSwitch
            mobile={
              <>
                <AudioModeToggle css={styles.audioModeToggle} />
                <LanguageSelector css={styles.languageSelector} />
              </>
            }
            tablet={null}
          />
        </div>
      </Modal>
    )}
  </ClassNames>
)

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Menu

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: zIndices.menu,
    '& .modal-content': {
      overflowY: 'scroll',
      WebkitOverflowScrolling: 'touch',
    },
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    position: 'relative',
    flexShrink: 0,
  },
  languageSelector: {
    position: 'absolute',
    top: 78,
    left: 32,
    zIndex: zIndices.journeySteps,
  },
  closeMenuButtonContainer: mq({
    position: 'absolute',
    top: 14,
    right: {
      mobile: 18,
      mobileMax: 32,
      tablet: 48,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: zIndices.closeMenu,
  }),
  closeMenuButtonLabel: {
    marginRight: 18,
    fontSize: 18,
    lineHeight: 'normal',
    [mq.mobile]: {
      display: 'none',
    },
    [mq.mobileMax]: {
      display: 'flex',
    },
  },
  audioModeToggle: {
    position: 'absolute',
    top: 113,
    left: 30,
    zIndex: zIndices.journeySteps,
  },
}
