import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'
import {addProps, flowMax} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import colors from 'style/colors'

import ClinicianNav from 'components/ClinicianNav'
import coumadinImage from 'assets/images/pill-coumadin.svg'
import xareltoImage from 'assets/images/pill-xarelto.svg'
import pradaxaImage from 'assets/images/pill-pradaxa.svg'
import eliquisImage from 'assets/images/pill-eliquis.svg'
import savaysaImage from 'assets/images/pill-savaysa.svg'
import {imageSourcePropType} from 'util/propTypes'
import {mq} from 'style/mediaQueries'

const bloodThinners = [
  {
    brandName: 'Coumadin',
    genericNameTranslationKey: 'warfarin',
    icon: coumadinImage,
    top: 40,
    left: 0,
    width: 398,
    height: 201,
    textOffset: {
      top: 0,
      left: 0,
    },
    imageOffset: {
      bottom: 0,
      right: 0,
    },
    dottedLine: {
      x1: 190,
      y1: 72,
      x2: 285,
      y2: 122,
    },
    color: colors.coumadin,
  },
  {
    brandName: 'Xarelto',
    genericNameTranslationKey: 'rivaroxaban',
    icon: xareltoImage,
    top: 243,
    left: 502,
    width: 400,
    height: 99,
    textOffset: {
      bottom: 0,
      left: 210,
    },
    imageOffset: {
      top: 0,
      left: 0,
    },
    dottedLine: {
      x1: 81,
      y1: 32,
      x2: 190,
      y2: 40,
    },
    color: colors.xarelto,
    dottedLineColor: colors.xareltoDottedLine,
  },
  {
    brandName: 'Pradaxa',
    genericNameTranslationKey: 'dabigatran',
    icon: pradaxaImage,
    top: 43,
    left: 462,
    width: 400,
    height: 174,
    textOffset: {
      top: 0,
      left: 220,
    },
    imageOffset: {
      bottom: 0,
      left: 0,
    },
    dottedLine: {
      x1: 86,
      y1: 110,
      x2: 196,
      y2: 52,
    },
    color: colors.pradaxa,
    dottedLineColor: colors.pradaxaDottedLine,
  },
  {
    brandName: 'Eliquis',
    genericNameTranslationKey: 'apixaban',
    icon: eliquisImage,
    top: 357,
    left: 414,
    width: 500,
    height: 159,
    textOffset: {
      bottom: 0,
      left: 290,
    },
    imageOffset: {
      top: 0,
      left: 0,
    },
    dottedLine: {
      x1: 132,
      y1: 56,
      x2: 272,
      y2: 88,
    },
    color: colors.eliquis,
    dottedLineColor: colors.eliquisDottedLine,
  },
  {
    brandName: 'Savaysa',
    genericNameTranslationKey: 'edoxaban',
    icon: savaysaImage,
    top: 280,
    left: 27,
    width: 365,
    height: 209,
    textOffset: {
      bottom: 0,
      left: 0,
    },
    imageOffset: {
      top: 0,
      right: 0,
    },
    dottedLine: {
      x1: 133,
      y1: 106,
      x2: 273,
      y2: 53,
    },
    color: colors.savaysa,
    dottedLineColor: colors.savaysaDottedLine,
  },
]

const addDynamicStyles = addProps(
  ({
    top,
    left,
    bottom,
    right,
    width,
    height,
    textOffset,
    imageOffset,
    color,
    dottedLineColor,
  }) => ({
    dynamicStyles: {
      container: {
        [mq.desktop]: {
          top,
          left,
          bottom,
          right,
          width,
          height,
        },
      },
      text: {
        [mq.desktop]: {
          ...textOffset,
        },
      },
      image: {
        [mq.desktop]: {
          ...imageOffset,
        },
      },
      brandName: {color},
      dottedLine: {stroke: dottedLineColor || color},
    },
  })
)

const BloodThinner = flowMax(
  addTranslationHelpers,
  addProps(({genericNameTranslationKey, t}) => ({
    genericName: t(`bloodThinnerGenericNames.${genericNameTranslationKey}`),
  })),
  addDynamicStyles,
  ({
    brandName,
    genericName,
    icon,
    dynamicStyles,
    width,
    height,
    dottedLine,
    t,
  }) => (
    <li css={[styles.bloodThinnerContainer, dynamicStyles.container]}>
      <svg viewBox={`0 0 ${width} ${height}`} css={styles.dottedLineContainer}>
        <line
          css={[styles.dottedLine, dynamicStyles.dottedLine]}
          {...dottedLine}
        />
      </svg>
      <div css={[styles.bloodThinnerTextContainer, dynamicStyles.text]}>
        <span css={dynamicStyles.brandName}>{brandName}</span>
        <span
          css={styles.genericName}
          aria-label={t('clinicianMedicationGraphicPage.alsoCalled', {
            name: genericName,
          })}
        >
          {genericName}
        </span>
      </div>
      <div css={[styles.bloodThinnerImageContainer, dynamicStyles.image]}>
        <img src={icon} alt={`${brandName} pill illustration`} />
      </div>
    </li>
  )
)

BloodThinner.propTypes = {
  brandName: PropTypes.string.isRequired,
  genericNameTranslationKey: PropTypes.string.isRequired,
  icon: imageSourcePropType.isRequired,
}

const ClinicianMedicationGraphic = flow(
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      background={colors.darkPurpleBackground}
      footer={<ClinicianNav currentPage="bloodThinnerTypes" lightText />}
      name="Blood thinner medications graphic"
    >
      <div css={styles.contentContainer}>
        <h1 css={styles.header}>
          {t('clinicianMedicationGraphicPage.header')}
        </h1>
        <ul css={styles.bloodThinnersContainer}>
          {bloodThinners.map(bloodThinner => (
            <BloodThinner
              {...bloodThinner}
              key={bloodThinner.genericNameTranslationKey}
            />
          ))}
        </ul>
      </div>
    </Page>
  )
)

export default ClinicianMedicationGraphic

const styles = {
  container: {
    paddingLeft: 21,
    paddingRight: 21,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 90,
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxWidth: 1016,
  },
  header: {
    fontSize: 26,
    lineHeight: '38px',
    fontWeight: 500,
    color: colors.white,
    marginBottom: 23,
  },
  bloodThinnersContainer: {
    [mq.desktop]: {
      width: 950,
      alignSelf: 'center',
      position: 'relative',
      height: 537,
    },
  },
  bloodThinnerContainer: {
    [mq.mobile]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'flex-end',
      minHeight: 120,
    },
    [mq.mobileMax]: {
      minHeight: 160,
    },
    [mq.desktop]: {
      position: 'absolute',
      alignItems: 'normal',
      justifyContent: 'flex-start',
      minHeight: 'auto',
    },
  },
  bloodThinnerImageContainer: {
    [mq.mobile]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginRight: 14,
      transform: 'scale(0.5)',
      width: 70,
    },
    [mq.mobileMax]: {
      transform: 'none',
      width: 140,
    },
    [mq.desktop]: {
      position: 'absolute',
      width: 'auto',
      marginRight: 0,
    },
  },
  bloodThinnerTextContainer: {
    [mq.mobile]: {
      fontSize: 28,
      lineHeight: '40px',
    },
    [mq.mobileMax]: {
      fontSize: 40,
      lineHeight: '50px',
    },
    [mq.desktop]: {
      position: 'absolute',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  genericName: {
    color: colors.lightPurpleText,
  },
  dottedLine: {
    strokeWidth: 1.7,
    strokeDasharray: '7 6',
  },
  dottedLineContainer: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.desktop]: {
      display: 'block',
      width: '100%',
    },
  },
}
