import React from 'react'
import {flow} from 'lodash/fp'

import Button from 'components/Button'
import {styles as patientStoriesStyles} from 'components/PatientStories'
import {addTranslationHelpers} from 'util/i18n'

const LightboxCloseButton = flow(
  addTranslationHelpers,
  ({t, ...buttonProps}) => (
    <div css={styles.videoCloseButtonContainer}>
      <Button
        mode="plain"
        css={patientStoriesStyles.closeButton}
        title={t('buttons.close')}
        {...buttonProps}
      />
    </div>
  )
)

export default LightboxCloseButton

const styles = {
  videoCloseButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    marginTop: 36,
    paddingRight: 5,
  },
}
