import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import translations from './translations.json'
import {getUserLanguage} from './util/i18n'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  // debug: true,
  lng: getUserLanguage(),
  resources: translations,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
