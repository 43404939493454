import React from 'react'
import {flowMax} from 'ad-hok'

import TitleBar from 'components/TitleBar'
import ClinicianRoutes from 'ClinicianRoutes'
import {addTranslationHelpers} from 'util/i18n'
import ClinicianTitleBarAction from 'components/ClinicianTitleBarAction'
import {mq, BreakpointSwitch} from 'style/mediaQueries'
import {addTrackingUserProps} from 'util/tracking'
import LanguageSelector from 'components/LanguageSelector'

const ClinicianApp = flowMax(
  addTrackingUserProps({App: 'clinician'}),
  addTranslationHelpers,
  ({t}) => (
    <>
      <TitleBar>
        <div css={styles.titleBarContent}>
          <ClinicianTitleBarAction />
          <p css={styles.appTitle}>{t('clinicianGeneral.appTitle')}</p>
          <BreakpointSwitch
            desktop={
              <div css={styles.languageSelectorContainer}>
                <LanguageSelector css={styles.languageSelector} />
              </div>
            }
          />
        </div>
      </TitleBar>
      <ClinicianRoutes />
    </>
  )
)

export default ClinicianApp

const styles = {
  appTitle: mq({
    flexGrow: {mobile: 2, tablet: 1},
    fontWeight: 500,
    position: 'relative',
    textAlign: 'center',
    top: 1,
    marginLeft: 18,
    marginRight: 18,
  }),
  titleBarContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,

    [mq.mobile]: {
      '&::after': {
        content: "''",
        flex: 1,
      },
    },
    [mq.desktop]: {
      '&::after': {
        content: "''",
        flex: 0,
      },
    },
  },
  languageSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [mq.desktop]: {
      flex: 1,
    },
  },
  languageSelector: {
    marginLeft: 'auto',
    [mq.mobile]: {
      marginRight: 0,
    },
  },
}
