import React from 'react'
import PropTypes from 'prop-types'
import {addProps, flowMax} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import Button, {
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import colors from 'style/colors'
import leftCloudIllustration from 'assets/images/intro-cloud.svg'
import rightCloudIllustration from 'assets/images/intro-cloud-small.svg'
import largeRightCloudIllustration from 'assets/images/intro-cloud-large.svg'
import {cssPropType, componentPropType} from 'util/propTypes'
import BackButton from 'components/BackButton'
import HillsideFooter from 'components/HillsideFooter'
import {mq, addResponsiveBreakpointProps} from 'style/mediaQueries'
import zIndices from 'style/zIndices'
import AudioHighlight from 'components/AudioHighlight'

export const AdditionalContentLink = ({
  ButtonComponent = Button,
  clipKey,
  onClick,
}) => (
  <ButtonComponent
    css={styles.additionalContentLinkContainer}
    mode="link"
    onClick={onClick}
  >
    <AudioHighlight clipKey={clipKey} />
  </ButtonComponent>
)

AdditionalContentLink.propTypes = {
  ButtonComponent: componentPropType,
  clipKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const IntroPage = flowMax(
  addTranslationHelpers,
  addResponsiveBreakpointProps('tablet'),
  addProps(({noHeader}) => ({
    QuestionTag: noHeader ? 'h1' : 'h2',
  })),
  ({
    t,
    translationKey,
    linkTo,
    headerCss,
    containerCss,
    contentContainerCss,
    questionCss,
    buttonCss,
    buttonMode,
    questionKey = 'question',
    additionalContent,
    additionalContentAfterButton,
    buttonKey = 'next',
    noHeader,
    noHardy,
    hardyCss,
    isBelowTablet,
    QuestionTag,
    audioSectionId,
    onButtonClick,
    name,
    trackButtonAs,
  }) => (
    <Page
      css={[styles.container, containerCss]}
      background={colors.lighterBlueBackground}
      footer={<HillsideFooter hardyCss={hardyCss} noHardy={noHardy} />}
      audioSectionId={audioSectionId}
      name={name}
    >
      <BackButton />
      <div css={[styles.contentAndCloudsContainer, contentContainerCss]}>
        <div css={styles.contentContainer}>
          {!noHeader && (
            <h1 css={[styles.header, headerCss]}>
              <AudioHighlight clipKey="header">
                {t(`${translationKey}.header`)}
              </AudioHighlight>
            </h1>
          )}
          <QuestionTag css={[styles.question, questionCss]}>
            <AudioHighlight clipKey={questionKey}>
              {t(`${translationKey}.${questionKey}`)}
            </AudioHighlight>
          </QuestionTag>
          {additionalContent}
          <Button
            mode={buttonMode}
            title={t(`buttons.${buttonKey}`)}
            css={[
              buttonMode !== 'plain' && buttonMode !== 'link' && styles.button,
              buttonCss,
            ]}
            linkTo={linkTo}
            data-testid={`${translationKey}-button`}
            audioClipKey={buttonKey}
            onClick={onButtonClick}
            trackAs={trackButtonAs}
          />
          {additionalContentAfterButton}
        </div>
        <img src={leftCloudIllustration} css={styles.leftCloud} alt="" />
        <img
          src={
            noHardy && !isBelowTablet
              ? largeRightCloudIllustration
              : rightCloudIllustration
          }
          css={styles.rightCloud}
          alt=""
        />
      </div>
    </Page>
  )
)

IntroPage.propTypes = {
  translationKey: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  questionKey: PropTypes.string,
  additionalContent: PropTypes.node,
  additionalContentAfterButton: PropTypes.node,
  headerCss: cssPropType,
  hardyCss: cssPropType,
  containerCss: cssPropType,
  contentContainerCss: cssPropType,
  questionCss: cssPropType,
  buttonCss: cssPropType,
  buttonMode: PropTypes.string,
  buttonKey: PropTypes.string,
  noHeader: PropTypes.bool,
  noHardy: PropTypes.bool,
  audioSectionId: PropTypes.string,
  onButtonClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  trackButtonAs: PropTypes.string,
}

export default IntroPage

export const styles = {
  container: {
    [mq.mobile]: {
      paddingLeft: 21,
      paddingRight: 21,
    },
    [mq.tablet]: {
      paddingLeft: 39,
      paddingRight: 39,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 160,
    overflowX: 'hidden',
  },
  header: {
    fontSize: 20,
    lineHeight: '28px',
    fontStyle: 'italic',
    fontWeight: 700,
    marginBottom: 18,
  },
  question: {
    [mq.mobile]: {
      fontSize: 36,
      lineHeight: '48px',
    },
    [mq.tablet]: {
      fontSize: 48,
      lineHeight: '67px',
    },
    fontWeight: 400,
    marginBottom: 32,
  },
  button: {
    [mq.mobile]: {
      ...mobileButtonResponsiveStyles,
      minWidth: 168,
    },
    [mq.tablet]: {
      ...normalButtonResponsiveStyles,
      minWidth: 216,
    },
    fontWeight: 600,
    flexShrink: 0,
    alignSelf: 'flex-start',
  },
  contentAndCloudsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 709,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    flexGrow: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    zIndex: zIndices.introPageContent,
    marginBottom: 8,
  },
  leftCloud: {
    position: 'absolute',
    left: -178,
    bottom: 45,
  },
  rightCloud: {
    position: 'absolute',
    right: -135,
    bottom: 50,
  },
  additionalContentLinkContainer: {
    [mq.mobile]: {
      fontSize: 24,
      lineHeight: '32px',
    },
    [mq.tablet]: {
      fontSize: 26,
      lineHeight: '36px',
    },
    marginTop: 38,
    alignSelf: 'flex-start',
  },
}
