import React, {createContext} from 'react'
import {
  addWrapper,
  addHandlers,
  flowMax,
  addProps,
  branchPure,
  returns,
} from 'ad-hok'
import {Amplitude as BaseAmplitude} from '@amplitude/react-amplitude'
import {isFunction} from 'lodash'
import {flow} from 'lodash/fp'
import amplitude from 'amplitude-js'

import {removeProps, callWith} from 'util/fp'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import addEffectOnMount from 'util/addEffectOnMount'

const TrackingEventDebouncingContext = createContext()

export const addTrackingEventDebouncing = wait =>
  addWrapper(({render}) => (
    <TrackingEventDebouncingContext.Provider value={wait}>
      {render()}
    </TrackingEventDebouncingContext.Provider>
  ))

const Amplitude = props => (
  <TrackingEventDebouncingContext.Consumer>
    {flow(
      debounceInterval => (debounceInterval ? {debounceInterval} : {}),
      debounceIntervalProps => (
        <BaseAmplitude {...debounceIntervalProps} {...props} />
      )
    )}
  </TrackingEventDebouncingContext.Consumer>
)

export const addTrackingProps = trackingProps =>
  addWrapper(({render, props}) => (
    <Amplitude
      eventProperties={
        isFunction(trackingProps) ? trackingProps(props) : trackingProps
      }
    >
      {render()}
    </Amplitude>
  ))

export const addTrackingUserProps = trackingUserProps =>
  addWrapper(({render, props}) => (
    <Amplitude
      userProperties={
        isFunction(trackingUserProps)
          ? trackingUserProps(props)
          : trackingUserProps
      }
    >
      {render()}
    </Amplitude>
  ))

export const addInstrumentTracking = addWrapper(({render}) => (
  <Amplitude>
    {({instrument: instrumentTracking}) => render({instrumentTracking})}
  </Amplitude>
))

export const addLogTrackingEvent = addWrapper(({render}) => (
  <Amplitude>
    {({logEvent: logTrackingEvent}) => render({logTrackingEvent})}
  </Amplitude>
))

export const addTrackingToHandler = (handlerPropName, eventName) =>
  flowMax(
    addInstrumentTracking,
    addHandlers({
      [handlerPropName]: flow(
        addProps(({[handlerPropName]: handler}) => ({
          [handlerPropName]: handler || (() => {}),
        })),
        ({[handlerPropName]: handler, instrumentTracking, ...props}) =>
          callWith(eventName)(
            flow(
              name => (isFunction(name) ? name(props) : name),
              name => (name ? instrumentTracking(name, handler) : handler)
            )
          )
      ),
    }),
    removeProps('instrumentTracking')
  )

export const addTrackingOnClick = eventName =>
  addTrackingToHandler('onClick', eventName)

export const addTrackingOnPropBecomingTruthy = (propName, eventName) =>
  flowMax(
    addLogTrackingEvent,
    addEffectOnPropChange(
      propName,
      ({[propName]: propValue, logTrackingEvent}, prevProps) => {
        if (!(propValue && !prevProps[propName])) return
        logTrackingEvent(eventName)
      }
    ),
    removeProps('logTrackingEvent')
  )

export const addTrackingOnPropBecomingFalsy = (propName, eventName) =>
  flowMax(
    addLogTrackingEvent,
    addEffectOnPropChange(
      propName,
      ({[propName]: propValue, logTrackingEvent}, prevProps) => {
        if (!(!propValue && prevProps[propName])) return
        logTrackingEvent(eventName)
      }
    ),
    removeProps('logTrackingEvent')
  )

export const resetTracking = () => {
  amplitude.getInstance().setUserId(null)
  amplitude.getInstance().regenerateDeviceId()
}

export const addTrackingOnMount = eventName =>
  flowMax(
    addLogTrackingEvent,
    addEffectOnMount(({logTrackingEvent, ...props}) => () => {
      callWith(eventName)(
        flowMax(
          name => (isFunction(name) ? name(props) : name),
          branchPure(name => !name, returns(() => null)),
          name => logTrackingEvent(name)
        )
      )
    }),
    removeProps('logTrackingEvent')
  )
