import {
  flow,
  values as fvalues,
  first as ffirst,
  join as fjoin,
} from 'lodash/fp'

import {fmapWithKey} from 'util/fp'

const getSrcSet = flow(
  fmapWithKey((src, width) => `${src} ${width}w`),
  fjoin(', ')
)

// eslint-disable-next-line import/prefer-default-export
export const getImgPropsFromSizes = ({sizes, srcSet}) => ({
  src: flow(
    () => srcSet,
    fvalues,
    ffirst
  )(),
  sizes,
  srcSet: getSrcSet(srcSet),
})
