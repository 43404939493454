import React from 'react'
import {flowMax} from 'ad-hok'

import IntroPage from 'components/IntroPage'
import pdfIcon from 'assets/images/pdf-icon.svg'
import worksheetPdfEnglish from 'assets/AFib-Worksheet-en.pdf'
import worksheetPdfSpanish from 'assets/AFib-Worksheet-es.pdf'
import Button from 'components/Button'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import {addLanguageConditionalProps, addTranslationHelpers} from 'util/i18n'
import {addTrackingOnClick} from 'util/tracking'

const WorksheetLink = flowMax(
  addLanguageConditionalProps({
    worksheetPdf: {
      en: worksheetPdfEnglish,
      es: worksheetPdfSpanish,
    },
  }),
  addTranslationHelpers,
  addTrackingOnClick('Open worksheet'),
  ({worksheetPdf, onClick, t}) => (
    <Button
      mode="link"
      linkTo={worksheetPdf}
      css={styles.worksheetLinkContainer}
      external
      onClick={onClick}
    >
      <img
        src={pdfIcon}
        css={styles.pdfIcon}
        alt={t('wrapUpWorksheetPage.pdfIconAlt')}
      />
      <span css={styles.worksheetLinkText}>
        <AudioHighlight clipKey="downloadLink" />
      </span>
    </Button>
  )
)

const AdditionalContent = () => <WorksheetLink />

const WrapUpWorksheet = props => (
  <IntroPage
    {...props}
    translationKey="wrapUpWorksheetPage"
    linkTo="/wrap-up/done"
    questionKey="nextStep"
    additionalContent={<AdditionalContent />}
    contentContainerCss={styles.contentContainer}
    hardyCss={styles.hardy}
    buttonKey="next"
    buttonCss={styles.button}
    headerCss={styles.header}
    audioSectionId="wrapUpWorksheetPage"
    name="Wrap-up worksheet"
  />
)

export default WrapUpWorksheet

const styles = {
  worksheetLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: 56,
  },
  worksheetLinkText: {
    [mq.mobile]: {
      marginLeft: 13,
      fontSize: 24,
      lineHeight: '29px',
    },
    [mq.tablet]: {
      marginLeft: 16,
      fontSize: 26,
      lineHeight: '38px',
    },
  },
  contentContainer: {
    maxWidth: 827,
  },
  hardy: {
    [mq.tablet]: {
      bottom: 375,
      left: '86%',
    },
  },
  button: {
    alignSelf: 'flex-start',
  },
  header: {
    fontWeight: 600,
  },
  pdfIcon: {
    [mq.mobile]: {
      width: 30,
    },
    [mq.tablet]: {
      width: 34,
    },
  },
}
