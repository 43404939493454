import React from 'react'

import RiskCalculatorPage from 'components/RiskCalculatorPage'
import FactorsName from 'components/FactorsName'

const factors = [
  {key: 'age', points: 2, row: 1},
  {key: 'pastStroke', points: 2, row: 1},
  {key: 'diabetes', points: 1, row: 2},
  {key: 'ageRange', points: 1, row: 2},
  {key: 'female', points: 1, row: 2},
  {key: 'vascular', points: 1, row: 3},
  {key: 'bloodPressure', points: 1, row: 3},
  {key: 'congestive', points: 1, row: 3},
]

const ClinicianStrokeRiskFactors = () => (
  <RiskCalculatorPage
    factors={factors}
    largeRows={[1]}
    factorGaps={factorGaps}
    translationKey="clinicianStrokeRiskFactorsPage"
    detailsLinkTo="/clinician/stroke-risk-percentages"
    headerText={<FactorsName />}
    pageKey="strokeRiskFactors"
    name="Stroke risk factors calculator"
  />
)

export default ClinicianStrokeRiskFactors

const factorGaps = {
  1: {
    standard: 41,
  },
  2: {
    standard: 64,
    byColumn: {
      1: {
        top: -20,
      },
      2: {
        gap: 72,
      },
      3: {
        top: -43,
      },
    },
  },
  3: {
    standard: 35,
    byColumn: {
      1: {
        top: -35,
      },
      2: {
        gap: 49,
      },
      3: {
        top: -58,
      },
    },
  },
}
