import React from 'react'
import {flow} from 'lodash/fp'
import {keyframes} from '@emotion/core'

import {addTranslationHelpers} from 'util/i18n'
import colors from 'style/colors'

const Loading = flow(
  addTranslationHelpers,
  ({t}) => (
    <div css={styles.container}>
      <div css={styles.loader}>{t('general.loading')}</div>
    </div>
  )
)

export default Loading

const grow = keyframes({
  '0%, 80%, 100%': {
    boxShadow: '0 0',
    height: '4em',
  },
  '40%': {
    boxShadow: '0 -2em',
    height: '5em',
  },
})

const color = colors.peach
const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    color,
    textIndent: '-9999em',
    margin: '88px auto',
    position: 'relative',
    fontSize: '11px',
    transform: 'translateZ(0)',
    '&, &:before, &:after': {
      background: color,
      animation: `${grow} 1s infinite ease-out`,
      width: '1em',
      height: '4em',
    },
    '&': {
      animationDelay: '-0.16s',
    },
    '&:before, &:after': {
      position: 'absolute',
      top: 0,
      content: "''",
    },
    '&:before': {
      left: '-1.5em',
      animationDelay: '-0.32s',
    },
    '&:after': {
      left: '1.5em',
    },
  },
}
