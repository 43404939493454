/* eslint-disable */
import React from 'react'
import {Location} from '@reach/router'

import {getLocationKey} from 'components/PosedRouter'

let scrollPositions = {}

class ManageScrollImpl extends React.Component {
  constructor(props) {
    super(props)

    this.listener = this.listener.bind(this)
  }

  componentDidMount() {
    try {
      // session storage will throw for a few reasons
      // - user settings
      // - in-cognito/private browsing
      // - who knows...
      const storage = JSON.parse(sessionStorage.getItem('scrollPositions'))
      if (storage) {
        scrollPositions = JSON.parse(storage) || {}
        const key = getLocationKey(this.props)
        if (scrollPositions[key]) {
          window.scrollTo(0, scrollPositions[key])
        }
      }
    } catch (e) {}

    window.addEventListener('scroll', this.listener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listener)
  }

  componentDidUpdate() {
    const key = getLocationKey(this.props)
    if (!scrollPositions[key]) {
      // never seen this location before
      window.scrollTo(0, 0)
    } else {
      // seen it
      window.scrollTo(0, scrollPositions[key])
    }
  }

  listener() {
    scrollPositions[getLocationKey(this.props)] = window.scrollY
    try {
      sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions))
    } catch (e) {}
  }

  render() {
    return null
  }
}

export default () => (
  <Location>
    {({location}) => <ManageScrollImpl location={location} />}
  </Location>
)
