import React from 'react'
import {flow} from 'lodash/fp'

import ClinicianVideoPage from 'components/ClinicianVideoPage'
import {addTranslationHelpers} from 'util/i18n'
// eslint-disable-next-line known-imports/no-unused-vars
import leadsToStrokeVideo from 'assets/videos/clinician-leads-to-stroke-1080p.mp4'
import leadsToStrokeVideoThumbnail from 'assets/images/clinician-leads-to-stroke-video-thumbnail.png'

const ClinicianLeadsToStroke = flow(
  addTranslationHelpers,
  ({t}) => (
    <ClinicianVideoPage
      videoIdKey="clinicianLeadsToStroke"
      title={t('clinicianLeadsToStrokePage.videoTitle')}
      duration={30}
      pageKey="leadsToStroke"
      fallbackCacheKey="leadsToStrokeVideo"
      fallbackCacheThumbnail={leadsToStrokeVideoThumbnail}
      name="Video: AFib leads to stroke"
    />
  )
)

export default ClinicianLeadsToStroke
