export default {
  green: '#20d181',
  white: '#fff',
  darkBlue: '#28304c',
  black: '#000',
  skyBlue: '#e5f9ff',
  offSkyBlue: '#e7f5ff',
  water: '#5fb2f9',
  dimGreen: '#bdc8c0',
  greenText: '#22c57b',
  greenBackground: '#7deebb',
  lightGreenBackground: '#e9fff3',
  greyBackground: '#ececec',
  blueOutline: '#3086e8',
  greyOutline: '#aeb9c1',
  lightBlue: '#d9efff',
  lightBlueOutline: '#c3dff4',
  purpleBackground: '#8b8ac7',
  darkPurpleBackground: '#292984',
  peach: '#fdd09b',
  purple: '#46429b',
  borderGrey: '#bdbdbd',
  greyDisabledBackground: '#c4c4c4',
  greyDisabledText: '#8a8a8a',
  greyDeselectedBackground: '#f2f2f2',
  greyDeselectedText: '#666666',
  greySelectedBackground: '#bbbdbf',
  lightGreyBackground: '#e5e5ea',
  lightishGreyBackground: '#e2e4e6',
  midGrey: '#969696',
  orange: '#f15a24',
  lavender: '#96a4f0',
  blueButtonBackground: '#3f3fde',
  blueButtonBorder: '#9494dd',
  yellowBackground: '#f4eb9b',
  blue: '#145db3',
  darkerGreen: '#00ac64',
  lightBlueBackground: '#e6f5fb',
  darkGreyBackground: '#3b3b3b',
  darkerBlueBackground: '#b5e0ff',
  paleBlueBackground: '#eaf6ff',
  lighterBlueBackground: '#e3f5fc',
  darkSkyBlue: '#94d9ff',
  darkerGrey: '#717171',
  forestGreen: '#10652a',
  mutedGreen: '#47875e',
  mustardYellow: '#debe01',
  darkerBlue: '#134279',
  offWhiteBorder: '#e0e0e0',
  lightPurpleText: '#a0a0e0',
  coumadin: '#f9b7b2',
  pradaxa: '#99c7e0',
  pradaxaDottedLine: '#4a98cc',
  xarelto: '#e88e79',
  xareltoDottedLine: '#d2694c',
  eliquis: '#e2b2a6',
  eliquisDottedLine: '#f4b456',
  savaysa: '#ddc66f',
  savaysaDottedLine: '#d1af74',
  highlightYellow: '#ffe86e',
  blueBorder: '#99c6e7',
  brighterYellow: '#ffdb00',
  periwinkle: '#cad3ea',
  greyPlaceholderBackground: '#f4f4f4',
}
