import React from 'react'
import PropTypes from 'prop-types'

import hardyIllustration from 'assets/images/hardy-intro.svg'
import hillIllustration from 'assets/images/journey-hill.svg'
import {mq} from 'style/mediaQueries'
import zIndices from 'style/zIndices'
import {cssPropType} from 'util/propTypes'

const HillsideFooter = ({
  hardyCss,
  noHardy,
  hillContainerCss,
  hillsideContainerCss,
  hillsideOuterContainerCss,
}) => (
  <footer css={[styles.hillsideOuterContainer, hillsideOuterContainerCss]}>
    <div css={[styles.hillsideContainer, hillsideContainerCss]}>
      <div css={styles.treesContainer}>
        <div css={styles.treesInnerContainer}>
          {!noHardy && (
            <img
              src={hardyIllustration}
              alt=""
              css={[styles.hardy, hardyCss]}
            />
          )}
        </div>
      </div>
      <div css={[styles.hillContainer, hillContainerCss]} />
    </div>
  </footer>
)

HillsideFooter.propTypes = {
  hardyCss: cssPropType,
  noHardy: PropTypes.bool,
  hillContainerCss: cssPropType,
  hillsideContainerCss: cssPropType,
  hillsideOuterContainerCss: cssPropType,
}

export default HillsideFooter

const hillTileWidth = 1040

const styles = {
  hillsideOuterContainer: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    [mq.mobile]: {
      minHeight: 240,
    },
    [mq.tablet]: {
      minHeight: 318,
    },
  },
  hillsideContainer: mq({
    position: 'absolute',
    bottom: {
      mobile: -383,
      tablet: -313,
    },
    left: 0,
    right: 0,
    top: 0,
  }),
  treesContainer: mq({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowX: 'hidden',
  }),
  treesInnerContainer: {
    width: hillTileWidth,
    position: 'relative',
    left: -39,
  },
  hillContainer: {
    backgroundImage: `url(${hillIllustration})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center top',
    height: 442,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndices.journeyHill,
  },
  hardy: {
    [mq.mobile]: {
      width: 150,
      bottom: 409,
    },
    [mq.tablet]: {
      width: 180,
      bottom: 405,
    },
    position: 'absolute',
    zIndex: zIndices.journeyHardy,
    transform: 'translate(-30%, 0)',
    left: '50%',
  },
}
