import React from 'react'
import {flow} from 'lodash/fp'
import {addState, addProps, flowMax, addHandlers} from 'ad-hok'
import PropTypes from 'prop-types'
import {min} from 'lodash'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import colors from 'style/colors'
import PercentageGraphic from 'components/PercentageGraphic'
import FactorsName from 'components/FactorsName'
import {addQueryParams} from 'util/queryString'
import radioButtonSelected from 'assets/images/percentage-radio-button-selected.svg'
import radioButtonUnselected from 'assets/images/percentage-radio-button-unselected.svg'
import leftCaretIcon from 'assets/images/left-caret-white.svg'
import BackButton from 'components/BackButton'
import zIndices from 'style/zIndices'
import ClinicianNav from 'components/ClinicianNav'
import {BreakpointSwitch, mq} from 'style/mediaQueries'
import {height as titleBarHeight} from 'components/TitleBar'
import FullWidthContainer, {
  MaxWidthContainer,
} from 'components/FullWidthContainer'
import {a11yStyles} from 'util/a11y'

const maxScore = 6
const percentages = {
  0: {
    with: 0,
    without: 0,
  },
  1: {
    with: 0.5,
    without: 1.3,
  },
  2: {
    with: 0.8,
    without: 2.2,
  },
  3: {
    with: 1.2,
    without: 3.2,
  },
  4: {
    with: 1.4,
    without: 4.0,
  },
  5: {
    with: 2.4,
    without: 6.7,
  },
  6: {
    with: 3.4,
    without: 9.6,
  },
}

const ToggleRadioButton = flow(
  addHandlers({
    onClick: ({value, onChange}) => () => {
      onChange(value)
    },
  }),
  addHandlers({
    onChange: ({onClick}) => event => {
      onClick(event.target)
    },
  }),
  addProps(({current, value}) => ({
    isSelected: current === value,
    id: `clinician-percentage-toggle-${value}`,
  })),
  ({onChange, isSelected, label, id, onClick}) => (
    <>
      <input
        type="radio"
        name="clinician-percentage-toggle"
        id={id}
        css={styles.radioInput}
        onChange={onChange}
      />
      <label htmlFor={id} onClick={onClick} css={styles.toggleOption}>
        <img
          src={isSelected ? radioButtonSelected : radioButtonUnselected}
          alt=""
          css={styles.radioButton}
        />
        <span css={styles.toggleOptionLabel}>{label}</span>
      </label>
    </>
  )
)

ToggleRadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

const Toggle = flow(
  addTranslationHelpers,
  ({current, onChange, t}) => (
    <div
      css={styles.toggleContainer}
      role="radiogroup"
      aria-label={t('clinicianStrokeRiskPercentagesPage.header')}
    >
      <ToggleRadioButton
        onChange={onChange}
        current={current}
        value
        label={t('clinicianStrokeRiskPercentagesPage.toggleOptionYes')}
      />
      <ToggleRadioButton
        onChange={onChange}
        current={current}
        value={false}
        label={t('clinicianStrokeRiskPercentagesPage.toggleOptionNo')}
      />
    </div>
  )
)

Toggle.propTypes = {
  current: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Explanation = flow(
  addTranslationHelpers,
  ({withBloodThinner, withPercentage, withoutPercentage, t}) => (
    <p css={styles.percentageExplanation} aria-live="assertive">
      {t('clinicianStrokeRiskPercentagesPage.percentageExplanation', {
        percentage: withBloodThinner ? withPercentage : withoutPercentage,
      })}
    </p>
  )
)

Explanation.propTypes = {
  withBloodThinner: PropTypes.bool.isRequired,
  withPercentage: PropTypes.number.isRequired,
  withoutPercentage: PropTypes.number.isRequired,
}

const Title = flow(
  addTranslationHelpers,
  ({t}) => (
    <h1 css={styles.header}>
      {t('clinicianStrokeRiskPercentagesPage.header')}
    </h1>
  )
)

const Score = flow(
  addTranslationHelpers,
  ({score, t}) => (
    <em css={[styles.headerText, styles.score]}>
      {t('clinicianStrokeRiskPercentagesPage.score', {
        score,
        count: score,
      })}
    </em>
  )
)

Score.propTypes = {
  score: PropTypes.number.isRequired,
}

const SeeFactorsButton = flow(
  addTranslationHelpers,
  ({t}) => (
    <BackButton mode="primary" css={styles.backButton}>
      <img src={leftCaretIcon} alt="Left arrow" />
      <span css={styles.backButtonLabel}>
        {t('clinicianStrokeRiskPercentagesPage.backButtonLabel')}
      </span>
    </BackButton>
  )
)

const CHA2 = () => <FactorsName css={[styles.headerText, styles.cha2]} />

const ClinicianStrokeRiskPercentages = flowMax(
  addState('withBloodThinner', 'onChangeWithBloodThinner', false),
  addQueryParams(['score']),
  addProps(({score = '0'}) => ({score: Number(score)})),
  addProps(({score}) => ({scoreForPercentages: min([score, maxScore])})),
  addProps(({scoreForPercentages}) => ({
    withPercentage: percentages[scoreForPercentages].with,
    withoutPercentage: percentages[scoreForPercentages].without,
  })),
  ({
    withPercentage,
    withoutPercentage,
    withBloodThinner,
    onChangeWithBloodThinner,
    score,
  }) => (
    <Page
      css={styles.container}
      footer={<ClinicianNav currentPage="strokeRiskPercentages" />}
      name="Stroke risk percentage graphic"
      containerCss={styles.pageContainer}
    >
      <FullWidthContainer css={styles.headerBarFullWidthContainer}>
        <MaxWidthContainer css={styles.headerBarContainer}>
          <BreakpointSwitch
            desktop={
              <div css={styles.headerCenterColumnContainer}>
                <Explanation
                  withBloodThinner={withBloodThinner}
                  withPercentage={withPercentage}
                  withoutPercentage={withoutPercentage}
                />
              </div>
            }
          />
          <BreakpointSwitch
            mobile={
              <>
                <BreakpointSwitch mobileMax={<SeeFactorsButton />} />
                <Title />
                <Score score={score} />
                <CHA2 />
                <Toggle
                  current={withBloodThinner}
                  onChange={onChangeWithBloodThinner}
                />
              </>
            }
            tablet={
              <>
                <div css={styles.headerLeftColumnContainer}>
                  <Title />
                  <Toggle
                    current={withBloodThinner}
                    onChange={onChangeWithBloodThinner}
                  />
                </div>
                <div css={styles.headerRightColumnContainer}>
                  <Score score={score} />
                  <CHA2 />
                  <SeeFactorsButton />
                </div>
              </>
            }
          />
        </MaxWidthContainer>
      </FullWidthContainer>
      <div css={styles.graphicOuterContainer}>
        <BreakpointSwitch mobile={<SeeFactorsButton />} mobileMax={null} />
        <div css={styles.graphicContainer}>
          <BreakpointSwitch
            mobile={
              <Explanation
                withBloodThinner={withBloodThinner}
                withPercentage={withPercentage}
                withoutPercentage={withoutPercentage}
              />
            }
            desktop={null}
          />
          <PercentageGraphic
            withBloodThinner={withBloodThinner}
            withPercentage={withPercentage}
            withoutPercentage={withoutPercentage}
          />
        </div>
      </div>
    </Page>
  )
)

export default ClinicianStrokeRiskPercentages

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 44,
  },
  percentageExplanation: {
    [mq.mobile]: {
      marginBottom: 18,
      marginLeft: -2,
    },
    [mq.desktop]: {
      marginBottom: 0,
      marginLeft: 31,
    },
    fontSize: 26,
    lineHeight: '35px',
    maxWidth: 380,
  },
  header: {
    [mq.mobile]: {
      marginBottom: 8,
    },
    [mq.tablet]: {
      marginBottom: 16,
    },
    fontSize: 26,
    lineHeight: '38px',
    fontWeight: 500,
    maxWidth: 250,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  toggleOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.white,
    fontSize: 18,
    lineHeight: '37px',
    marginBottom: 1,
  },
  headerBarFullWidthContainer: {
    backgroundColor: colors.darkPurpleBackground,
  },
  headerBarContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
      paddingTop: 68,
      paddingLeft: 21,
      alignItems: 'flex-start',
    },
    [mq.tablet]: {
      flexDirection: 'row',
      paddingTop: 83,
      paddingLeft: 33,
      alignItems: 'normal',
      justifyContent: 'space-between',
    },
    display: 'flex',
    paddingRight: 20,
    paddingBottom: 11,
    color: colors.white,
    position: 'relative',
  },
  headerLeftColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: zIndices.clinicianPercentagePageHeaderLeftColumn,
  },
  radioInput: {
    ...a11yStyles.visuallyHidden,
    '&:focus + label img': a11yStyles.defaultFocus,
  },
  radioButton: {
    flexShrink: 0,
  },
  toggleOptionLabel: {
    marginLeft: 18,
    position: 'relative',
    top: 4,
  },
  headerCenterColumnContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 11,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  headerRightColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: zIndices.clinicianPercentagePageHeaderRightColumn,
    marginTop: 3,
  },
  headerText: {
    fontSize: 18,
    lineHeight: '26px',
  },
  graphicOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 21,
    marginRight: 21,
    alignItems: 'center',
    position: 'relative',
    marginTop: 28,
  },
  graphicContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  backButton: {
    [mq.mobile]: {
      alignSelf: 'flex-start',
      position: 'relative',
      top: -14,
      left: -4,
      marginBottom: 4,
    },
    [mq.mobileMax]: {
      alignSelf: 'normal',
      position: 'absolute',
      top: titleBarHeight + 18,
      right: 12,
      left: 'auto',
      marginBottom: 0,
    },
    [mq.tablet]: {
      marginTop: 14,
      position: 'relative',
      top: 0,
      right: -4,
    },
    backgroundColor: colors.blueButtonBackground,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.blueButtonBorder,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 18,
    minHeight: 48,
    borderRadius: 24,
  },
  backButtonLabel: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
    color: colors.white,
    marginLeft: 13,
  },
  score: {
    marginBottom: 1,
  },
  cha2: {
    [mq.mobile]: {
      marginBottom: 6,
    },
    [mq.tablet]: {
      marginBottom: 0,
    },
  },
  pageContainer: {
    overflowX: 'hidden',
  },
}
