import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import i18n from 'i18next'

import {addTranslationHelpers} from 'util/i18n'
import colors from 'style/colors'
import rhythmImage from 'assets/images/clinician-rhythm-preview.png'
import rhythmImage2x from 'assets/images/clinician-rhythm-preview@2x.png'
import rhythmImage3x from 'assets/images/clinician-rhythm-preview@3x.png'
import leadsToStrokeImage from 'assets/images/clinician-leads-to-stroke-preview.png'
import leadsToStrokeImage2x from 'assets/images/clinician-leads-to-stroke-preview@2x.png'
import leadsToStrokeImage3x from 'assets/images/clinician-leads-to-stroke-preview@3x.png'
import strokeRiskFactorsImage from 'assets/images/clinician-stroke-risk-factors-preview.png'
import strokeRiskFactorsImage2x from 'assets/images/clinician-stroke-risk-factors-preview@2x.png'
import strokeRiskFactorsImage3x from 'assets/images/clinician-stroke-risk-factors-preview@3x.png'
import balancingImage from 'assets/images/clinician-balancing-preview.svg'
import bloodThinnerTypesImage from 'assets/images/clinician-blood-thinner-types-preview.png'
import bloodThinnerTypesImage2x from 'assets/images/clinician-blood-thinner-types-preview@2x.png'
import bloodThinnerTypesImage3x from 'assets/images/clinician-blood-thinner-types-preview@3x.png'
import bloodThinnerTableImage from 'assets/images/clinician-blood-thinner-table-preview.png'
import bloodThinnerTableImage2x from 'assets/images/clinician-blood-thinner-table-preview@2x.png'
import bloodThinnerTableImage3x from 'assets/images/clinician-blood-thinner-table-preview@3x.png'
import watchmanImage from 'assets/images/clinician-watchman-preview.jpg'
import watchmanImage2x from 'assets/images/clinician-watchman-preview@2x.jpg'
import watchmanImage3x from 'assets/images/clinician-watchman-preview@3x.jpg'
import illustration from 'assets/images/hardy-rolling-hill.svg'
import Link from 'components/Link'
import {imageSourcePropType, classNamePropType} from 'util/propTypes'
import {condensedFont} from 'style/typography'
import Page from 'components/Page'
import FactorsName from 'components/FactorsName'
import {mq, BreakpointSwitch} from 'style/mediaQueries'
import LanguageSelector from 'components/LanguageSelector'

const PageLink = ({label, image, image2x, image3x, linkTo, onClick}) => (
  <li>
    <Link
      to={linkTo}
      mode="plain"
      css={styles.pageLinkContainer}
      onClick={onClick}
    >
      <img
        src={image}
        css={styles.pageLinkImage}
        alt=""
        srcSet={image2x ? `${image2x} 2x, ${image3x} 3x` : undefined}
      />
      <span css={styles.pageLinkLabel}>{label}</span>
    </Link>
  </li>
)

PageLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  image: imageSourcePropType.isRequired,
  image2x: imageSourcePropType,
  image3x: imageSourcePropType,
  linkTo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export const clinicianPages = [
  {
    key: 'rhythm',
    image: rhythmImage,
    image2x: rhythmImage2x,
    image3x: rhythmImage3x,
    linkTo: '/clinician/rhythm',
  },
  {
    key: 'leadsToStroke',
    image: leadsToStrokeImage,
    image2x: leadsToStrokeImage2x,
    image3x: leadsToStrokeImage3x,
    linkTo: '/clinician/leads-to-stroke',
  },
  {
    key: 'strokeRiskFactors',
    // eslint-disable-next-line react/prop-types
    useTrans: ({i18nKey}) => (
      <Trans i18nKey={i18nKey} i18n={i18n}>
        <FactorsName />
      </Trans>
    ),
    image: strokeRiskFactorsImage,
    image2x: strokeRiskFactorsImage2x,
    image3x: strokeRiskFactorsImage3x,
    linkTo: '/clinician/stroke-risk-factors',
  },
  {
    key: 'balancing',
    image: balancingImage,
    linkTo: '/clinician/balancing',
  },
  {
    key: 'bloodThinnerTypes',
    image: bloodThinnerTypesImage,
    image2x: bloodThinnerTypesImage2x,
    image3x: bloodThinnerTypesImage3x,
    linkTo: '/clinician/blood-thinner-types',
  },
  {
    key: 'bloodThinnerTable',
    image: bloodThinnerTableImage,
    image2x: bloodThinnerTableImage2x,
    image3x: bloodThinnerTableImage3x,
    linkTo: '/clinician/blood-thinner-comparison',
  },
  {
    key: 'watchman',
    image: watchmanImage,
    image2x: watchmanImage2x,
    image3x: watchmanImage3x,
    linkTo: '/clinician/watchman',
  },
]

const ClinicianHome = flow(
  addTranslationHelpers,
  ({t, onLinkClick, className, isMenu}) => (
    <Page
      className={className}
      css={styles.container}
      background={colors.greyDeselectedBackground}
      footer={
        <footer>
          <img src={illustration} css={styles.illustration} alt="" />
        </footer>
      }
      name={isMenu ? null : 'Home'}
    >
      <div css={styles.headerContainer}>
        <h1 css={styles.header}>{t('clinicianHomePage.header')}</h1>
        <BreakpointSwitch mobile={<LanguageSelector />} desktop={null} />
      </div>
      <nav css={styles.pageLinksContainer}>
        <ul>
          {clinicianPages.map(page => (
            <PageLink
              {...page}
              label={
                page.useTrans
                  ? page.useTrans({
                      i18nKey: `clinicianHomePage.pages.${page.key}`,
                    })
                  : t(`clinicianHomePage.pages.${page.key}`)
              }
              key={page.key}
              onClick={onLinkClick}
            />
          ))}
        </ul>
      </nav>
    </Page>
  )
)

export default ClinicianHome

ClinicianHome.propTypes = {
  onLinkClick: PropTypes.func,
  className: classNamePropType,
  isMenu: PropTypes.bool,
}

const borderStyle = '1px solid rgba(141, 196, 194, 0.5)'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    [mq.mobile]: {
      paddingTop: 111,
    },
    [mq.tablet]: {
      paddingTop: 121,
    },
    flexGrow: 1,
  },
  headerContainer: {
    [mq.mobile]: {
      paddingLeft: 21,
      paddingRight: 20,
      paddingBottom: 24,
    },
    [mq.tablet]: {
      paddingLeft: 99,
      paddingRight: 99,
      paddingBottom: 38,
    },
    borderBottom: borderStyle,
  },
  header: {
    fontSize: 36,
    lineHeight: '50px',
    fontWeight: 700,
    marginBottom: 10,
  },
  pageLinkContainer: {
    [mq.mobile]: {
      marginLeft: 20,
      marginRight: 20,
      flexDirection: 'column',
      paddingTop: 18,
      paddingBottom: 8,
    },
    [mq.tablet]: {
      marginLeft: 101,
      marginRight: 101,
      flexDirection: 'row',
      alignItems: 'center',
      height: 233,
      paddingTop: 0,
      paddingBottom: 0,
    },
    borderBottom: borderStyle,
    display: 'flex',
  },
  pageLinkLabel: {
    [mq.mobile]: {
      marginLeft: 3,
      marginTop: 8,
    },
    [mq.tablet]: {
      marginLeft: 30,
      marginTop: 0,
    },
    fontSize: 26,
    lineHeight: '37px',
    fontWeight: 700,
    color: colors.black,
    fontFamily: condensedFont,
  },
  pageLinksContainer: {
    marginBottom: 45,
  },
  illustration: {
    width: '100%',
    marginTop: 65,
    display: 'block',
  },
  pageLinkImage: {
    [mq.mobile]: {
      alignSelf: 'flex-start',
      maxWidth: '100%',
    },
    [mq.tablet]: {
      alignSelf: 'auto',
    },
    width: 344,
    borderRadius: 10,
  },
}
