import {useTranslation} from 'react-i18next'
import i18n from 'i18next'
import {flow, mapValues as fmapValues, get as fget} from 'lodash/fp'
import {addProps, flowMax} from 'ad-hok'
import 'moment/locale/es'
import {pluckQueryParams} from './queryString'

export const addTranslationHelpers = props => {
  const [
    t,
    // i18n
  ] = useTranslation()

  return {...props, t}
}

export const addCurrentLanguage = flow(
  // trigger rerender when language changes
  addTranslationHelpers,
  addProps(() => ({
    currentLanguage: i18n.language,
  })),
  addProps(({currentLanguage}) => ({
    isSpanishSelected: currentLanguage === 'es',
  }))
)

export const addLanguageConditionalProps = conditionalProps =>
  flowMax(
    addCurrentLanguage,
    addProps(({currentLanguage}) =>
      fmapValues(fget(currentLanguage))(conditionalProps)
    )
  )

const languageNames = {
  en: 'English',
  es: 'Spanish',
}
export const getLanguageName = code => languageNames[code]

export const getUserLanguage = () => {
  const params = pluckQueryParams(['language'])
  if (params.language === 'es') {
    return 'es'
  }
  if (params.language === 'en') {
    return 'en'
  }
  if (window.navigator.language.split('-')[0] === 'es') return 'es'
  return 'en'
}
