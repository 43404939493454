import React from 'react'
import PropTypes from 'prop-types'
import {flow, groupBy as fgroupBy, map as fmap} from 'lodash/fp'
import {Trans} from 'react-i18next'
import i18n from 'i18next'
import {includes} from 'lodash'
import {addProps} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import colors from 'style/colors'
import {BreakpointSwitch, mq} from 'style/mediaQueries'
import {a11yStyles} from 'util/a11y'

const Factor = flow(
  addTranslationHelpers,
  ({
    row,
    column,
    points,
    labelKey,
    isSelected,
    t,
    onClick,
    dynamicStyles,
    isLargeRow,
    translationKey,
  }) => (
    <Button
      mode="plain"
      css={[
        styles.factorContainer,
        isLargeRow(row) && styles.factorContainerLarge,
        dynamicStyles[`factorContainerRow${row}`],
        dynamicStyles[`factorContainerRow${row}Column${column}`],
        isSelected && styles.factorContainerSelected,
      ]}
      onClick={onClick}
    >
      <em
        css={[
          styles.label,
          isLargeRow(row) && styles.labelLarge,
          dynamicStyles[`factorLabelRow${row}`],
        ]}
      >
        <Trans i18nKey={labelKey} i18n={i18n}>
          <sup>1</sup>
          {'<'}
        </Trans>
      </em>
      <span css={styles.points}>
        {t(`${translationKey}.score`, {
          score: points,
          count: points,
        })}
      </span>
      <span
        css={a11yStyles.visuallyHidden}
        aria-label={t(
          `clinicianCalculator.${isSelected ? 'selected' : 'notSelected'}`
        )}
      />
    </Button>
  )
)

Factor.propTypes = {
  row: PropTypes.number.isRequired,
  column: PropTypes.number,
  points: PropTypes.number.isRequired,
  labelKey: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  dynamicStyles: PropTypes.object.isRequired,
  isLargeRow: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
}

export const isFactorSelected = selectedFactors => factor =>
  includes(selectedFactors, factor.key)

const RiskCalculatorFactors = flow(
  addProps(({factors: allFactors}) => ({
    factorsByRow: flow(
      () => allFactors,
      fgroupBy('row'),
      fmap(factors => ({factors, rowNum: factors[0].row}))
    )(),
  })),
  ({
    selected,
    factorsByRow,
    toggleFactor,
    isLargeRow,
    dynamicStyles,
    translationKey,
    factors,
  }) => (
    <BreakpointSwitch
      mobile={
        <div css={styles.listContainer}>
          {factors.map(factor => (
            <Factor
              row={factor.row}
              points={factor.points}
              labelKey={`${translationKey}.factors.${factor.key}`}
              key={factor.key}
              isSelected={isFactorSelected(selected)(factor)}
              onClick={toggleFactor(factor)}
              isLargeRow={isLargeRow}
              dynamicStyles={dynamicStyles}
              translationKey={translationKey}
            />
          ))}
        </div>
      }
      tablet={
        <div css={styles.rowsContainer}>
          {factorsByRow.map(({factors: rowFactors, rowNum}, rowIndex) => (
            <div
              css={[
                styles.row,
                rowIndex === factorsByRow.length - 1 && styles.rowLast,
                isLargeRow(rowNum) && styles.rowLarge,
                dynamicStyles[`row${rowNum}`],
              ]}
              key={rowNum}
            >
              {rowFactors.map((factor, index) => (
                <Factor
                  row={rowNum}
                  column={index + 1}
                  points={factor.points}
                  labelKey={`${translationKey}.factors.${factor.key}`}
                  key={factor.key}
                  isSelected={isFactorSelected(selected)(factor)}
                  onClick={toggleFactor(factor)}
                  isLargeRow={isLargeRow}
                  dynamicStyles={dynamicStyles}
                  translationKey={translationKey}
                />
              ))}
            </div>
          ))}
        </div>
      }
    />
  )
)

RiskCalculatorFactors.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  toggleFactor: PropTypes.func.isRequired,
  factors: PropTypes.array.isRequired,
  dynamicStyles: PropTypes.object.isRequired,
  translationKey: PropTypes.string.isRequired,
}

export default RiskCalculatorFactors

const styles = {
  rowsContainer: {
    alignSelf: 'center',
  },
  factorContainer: {
    [mq.mobile]: {
      marginBottom: 15,
      paddingTop: 21,
      paddingBottom: 18,
    },
    [mq.tablet]: {
      marginBottom: 0,
      width: 197,
      paddingTop: 15,
      paddingBottom: 8,
    },
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.borderGrey,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
    background: colors.white,
  },
  factorContainerLarge: {
    [mq.tablet]: {
      width: 231,
    },
    paddingLeft: 16,
    paddingRight: 16,
  },
  factorContainerSelected: {
    borderWidth: 3,
    [mq.mobile]: {
      marginBottom: 15,
      paddingTop: 20,
      paddingBottom: 17,
    },
    [mq.tablet]: {
      marginBottom: 0,
      paddingTop: 14,
      paddingBottom: 7,
    },
    borderColor: colors.black,
    backgroundColor: colors.yellowBackground,
  },
  label: {
    [mq.mobile]: {
      marginBottom: 10,
    },
    [mq.tablet]: {
      marginBottom: 0,
    },
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
    textAlign: 'center',
    color: colors.black,
  },
  labelLarge: {
    fontSize: 24,
    lineHeight: '32px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 129,
    justifyContent: 'center',
    marginBottom: 54,
  },
  rowLast: {
    marginBottom: 0,
  },
  rowLarge: {
    height: 165,
    marginBottom: 68,
  },
  points: {
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}
