import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import colors from 'style/colors'
import questions from 'data/quizQuestions'

const getProgressBarPercent = ({numCorrect}) => {
  if (numCorrect === 0) return 0
  if (numCorrect === questions.length) return 101
  return (numCorrect / questions.length) * 100
}

const ProgressBar = flow(
  ({numCorrect}) => ({
    percent: getProgressBarPercent({numCorrect}),
  }),
  ({percent}) => (
    <div css={styles.container}>
      <div css={styles.track} data-testid="progress-bar-track">
        <div css={[styles.endBulb, percent >= 100 && styles.endBulbReached]} />
        <div
          data-testid="progress-meter"
          css={[styles.meter, {width: `${percent}%`}]}
        />
      </div>
    </div>
  )
)

ProgressBar.propTypes = {
  numCorrect: PropTypes.number.isRequired,
}

export default ProgressBar

const styles = {
  container: {
    marginRight: 16,
  },
  track: {
    height: 0,
    width: '100%',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.midGrey,
    position: 'relative',
  },
  meter: {
    backgroundColor: colors.darkerGreen,
    borderRadius: 5,
    height: 10,
    position: 'absolute',
    top: -5,
    left: -1,
    transition: 'all 0.3s ease-in-out',
  },
  endBulb: {
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: colors.midGrey,
    position: 'absolute',
    right: -12,
    top: -12,
  },
  endBulbReached: {
    backgroundColor: colors.darkerGreen,
  },
}
