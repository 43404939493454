import React from 'react'
import PropTypes from 'prop-types'
import {flow, map as fmap, join as fjoin, omit as fomit} from 'lodash/fp'
import {addState, addProps, flowMax} from 'ad-hok'
import posed from 'react-pose'
import {Trans} from 'react-i18next'
import i18n from 'i18next'

import SpeechBubble from 'components/SpeechBubble'
import colors from 'style/colors'
import Button from 'components/Button'
import Lightbox from 'components/Lightbox'
import {addTranslationHelpers} from 'util/i18n'
import playButtonIcon from 'assets/images/play-button-white.svg'
import doctorAvatar1 from 'assets/images/doctor-avatar-1.svg'
import doctorAvatar2 from 'assets/images/doctor-avatar-2.svg'
import FactorsName from 'components/FactorsName'
import {mq} from 'style/mediaQueries'
import PatientStories from 'components/PatientStories'
import AudioHighlight from 'components/AudioHighlight'
import shadows from 'style/shadows'
import {getImgPropsFromSizes} from 'util/responsive'
import {imageSourcePropType} from 'util/propTypes'
import Image from 'components/Image'
import {callWith} from 'util/fp'
import VideoLightbox from 'components/VideoLightbox'
import LightboxCloseButton from 'components/LightboxCloseButton'
import zIndices from 'style/zIndices'

const doctorAvatars = [doctorAvatar1, doctorAvatar2]
const getDoctorAvatar = ({questionNumber}) =>
  doctorAvatars[questionNumber % doctorAvatars.length]

const getTextTag = ({isHeader, isListItem, isNestedListItem}) => {
  if (isHeader) return 'em'
  if (isListItem) return 'li'
  if (isNestedListItem) return 'li'
  return 'div'
}

const Text = ({
  content: {key},
  keyPrefix,
  isHeader,
  isListItem,
  isNestedListItem,
}) => {
  const Tag = getTextTag({isHeader, isListItem, isNestedListItem})
  return (
    <Tag
      css={[
        styles.speechBubbleText,
        isHeader && styles.speechBubbleTextHeader,
        isListItem && styles.speechBubbleListItem,
        isNestedListItem && styles.speechBubbleNestedListItem,
      ]}
    >
      <AudioHighlight clipKey={key}>
        <Trans i18nKey={`${keyPrefix}.${key}`} i18n={i18n}>
          <FactorsName />
        </Trans>
      </AudioHighlight>
    </Tag>
  )
}

Text.propTypes = {
  content: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  keyPrefix: PropTypes.string.isRequired,
  isHeader: PropTypes.bool,
  isListItem: PropTypes.bool,
  isNestedListItem: PropTypes.bool,
}

const Header = props => <Text {...props} isHeader />

Header.propTypes = Text.propTypes

const ListItem = props => <Text {...props} isListItem />

ListItem.propTypes = Text.propTypes

const NestedListItem = props => <Text {...props} isNestedListItem />

NestedListItem.propTypes = Text.propTypes

const List = flow(
  addProps(({isNested}) => ({
    Item: isNested ? NestedListItem : ListItem,
  })),
  ({content: {items}, keyPrefix, Item, isNested}) => (
    <ul css={isNested ? styles.nestedListContainer : styles.listContainer}>
      {items.map(item => (
        <Item content={item} keyPrefix={keyPrefix} key={item.key} />
      ))}
    </ul>
  )
)

List.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({key: PropTypes.string.isRequired}).isRequired
    ).isRequired,
  }).isRequired,
  keyPrefix: PropTypes.string.isRequired,
  isNested: PropTypes.bool,
}

const NestedList = props => <List {...props} isNested />

NestedList.propTypes = fomit(['isNested'])(List.propTypes)

const ImageAnswer = flow(
  addTranslationHelpers,
  addState('showingModal', 'setShowingModal', false),
  ({
    content: {imageSizes, imageSrcSet, expandedContent, alt, idKey},
    t,
    showingModal,
    setShowingModal,
  }) => (
    <>
      <Button
        mode="plain"
        onClick={() => setShowingModal(true)}
        css={styles.openImageButtonContainer}
      >
        <img
          alt={alt}
          data-testid={`faq-answer-image-${idKey}`}
          css={styles.answerImage}
          {...getImgPropsFromSizes({
            sizes: imageSizes,
            srcSet: imageSrcSet,
          })}
        />
        <span css={styles.openImageButtonButtonContainer}>
          <Button
            as="span"
            responsive
            css={styles.openImageButton}
            slim
            title={t('buttons.open')}
            data-testid={`faq-answer-image-open-button-${idKey}`}
          />
        </span>
      </Button>
      <Lightbox
        show={showingModal}
        onHide={() => setShowingModal(false)}
        css={styles.imageLightboxModalDialog}
        showCloseButton={false}
      >
        {expandedContent}
        <LightboxCloseButton
          onClick={() => setShowingModal(false)}
          data-testid={`faq-answer-image-close-button-${idKey}`}
          css={styles.imageLightboxCloseButtonContainer}
        />
      </Lightbox>
    </>
  )
)

ImageAnswer.propTypes = {
  content: PropTypes.shape({
    imageSizes: PropTypes.string.isRequired,
    imageSrcSet: PropTypes.object.isRequired,
    expandedContent: PropTypes.node.isRequired,
    alt: PropTypes.string.isRequired,
    idKey: PropTypes.string.isRequired,
  }).isRequired,
}

const VideoAnswer = flowMax(
  addTranslationHelpers,
  addState('showingModal', 'setShowingModal', false),
  ({
    content: {
      image,
      imageAspectRatio,
      idKey,
      alt,
      fallbackCacheKey,
      fallbackCacheCaptionsUrl,
      fallbackCacheThumbnail,
      hasSpanishVersion,
    },
    t,
    showingModal,
    setShowingModal,
  }) => (
    <>
      <Button mode="plain" onClick={() => setShowingModal(true)}>
        <Image
          src={image}
          alt={alt}
          data-testid={`faq-answer-video-${idKey}`}
          css={styles.answerVideoImage}
          aspectRatio={imageAspectRatio}
        />
      </Button>
      <Button
        responsive
        css={styles.openVideoButton}
        onClick={() => setShowingModal(true)}
        data-testid={`faq-answer-video-open-button-${idKey}`}
      >
        <img src={playButtonIcon} css={styles.openVideoButtonIcon} alt="" />
        <span css={styles.openVideoButtonLabel}>
          <AudioHighlight clipKey="play" dark>
            {t('buttons.playAnswer')}
          </AudioHighlight>
        </span>
      </Button>
      <VideoLightbox
        showingModal={showingModal}
        setShowingModal={setShowingModal}
        videoIdKey={idKey}
        videoTestId={`faq-answer-modal-video-${idKey}`}
        videoFallbackCacheKey={fallbackCacheKey}
        videoFallbackCacheCaptionsUrl={fallbackCacheCaptionsUrl}
        videoFallbackCacheThumbnail={fallbackCacheThumbnail}
        closeButtonTestId="faq-answer-modal-video-close-button"
        audioSectionId="faqVideoLightbox"
        videoEndedTrackingEvent="Finished watching FAQ video"
        videoStartedTrackingEvent="Started watching FAQ video"
        hasSpanishVersion={hasSpanishVersion}
      />
    </>
  )
)

VideoAnswer.propTypes = {
  content: PropTypes.shape({
    image: imageSourcePropType.isRequired,
    idKey: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    fallbackCacheKey: PropTypes.string,
    fallbackCacheCaptionsUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    fallbackCacheThumbnail: imageSourcePropType,
  }).isRequired,
}

const typeRenderers = {
  text: Text,
  image: ImageAnswer,
  video: VideoAnswer,
  header: Header,
  list: List,
  nestedList: NestedList,
  patientStories: PatientStories,
}

const getTypeRenderer = ({type}) => {
  const TypeRenderer = typeRenderers[type]
  if (!TypeRenderer) throw new Error(`Unknown FAQ answer type: ${type}`)
  return TypeRenderer
}

const getTypedContentStyles = ({
  content: {type},
  prevContent: {type: prevType} = {},
  index,
}) => {
  if (prevType === 'header') return null
  if (type === 'list' || type === 'nestedList') return null
  if (index > 0) return styles.typedContentWrapperNonFirst
  return null
}

const TypedContent = flow(
  addProps(({content: {type}}) => ({
    TypeRenderer: getTypeRenderer({type}),
  })),
  ({TypeRenderer, content, prevContent, keyPrefix, index}) => (
    <div
      css={[
        styles.typedContentWrapper,
        getTypedContentStyles({content, prevContent, index}),
      ]}
    >
      <TypeRenderer content={content} keyPrefix={keyPrefix} />
    </div>
  )
)

TypedContent.propTypes = {
  content: PropTypes.object.isRequired,
  prevContent: PropTypes.object,
  keyPrefix: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

const getListKey = flow(
  fmap('key'),
  fjoin('-')
)

const groupContentLists = contents => {
  const allContent = []
  let currentList = []
  let currentNestedList = []
  const pushCurrentList = () => {
    if (!currentList.length) return
    allContent.push({
      type: 'list',
      items: currentList,
      key: getListKey(currentList),
    })
    currentList = []
  }
  const pushCurrentNestedList = () => {
    if (!currentNestedList.length) return
    allContent.push({
      type: 'nestedList',
      items: currentNestedList,
      key: getListKey(currentNestedList),
    })
    currentNestedList = []
  }
  contents.forEach((content, index) => {
    if (content.type === 'listItem') {
      pushCurrentNestedList()
      currentList.push(content)
    } else if (content.type === 'nestedListItem') {
      pushCurrentList()
      currentNestedList.push(content)
    } else {
      pushCurrentList()
      pushCurrentNestedList()
      allContent.push(content)
    }
    if (index === contents.length - 1) {
      pushCurrentList()
      pushCurrentNestedList()
    }
  })
  return allContent
}

const normalizeContent = ({
  answer: {content, noAvatar, ...answer},
  isPatientStories,
}) => ({
  ...answer,
  content: callWith(content)(
    flow(
      fmap(({type = 'text', ...typedContent}) => ({
        ...typedContent,
        type,
      })),
      groupContentLists
    )
  ),
  noAvatar: noAvatar || isPatientStories,
})

export const QuestionOrAnswer = posed.div({
  enter: {
    scale: 1,
    opacity: 1,
    beforeChildren: true,
    transition: {duration: 200},
  },
  preEnter: {scale: 0.93, opacity: 0},
})

const FaqAnswer = flow(
  addProps(({answer: {content}}) => ({
    isPatientStories: content.length && content[0].type === 'patientStories',
  })),
  addProps(({answer, isPatientStories}) => ({
    answer: normalizeContent({answer, isPatientStories}),
  })),
  addProps(({questionNumber}) => ({
    doctorAvatar: getDoctorAvatar({questionNumber}),
  })),
  ({
    answer: {
      content,
      content: [{type: firstContentType}],
      noAvatar,
    },
    keyPrefix,
    doctorAvatar,
    isPatientStories,
  }) => (
    <QuestionOrAnswer
      css={[
        styles.questionAndAnswerRowContainer,
        styles.answerRowContainer,
        isPatientStories && styles.patientStoriesRowContainer,
      ]}
    >
      <div
        css={[
          styles.avatarContainer,
          styles.avatarContainerLeft,
          noAvatar && styles.avatarContainerHidden,
        ]}
      >
        <img
          src={doctorAvatar}
          alt=""
          css={[styles.avatar, styles.avatarLeft]}
        />
      </div>
      <SpeechBubble
        corner={noAvatar ? null : {mobile: 'leftBottom', tablet: 'bottomLeft'}}
        backgroundColor={colors.white}
        css={[
          styles.speechBubbleContainer,
          styles.answerSpeechBubbleContainer,
          firstContentType === 'video' &&
            styles.videoAnswerSpeechBubbleContainer,
        ]}
        offset={noAvatar ? null : {mobile: 78, tablet: 26}}
      >
        {content.map((typedContent, index) => (
          <TypedContent
            content={typedContent}
            prevContent={index > 0 ? content[index - 1] : undefined}
            keyPrefix={keyPrefix}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
          />
        ))}
      </SpeechBubble>
      <div css={styles.avatarContainer} />
    </QuestionOrAnswer>
  )
)

FaqAnswer.propTypes = {
  answer: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    noAvatar: PropTypes.bool,
  }).isRequired,
  keyPrefix: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
}

export default FaqAnswer

export const styles = {
  speechBubbleContainer: {
    maxWidth: 550,
    flexGrow: 1,
    [mq.tablet]: {
      alignSelf: 'flex-end',
    },
  },
  answerSpeechBubbleContainer: {
    [mq.mobile]: {
      paddingLeft: 14,
      paddingRight: 12,
    },
    [mq.tablet]: {
      paddingLeft: 25,
      paddingRight: 23,
    },
    paddingTop: 18,
    paddingBottom: 16,
  },
  speechBubbleText: {
    [mq.mobile]: {
      fontSize: 24,
      lineHeight: '34px',
    },
    [mq.tablet]: {
      fontSize: 26,
      lineHeight: '36px',
    },
    fontWeight: 400,
  },
  speechBubbleTextHeader: {
    fontWeight: 800,
  },
  speechBubbleListItem: {
    listStyleType: 'disc',
    marginLeft: 24,
  },
  speechBubbleNestedListItem: {
    listStyleType: 'circle',
    marginLeft: 36,
  },
  typedContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  typedContentWrapperNonFirst: {
    marginTop: 20,
  },
  openImageButtonContainer: {
    position: 'relative',
  },
  openImageButtonButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  openImageButton: {
    [mq.mobile]: {
      minHeight: 49,
    },
    backgroundColor: colors.blueText,
  },
  answerImage: {
    maxWidth: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    boxShadow: shadows.card,
  },
  answerVideoImage: {
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginBottom: 15,
  },
  openVideoButton: {
    [mq.mobile]: {
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 12,
      paddingRight: 28,
    },
    [mq.tablet]: {
      marginLeft: 27,
      paddingLeft: 20,
      paddingRight: 30,
    },
    backgroundColor: colors.blueText,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  openVideoButtonLabel: {
    [mq.mobile]: {
      marginLeft: 18,
    },
    [mq.tablet]: {
      marginLeft: 24,
    },
    fontSize: 26,
    lineHeight: '38px',
    fontWeight: 500,
    color: colors.white,
  },
  openVideoButtonIcon: {
    [mq.mobile]: {
      width: 44,
    },
    [mq.tablet]: {
      width: 'auto',
    },
  },
  videoAnswerSpeechBubbleContainer: {
    [mq.mobile]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingTop: 0,
    paddingBottom: 20,
  },
  questionAndAnswerRowContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.tablet]: {
      flexDirection: 'row',
    },
    display: 'flex',
    marginBottom: 40,
  },
  avatarContainer: {
    [mq.mobile]: {
      alignSelf: 'flex-end',
    },
    [mq.tablet]: {
      alignSelf: 'normal',
    },
    width: 128,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  avatar: {
    position: 'relative',
    bottom: -21,
    width: 89,
  },
  avatarContainerLeft: {
    [mq.mobile]: {
      alignSelf: 'flex-start',
    },
    [mq.tablet]: {
      alignSelf: 'normal',
    },
    alignItems: 'flex-start',
  },
  avatarLeft: {
    position: 'relative',
    left: 2,
    bottom: -18,
  },
  answerRowContainer: {
    [mq.mobile]: {
      flexDirection: 'column-reverse',
    },
    [mq.tablet]: {
      flexDirection: 'row',
    },
  },
  avatarContainerHidden: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.tablet]: {
      display: 'flex',
      visibility: 'hidden',
    },
  },
  patientStoriesRowContainer: {
    marginBottom: 10,
  },
  imageLightboxModalDialog: {
    [mq.mobile]: {
      width: 515,
    },
    [mq.desktop]: {
      width: 1350,
    },
    maxWidth: '96%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  listContainer: {
    marginTop: 12,
  },
  nestedListContainer: {},
  imageLightboxCloseButtonContainer: {
    zIndex: zIndices.imageLightboxCloseButtonContainer,
  },
}
