import React from 'react'
import {addHandlers, flowMax} from 'ad-hok'
import {navigate} from '@reach/router'

import Page from 'components/Page'
import Video from 'components/Video'
import {addTranslationHelpers} from 'util/i18n'
import Button, {
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import colors from 'style/colors'
import BackButton from 'components/BackButton'
import {mq} from 'style/mediaQueries'
/* eslint-disable known-imports/no-unused-vars */
import introVideo from 'assets/videos/intro-video-en.mp4'
import introVideoSpanish from 'assets/videos/intro-video-es.mp4'
/* eslint-enable known-imports/no-unused-vars */
import introVideoCaptions from 'assets/videos/intro-video-captions-en.vtt'
import introVideoCaptionsEnTraditionalChinese from 'assets/videos/intro-en-zh-Hant.vtt'
import introVideoCaptionsEnSimplifiedChinese from 'assets/videos/intro-en-zh.vtt'
import introVideoCaptionsEnVietnamese from 'assets/videos/intro-en-vi.vtt'
import introVideoCaptionsEnFarsi from 'assets/videos/intro-en-fa.vtt'
import introVideoCaptionsEnTagalog from 'assets/videos/intro-en-tl.vtt'
import introVideoCaptionsEnRussian from 'assets/videos/intro-en-ru.vtt'
import introVideoCaptionsEnPortuguese from 'assets/videos/intro-en-pt.vtt'
import introVideoCaptionsEnKorean from 'assets/videos/intro-en-ko.vtt'
import introVideoCaptionsEnHindi from 'assets/videos/intro-en-hi.vtt'
import introVideoCaptionsEnGerman from 'assets/videos/intro-en-de.vtt'
import introVideoCaptionsEnFrench from 'assets/videos/intro-en-fr.vtt'
import introVideoCaptionsEnArabic from 'assets/videos/intro-en-ar.vtt'
import introVideoCaptionsSpanish from 'assets/videos/intro-video-captions-es.vtt'
import introVideoCaptionsEsTraditionalChinese from 'assets/videos/intro-es-zh-Hant.vtt'
import introVideoCaptionsEsSimplifiedChinese from 'assets/videos/intro-es-zh.vtt'
import introVideoCaptionsEsVietnamese from 'assets/videos/intro-es-vi.vtt'
import introVideoCaptionsEsFarsi from 'assets/videos/intro-es-fa.vtt'
import introVideoCaptionsEsTagalog from 'assets/videos/intro-es-tl.vtt'
import introVideoCaptionsEsRussian from 'assets/videos/intro-es-ru.vtt'
import introVideoCaptionsEsPortuguese from 'assets/videos/intro-es-pt.vtt'
import introVideoCaptionsEsKorean from 'assets/videos/intro-es-ko.vtt'
import introVideoCaptionsEsHindi from 'assets/videos/intro-es-hi.vtt'
import introVideoCaptionsEsGerman from 'assets/videos/intro-es-de.vtt'
import introVideoCaptionsEsFrench from 'assets/videos/intro-es-fr.vtt'
import introVideoCaptionsEsArabic from 'assets/videos/intro-es-ar.vtt'
import introVideoThumbnail from 'assets/images/intro-video-thumbnail.png'
import {addTrackingToHandler} from 'util/tracking'

const nextPageUrl = '/intro/review'

const IntroVideo = flowMax(
  addHandlers({
    onEnded: () => () => {
      navigate(nextPageUrl)
    },
  }),
  addTrackingToHandler('onEnded', 'Finished watching intro video'),
  addTrackingToHandler('onStart', 'Started watching intro video'),
  addTranslationHelpers,
  ({onEnded, onStart, t}) => (
    <Page
      css={styles.container}
      background={colors.lighterBlueBackground}
      audioSectionId="introVideoPage"
      name="Intro video"
    >
      <BackButton />
      <div css={styles.contentContainer}>
        <Video
          idKey="intro"
          onEnded={onEnded}
          onStart={onStart}
          title={t('introVideoPage.videoTitle')}
          audioClipKey="videoTitle"
          fallbackCacheKey="introVideo"
          hasSpanishVersion
          fallbackCacheCaptionsUrl={{
            en: {
              en: introVideoCaptions,
              ar: introVideoCaptionsEnArabic,
              fr: introVideoCaptionsEnFrench,
              de: introVideoCaptionsEnGerman,
              hi: introVideoCaptionsEnHindi,
              ko: introVideoCaptionsEnKorean,
              pt: introVideoCaptionsEnPortuguese,
              ru: introVideoCaptionsEnRussian,
              tl: introVideoCaptionsEnTagalog,
              fa: introVideoCaptionsEnFarsi,
              vi: introVideoCaptionsEnVietnamese,
              zh: introVideoCaptionsEnSimplifiedChinese,
              'zh-Hant': introVideoCaptionsEnTraditionalChinese,
            },
            es: {
              es: introVideoCaptionsSpanish,
              ar: introVideoCaptionsEsArabic,
              fr: introVideoCaptionsEsFrench,
              de: introVideoCaptionsEsGerman,
              hi: introVideoCaptionsEsHindi,
              ko: introVideoCaptionsEsKorean,
              pt: introVideoCaptionsEsPortuguese,
              ru: introVideoCaptionsEsRussian,
              tl: introVideoCaptionsEsTagalog,
              fa: introVideoCaptionsEsFarsi,
              vi: introVideoCaptionsEsVietnamese,
              zh: introVideoCaptionsEsSimplifiedChinese,
              'zh-Hant': introVideoCaptionsEsTraditionalChinese,
            },
          }}
          fallbackCacheThumbnail={introVideoThumbnail}
        />
      </div>
      <div css={styles.skipButtonRowContainer}>
        <Button
          css={styles.skipButton}
          linkTo={nextPageUrl}
          title={t('introVideoPage.skipButton')}
          audioClipKey="skipButton"
          trackAs="Skip intro video"
        />
      </div>
    </Page>
  )
)

export default IntroVideo

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 126,
    [mq.mobile]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [mq.tablet]: {
      paddingLeft: 28,
      paddingRight: 28,
    },
    paddingBottom: 38,
  },
  skipButtonRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 38,
  },
  skipButton: {
    [mq.mobile]: mobileButtonResponsiveStyles,
    [mq.tablet]: normalButtonResponsiveStyles,
  },
  contentContainer: {
    [mq.mobile]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [mq.tablet]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
    [mq.desktop]: {
      paddingLeft: 79,
      paddingRight: 79,
    },
    display: 'flex',
    flexDirection: 'column',
  },
}
