import {useEffect} from 'react'
import {find} from 'lodash'

import usePrevious from 'util/usePrevious'
import {ensureArray} from 'util/fp'

const addEffectOnPropChange = (changeProps, callback) => props => {
  const prevProps = usePrevious(props) || {}
  useEffect(() => {
    const changed = find(
      ensureArray(changeProps),
      changeProp => prevProps[changeProp] !== props[changeProp]
    )
    if (!changed) return null
    return callback(props, prevProps)
  })
  return props
}

export default addEffectOnPropChange
