import React from 'react'
import PropTypes from 'prop-types'
import {flow, map as fmap, filter as ffilter} from 'lodash/fp'
import {max, min, range} from 'lodash'
import {addProps} from 'ad-hok'

import colors from 'style/colors'
import {fmapWithKey} from 'util/fp'

const rangeColors = [colors.orange, colors.lavender, colors.borderGrey]

const numRows = 10
const iconWidth = 21
const iconHeight = 42
const iconHorizontalGap = 15
const iconVerticalGap = 11.71
const iconsPerRow = 100 / numRows
const totalHeight = numRows * iconHeight + (numRows - 1) * iconVerticalGap
const totalWidth =
  iconsPerRow * iconWidth + (iconsPerRow - 1) * iconHorizontalGap
const getCoordinates = flow(
  ({index}) => ({
    row: Math.floor(index / iconsPerRow),
    column: index % iconsPerRow,
  }),
  ({row, column}) => ({
    x: column * (iconWidth + iconHorizontalGap),
    y: row * (iconHeight + iconVerticalGap),
  })
)

const PartialIcon = flow(
  addProps(({index, rangeIndex}) => ({
    clipPathId: `percentage-icon-clip-path-${index}-${rangeIndex}`,
  })),
  ({fractionStart, fractionEnd, color, clipPathId}) => (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox={`0 0 ${iconWidth} ${iconHeight}`}
    >
      <defs>
        <clipPath id={clipPathId}>
          <rect
            height={iconHeight}
            width={(fractionEnd - fractionStart) * iconWidth}
            x={fractionStart * iconWidth}
          />
        </clipPath>
      </defs>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M15.2942 5.27161C15.2942 7.75288 13.2841 9.76295 10.8029 9.76295C8.32159 9.76295 6.31152 7.75288 6.31152 5.27161C6.31152 2.79034 8.32159 0.780273 10.8029 0.780273C13.2841 0.780273 15.2942 2.79034 15.2942 5.27161Z"
          fill={color}
        />
        <path
          d="M20.7667 21.097L16.5327 13.1837C15.7624 11.73 14.2653 10.8311 12.6395 10.8311H8.91847C7.29269 10.8311 5.79558 11.73 5.02529 13.1837L0.833036 21.097C0.320082 22.0811 0.662604 23.2792 1.64507 23.7921C1.94416 23.9625 2.28669 24.0494 2.58577 24.0494C3.3126 24.0494 3.99768 23.6651 4.34021 22.9801L6.82149 18.3185L4.85317 38.8503C4.72451 40.1335 5.6652 41.2881 6.94844 41.4168H7.16231C8.36035 41.4168 9.38625 40.5178 9.47146 39.3215L10.7547 26.0177L12.0379 39.3215C12.1666 40.5195 13.1508 41.4168 14.3471 41.4168H14.561C15.8442 41.2881 16.7849 40.1335 16.6562 38.8503L14.6879 18.3185L17.1692 22.9801C17.5117 23.6651 18.2386 24.0494 18.9236 24.0494C19.2227 24.0494 19.5652 23.9642 19.8643 23.7921C20.937 23.2792 21.2796 22.0811 20.7666 21.097H20.7667Z"
          fill={color}
        />
      </g>
    </svg>
  )
)

PartialIcon.propTypes = {
  fractionStart: PropTypes.number.isRequired,
  fractionEnd: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  rangeIndex: PropTypes.number.isRequired,
}

const Icon = flow(
  addProps(({index, ranges}) => ({
    ...getCoordinates({index}),
    rangeFractions: fmap(([rangeMin, rangeMax]) => {
      if (index <= rangeMin - 1) return [0, 0]
      if (index >= rangeMax) return [0, 0]
      return [max([rangeMin - index, 0]), min([rangeMax - index, 1])]
    })(ranges),
  })),
  ({rangeFractions, x, y, index}) => (
    <g transform={`translate(${x} ${y})`}>
      {flow(
        () => rangeFractions,
        fmapWithKey(([fractionStart, fractionEnd], rangeIndex) => ({
          fractionStart,
          fractionEnd,
          index: rangeIndex,
        })),
        ffilter(
          ({fractionStart, fractionEnd}) => fractionEnd - fractionStart > 0
        ),
        fmap(({fractionStart, fractionEnd, index: rangeIndex}) => (
          <PartialIcon
            fractionStart={fractionStart}
            fractionEnd={fractionEnd}
            key={rangeIndex}
            color={rangeColors[rangeIndex]}
            index={index}
            rangeIndex={rangeIndex}
          />
        ))
      )()}
    </g>
  )
)

Icon.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
  ).isRequired,
  index: PropTypes.number.isRequired,
}

const PercentageGraphic = flow(
  ({withBloodThinner, withPercentage, withoutPercentage}) => ({
    firstMax: withBloodThinner ? withPercentage : withoutPercentage,
    secondMax: withoutPercentage,
  }),
  ({firstMax, secondMax}) => ({
    ranges: [[0, firstMax], [firstMax, secondMax], [secondMax, 100]],
  }),
  ({ranges}) => (
    <svg
      width={totalWidth}
      viewBox={`0 0 ${totalWidth} ${totalHeight}`}
      css={styles.container}
    >
      {range(100).map(index => (
        <Icon ranges={ranges} index={index} key={index} />
      ))}
    </svg>
  )
)

PercentageGraphic.propTypes = {
  withBloodThinner: PropTypes.bool.isRequired,
  withPercentage: PropTypes.number.isRequired,
  withoutPercentage: PropTypes.number.isRequired,
}

export default PercentageGraphic

const styles = {
  container: {
    maxWidth: '100%',
    height: 'auto',
  },
}
