import {connect} from 'react-redux'
import {flowMax, renderNothing} from 'ad-hok'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import {languageSelector} from 'redux-local/selectors'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import addEffectOnMount from 'util/addEffectOnMount'

const enhance = connect(languageSelector)

const WatchLanguage = flowMax(
  addEffectOnMount(({currentLanguage}) => () => {
    i18n.changeLanguage(currentLanguage)
    moment.locale(currentLanguage)
  }),
  addEffectOnPropChange('currentLanguage', ({currentLanguage}) => {
    i18n.changeLanguage(currentLanguage)
    moment.locale(currentLanguage)
  }),
  renderNothing()
)

WatchLanguage.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
}

export default enhance(WatchLanguage)
