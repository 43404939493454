import React from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import {addProps} from 'ad-hok'
import {range} from 'lodash'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import colors from 'style/colors'
import shadows from 'style/shadows'
import infoIcon from 'assets/images/info.svg'
import plusIcon from 'assets/images/plus.svg'
import {refPropType} from 'util/propTypes'
import {disableArrowPopperConfig} from 'util/popper'
import zIndices from 'style/zIndices'

const Symbol = () => <img src={plusIcon} css={styles.symbol} alt="" />

const LegendRow = flow(
  addTranslationHelpers,
  addProps(({t, translationKey}) => ({
    label: t(
      `clinicianBloodThinnerTablePage.reversalTooltipLegend.${translationKey}`
    ),
  })),
  ({numSymbols, label}) => (
    <div css={styles.legendRowContainer}>
      <div css={styles.symbolsContainer}>
        {range(numSymbols).map(index => (
          <Symbol key={index} />
        ))}
      </div>
      <p css={styles.legendLabel}>{label}</p>
    </div>
  )
)

LegendRow.propTypes = {
  numSymbols: PropTypes.number.isRequired,
  translationKey: PropTypes.string.isRequired,
}

export const reversalAgentTranslationKeys = [
  null,
  'limitedAvailability',
  'widelyAvailable',
  'generallyAvailable',
]

const ClinicianReversalAgentsTooltip = flow(
  addTranslationHelpers,
  ({isOpen, onOpen, onClose, columnHeaderRef, containerRef, t}) => (
    <>
      <Button mode="plain" onClick={onOpen} css={styles.openButton}>
        <img
          src={infoIcon}
          alt={t('clinicianBloodThinnerTablePage.reversalTooltipHeader')}
          data-testid="clinicianBloodThinnerTablePage-reversal-agents-info"
        />
      </Button>
      <Overlay
        target={columnHeaderRef.current}
        show={isOpen}
        placement="bottom-end"
        popperConfig={disableArrowPopperConfig}
        container={containerRef.current}
      >
        {({show, ...props}) => (
          <Tooltip {...props} css={styles.tooltipContainer}>
            <div css={styles.container}>
              <h2 css={styles.header}>
                {t('clinicianBloodThinnerTablePage.reversalTooltipHeader')}
              </h2>
              <LegendRow
                numSymbols={3}
                translationKey={reversalAgentTranslationKeys[3]}
              />
              <LegendRow
                numSymbols={2}
                translationKey={reversalAgentTranslationKeys[2]}
              />
              <LegendRow
                numSymbols={1}
                translationKey={reversalAgentTranslationKeys[1]}
              />
              <Button
                mode="secondary"
                slim
                css={styles.closeButton}
                title={t('buttons.close')}
                onClick={onClose}
                data-testid="clinicianBloodThinnerTablePage-reversal-agents-tooltip-close"
              />
            </div>
          </Tooltip>
        )}
      </Overlay>
    </>
  )
)

ClinicianReversalAgentsTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  columnHeaderRef: refPropType.isRequired,
  containerRef: refPropType,
}

export default ClinicianReversalAgentsTooltip

const styles = {
  container: {
    borderWidth: 2,
    borderColor: colors.black,
    borderStyle: 'solid',
    boxShadow: shadows.card,
    borderRadius: 10,
    backgroundColor: colors.white,
    paddingTop: 45,
    paddingLeft: 41,
    paddingRight: 26,
    paddingBottom: 20,
    maxWidth: 465,
    display: 'flex',
    flexDirection: 'column',
  },
  openButton: {
    position: 'relative',
    top: 6,
    marginLeft: 3,
  },
  closeButton: {
    alignSelf: 'flex-end',
    fontSize: 18,
    lineHeight: '26px',
    color: colors.black,
    fontWeight: 500,
    marginTop: 6,
  },
  header: {
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 600,
    marginBottom: 21,
  },
  legendRowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  symbolsContainer: {
    width: 52,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexShrink: 0,
    paddingTop: 7,
  },
  legendLabel: {
    fontSize: 18,
    lineHeight: '31px',
    marginLeft: 15,
  },
  symbol: {
    marginLeft: 3,
  },
  tooltipContainer: {
    zIndex: zIndices.clinicianComparisonTableTooltip,
  },
}
