import React from 'react'
import {addWrapper} from 'ad-hok'
import {ClassNames} from '@emotion/core'

const addCssHelper = () =>
  addWrapper(({render}) => (
    <ClassNames>{({css: cssHelper}) => render({cssHelper})}</ClassNames>
  ))

export default addCssHelper
