import {addEffect} from 'ad-hok'

const addEffectOnUnmount = callback =>
  addEffect(
    props => () => () => {
      callback(props)
    },
    []
  )

export default addEffectOnUnmount
