export default {
  skipLink: 1000,
  titleBar: 900,
  closeMenu: 510,
  menu: 500,
  introPageHardy: 10,
  introPageContent: 10,
  faqNavHeader: 10,
  journeyBackButton: 200,
  journeySteps: 100,
  journeyMenu: 90,
  journeyHardy: 40,
  journeyTreesInFrontOfHill: 30,
  journeySwoosh: 30,
  journeyHill: 20,
  journeyTreesBehindHill: 10,
  quizOverlayContent: 1100,
  quizOverlay: 1000,
  welcomeContent: 10,
  tooltip: 10,
  lightboxCloseButton: 10,
  imageLightboxCloseButtonContainer: 10,
  clinicianCalculatorPageStickyHeader: 10,
  clinicianPercentagePageHeaderLeftColumn: 10,
  clinicianPercentagePageHeaderRightColumn: 10,
  clinicianComparisonTableTooltip: 2000,
  clinicianComparisonTable: 10,
}
