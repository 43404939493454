import {addWrapperHOC} from 'ad-hok'
import {connect} from 'react-redux'

const addRedux = (mapStateToProps, mapDispatchToProps) =>
  addWrapperHOC(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )

export default addRedux
