import {PoseGroup as BasePoseGroup} from 'react-pose'
import {upperFirst} from 'lodash'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'
import PropTypes from 'prop-types'
import {createFactory} from 'react'

const PoseGroup = flow(
  addProps(({suffix}) =>
    suffix
      ? {
          preEnterPose: `preEnter${upperFirst(suffix)}`,
          enterPose: `enter${upperFirst(suffix)}`,
          exitPose: `exit${upperFirst(suffix)}`,
        }
      : {}
  ),
  createFactory(BasePoseGroup)
)

PoseGroup.propTypes = {
  suffix: PropTypes.string,
  ...BasePoseGroup.propTypes,
}

export default PoseGroup
