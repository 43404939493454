import React from 'react'
import {flow} from 'lodash/fp'

import IntroPage from 'components/IntroPage'
import {mq} from 'style/mediaQueries'
import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'

const SkipFaqLink = flow(
  addTranslationHelpers,
  ({t}) => (
    <Button
      mode="link"
      title={t('faqIntroPage.skip')}
      linkTo="/steps/3"
      css={styles.skipButton}
    />
  )
)

const AdditionalContentAfterButton = () => <SkipFaqLink />

const FaqIntro = props => (
  <IntroPage
    {...props}
    linkTo="/common-questions/all"
    translationKey="faqIntroPage"
    hardyCss={styles.hardy}
    questionCss={styles.question}
    headerCss={styles.header}
    containerCss={styles.container}
    audioSectionId="faqIntroPage"
    buttonKey="letsBegin"
    name="FAQ intro"
    additionalContentAfterButton={<AdditionalContentAfterButton />}
  />
)

export default FaqIntro

const styles = {
  hardy: {
    [mq.tablet]: {
      bottom: 378,
      left: '79%',
    },
  },
  question: {
    paddingRight: 64,
    marginBottom: 35,
  },
  header: {
    fontWeight: 600,
  },
  container: {
    paddingTop: 168,
  },
  skipButton: {
    marginTop: 38,
  },
}
