import React from 'react'
import {flow} from 'lodash/fp'
import {addHandlers, addProps, flowMax} from 'ad-hok'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {fmapWithKey} from 'util/fp'
import colors from 'style/colors'
import {classNamePropType} from 'util/propTypes'
import {addCurrentLanguage, addTranslationHelpers} from 'util/i18n'
import {mq} from 'style/mediaQueries'
import {toggleAudioMode, setLanguage} from 'redux-local/actions'
import Button from 'components/Button'
import {addLogTrackingEvent} from 'util/tracking'

const languages = [
  {code: 'en', label: 'English'},
  {code: 'es', label: 'Español'},
]

const enhance = connect(
  null,
  {toggleAudioMode, setLanguage}
)

const LanguageSelector = flowMax(
  addCurrentLanguage,
  addLogTrackingEvent,
  addHandlers({
    onChoose: ({
      toggleAudioMode: toggleAudio,
      setLanguage: setLanguageAction,
      logTrackingEvent,
    }) => code => () => {
      setLanguageAction(code)
      toggleAudio()
      toggleAudio()
      logTrackingEvent('Choose language')
    },
  }),
  addTranslationHelpers,
  ({onChoose, currentLanguage, className, t}) => (
    <div
      role="radiogroup"
      className={className}
      css={styles.container}
      aria-label={t('languageSelector.legend')}
    >
      {fmapWithKey(
        flow(
          (props, index) => ({...props, index}),
          addProps(({code}) => ({
            isSelected: currentLanguage === code,
          })),
          ({code, label, isSelected, index}) => (
            <Button
              onClick={onChoose(code)}
              title={label}
              key={code}
              css={[styles.language, isSelected && styles.selected]}
              mode="plain"
              data-testid={isSelected && `language-selected-${code}`}
              role="radio"
              aria-checked={isSelected ? 'true' : 'false'}
              aria-posinset={index + 1}
              aria-setsize={languages.length}
              lang={code}
            />
          )
        )
      )(languages)}
    </div>
  )
)

LanguageSelector.propTypes = {
  className: classNamePropType,
  toggleAudioMode: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
}

export default enhance(LanguageSelector)

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    [mq.tablet]: {
      marginRight: 60,
    },
    [mq.desktop]: {
      marginRight: 130,
    },
  },
  language: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.5)',
    color: colors.greyDeselectedText,
    fontSize: 18,
    paddingLeft: 12,
    paddingRight: 12,
    '&:first-of-type': {
      borderLeft: 'none',
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
  },
  selected: {
    color: colors.black,
    fontWeight: 600,
  },
}
