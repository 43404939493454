import React from 'react'
import {flow} from 'lodash/fp'
import {addHandlers} from 'ad-hok'

import {classNamePropType, childrenPropType} from 'util/propTypes'
import colors from 'style/colors'
import {addTranslationHelpers} from 'util/i18n'
import backArrow from 'assets/images/back-arrow.svg'
import Button from 'components/Button'
import {mq} from 'style/mediaQueries'

const BackButton = flow(
  addHandlers({
    onClick: () => () => {
      window.history.back()
    },
  }),
  addTranslationHelpers,
  ({t, children, mode = 'plain', ...props}) => (
    <Button
      mode={mode}
      css={[styles.container, !children && styles.containerStandard]}
      {...props}
    >
      {children || (
        <>
          <img src={backArrow} alt="" />
          <span css={styles.label}>{t('buttons.back')}</span>
        </>
      )}
    </Button>
  )
)

export default BackButton

BackButton.propTypes = {
  className: classNamePropType,
  children: childrenPropType,
  mode: Button.propTypes.mode,
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 500,
    color: colors.darkerGrey,
    marginLeft: 11,
    position: 'relative',
    top: 1,
  },
  containerStandard: {
    position: 'absolute',
    [mq.mobile]: {
      left: 3,
    },
    [mq.desktop]: {
      left: 30,
    },
    top: 74,
    height: 38,
    paddingLeft: 18,
    paddingRight: 25,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    borderRadius: 19,
  },
}
