import React from 'react'
import {flow} from 'lodash/fp'
import {Location} from '@reach/router'
import {addStateHandlers} from 'ad-hok'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import closeIcon from 'assets/images/close-menu.svg'
import menuIcon from 'assets/images/hamburger-menu.svg'
import colors from 'style/colors'
import Button from 'components/Button'
import ClinicianHome from 'pages/ClinicianHome'
import {height as titleBarHeight} from 'components/TitleBar'
import shadows from 'style/shadows'
import {mq} from 'style/mediaQueries'
import {a11yStyles} from 'util/a11y'

const CloseMenuButton = flow(
  addTranslationHelpers,
  ({t, onClick}) => (
    <Button
      mode="plain"
      css={[styles.buttonContainer, styles.closeButtonContainer]}
      onClick={onClick}
    >
      <img src={closeIcon} alt="" />
      <span css={[styles.menuLabel, styles.closeMenuLabel]}>
        {t('clinicianMenu.closeMenu')}
      </span>
    </Button>
  )
)

CloseMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const MenuButton = flow(
  addTranslationHelpers,
  ({t, onClick}) => (
    <Button mode="plain" css={styles.buttonContainer} onClick={onClick}>
      <img src={menuIcon} alt="" />
      <span css={styles.menuLabel}>{t('clinicianMenu.menu')}</span>
    </Button>
  )
)

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const ClinicianTitleBarAction = flow(
  addStateHandlers(
    {isMenuOpen: false},
    {
      onMenuOpen: () => () => ({isMenuOpen: true}),
      onMenuClose: () => () => ({isMenuOpen: false}),
    }
  ),
  ({isMenuOpen, onMenuOpen, onMenuClose}) => (
    <>
      {isMenuOpen && (
        <>
          <div css={styles.menuContainer}>
            <ClinicianHome
              onLinkClick={onMenuClose}
              css={styles.clinicianHomeContainer}
              isMenu
            />
          </div>
          <div css={styles.menuTitleBarShadowHack} />
        </>
      )}
      <div css={styles.container}>
        <Location>
          {({location: {pathname}}) => {
            if (isMenuOpen) return <CloseMenuButton onClick={onMenuClose} />
            if (/^\/clinician\/[^home].+/.test(pathname))
              return <MenuButton onClick={onMenuOpen} />
            return null
          }}
        </Location>
      </div>
    </>
  )
)

export default ClinicianTitleBarAction

const styles = {
  container: {
    flex: 1,
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    top: 2,
  },
  closeButtonContainer: {
    top: 1,
  },
  menuLabel: {
    [mq.mobile]: a11yStyles.visuallyHidden,
    [mq.tablet]: a11yStyles.notVisuallyHidden,
    fontSize: 18,
    fontWeight: 300,
    color: colors.black,
    marginLeft: 13,
  },
  closeMenuLabel: {
    position: 'relative',
    top: 1,
  },
  menuContainer: {
    position: 'fixed',
    top: titleBarHeight,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
  menuTitleBarShadowHack: {
    position: 'fixed',
    top: titleBarHeight - 5,
    height: 5,
    left: 0,
    right: 0,
    boxShadow: shadows.titleBar,
  },
  clinicianHomeContainer: {
    marginTop: -titleBarHeight,
  },
}
