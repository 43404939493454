import React from 'react'
import facepaint from 'facepaint-object-keys'
import {
  mapValues as fmapValues,
  flow,
  map as fmap,
  assignAll as fassignAll,
} from 'lodash/fp'
import {map, isUndefined, find, upperFirst} from 'lodash'
import MediaQuery from 'react-responsive'
import {addProps, flowMax} from 'ad-hok'

import {ensureArray} from 'util/fp'
import addWindowSize from 'util/addWindowSize'

const defaultBreakpoint = 'mobile'
const breakpoints = {
  mobileMax: 480,
  tablet: 768,
  desktop: 1024,
  biggerThan1024: 1025,
}

const mediaQueries = fmapValues(pixels => `@media(min-width: ${pixels}px)`)(
  breakpoints
)

export const mq = facepaint(mediaQueries, {default: defaultBreakpoint})

mq[defaultBreakpoint] = '&'
Object.assign(mq, mediaQueries)

/* eslint-disable react/destructuring-assignment */
const DefaultBreakpointMediaQuery = props => {
  const content = props[defaultBreakpoint]
  const smallestDefinedBreakpoint = find(
    breakpoints,
    (_, breakpoint) => !isUndefined(props[breakpoint])
  )
  if (!smallestDefinedBreakpoint) return content

  return (
    <MediaQuery query={`(max-width: ${smallestDefinedBreakpoint - 1}px)`}>
      {content}
    </MediaQuery>
  )
}

export const BreakpointSwitch = props => (
  <>
    {!isUndefined(props[defaultBreakpoint]) && (
      <DefaultBreakpointMediaQuery {...props} />
    )}
    {map(breakpoints, (pixels, breakpoint) =>
      !isUndefined(props[breakpoint]) ? (
        <MediaQuery query={`(min-width: ${pixels}px)`} key={breakpoint}>
          {props[breakpoint]}
        </MediaQuery>
      ) : null
    )}
  </>
)

export const addResponsiveBreakpointProps = flow(
  ensureArray,
  breakpointNames =>
    flowMax(
      addWindowSize,
      addProps(({innerWidth}) =>
        flow(
          () => breakpointNames,
          fmap(breakpointName => ({
            [`isBelow${upperFirst(breakpointName)}`]:
              innerWidth < breakpoints[breakpointName],
          })),
          fassignAll
        )()
      )
    )
)
