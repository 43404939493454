import React, {createContext} from 'react'
import posed from 'react-pose'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {addRef, addContext, flowMax} from 'ad-hok'

import {childrenPropType, classNamePropType, cssPropType} from 'util/propTypes'
import {maxWidth} from 'style/layout'
import {mq} from 'style/mediaQueries'
import {playAudioSection} from 'redux-local/actions'
import addEffectOnMount from 'util/addEffectOnMount'
import AudioSection from 'components/AudioSection'
import zIndices from 'style/zIndices'
import {addInactivityContext} from 'context/inactivityContext'
import {addQueryParams} from 'util/queryString'
import {tap} from 'util/fp'
import {bugsnagNotify} from 'util/bugsnag'
import {addTrackingOnMount, addTrackingProps} from 'util/tracking'

const enhance = connect(
  null,
  (dispatch, {audioSectionId}) => ({
    onAudioModeStart: () => {
      if (!audioSectionId) return
      dispatch(playAudioSection(audioSectionId))
    },
  })
)

const Container = posed.div({
  enter: {opacity: 1},
  exit: {opacity: 0},
})

const PageRefContext = createContext()

export const addPageRef = addContext(PageRefContext, 'pageRef')

const addInactivityResetting = flowMax(
  addInactivityContext,
  addEffectOnMount(({onInactivityReset}) => () => {
    onInactivityReset()
  }),
  ({onInactivityReset, ...props}) => props
)

const addErrorTriggering = flowMax(
  addQueryParams([
    '_trigger_error_notify',
    '_trigger_error_component',
    '_trigger_error_callback',
  ]),
  tap(
    ({
      _trigger_error_notify: triggerErrorNotify,
      _trigger_error_component: triggerErrorComponent,
      _trigger_error_callback: triggerErrorCallback,
    }) => {
      if (triggerErrorNotify) {
        bugsnagNotify(new Error('Test error via _trigger_error_notify'))
      }
      if (triggerErrorComponent) {
        throw new Error('Test error via _trigger_error_component')
      }
      if (triggerErrorCallback) {
        setTimeout(() => {
          throw new Error('Test error via _trigger_error_callback')
        }, 200)
      }
    }
  )
)

export const pageContentId = 'main-content'

const Page = flowMax(
  addErrorTriggering,
  addTrackingProps(({name}) => ({name})),
  addTrackingOnMount(({name}) => (name ? 'Load page' : null)),
  addEffectOnMount(({onAudioModeStart}) => () => {
    onAudioModeStart()
  }),
  addInactivityResetting,
  addRef('pageRef'),
  ({
    className,
    children,
    background,
    backgroundColor,
    footer,
    containerCss,
    onAudioModeStart,
    audioSectionId,
    audioSectionHighlightScrollOffset,
    MainTag = 'main',
    pageRef,
    exposeRef,
    name,
    ...props
  }) => {
    const content = (
      <PageRefContext.Provider value={pageRef}>
        <Container
          {...props}
          css={[
            styles.container,
            containerCss,
            mq({background, backgroundColor}),
          ]}
          id={pageContentId}
        >
          {exposeRef && (
            <div
              css={styles.tooltipOverlay}
              ref={pageRef}
              aria-live="assertive"
            />
          )}
          <MainTag className={className} css={styles.contentContainer}>
            {children}
          </MainTag>
          {footer}
        </Container>
      </PageRefContext.Provider>
    )
    if (!audioSectionId) return content
    return (
      <AudioSection
        id={audioSectionId}
        highlightScrollOffset={audioSectionHighlightScrollOffset}
      >
        {content}
      </AudioSection>
    )
  }
)

Page.propTypes = {
  className: classNamePropType,
  children: childrenPropType.isRequired,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backgroundColor: PropTypes.string,
  footer: PropTypes.node,
  containerCss: cssPropType,
  onAudioModeStart: PropTypes.func.isRequired,
  audioSectionId: PropTypes.string,
  audioSectionHighlightScrollOffset: PropTypes.number,
  MainTag: PropTypes.string,
  exposeRef: PropTypes.bool,
  name: PropTypes.string,
}

export default enhance(Page)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  contentContainer: {
    width: '100%',
    maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexGrow: 1,
    position: 'relative',
  },
  tooltipOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .tooltip': {
      zIndex: zIndices.tooltip,
    },
  },
}
