import React from 'react'
import {flowMax, branch, renderNothing} from 'ad-hok'
import moment from 'moment'

import {addTranslationHelpers, addCurrentLanguage} from 'util/i18n'
import Button, {
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import colors from 'style/colors'
import Page from 'components/Page'
import cloudLeft from 'assets/images/welcome-cloud-small.svg'
import cloudRight from 'assets/images/welcome-cloud-large.svg'
import HillsideFooter from 'components/HillsideFooter'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import zIndices from 'style/zIndices'
import {precacheAllAssets, addPrecaching} from 'util/serviceWorker'
import {LAST_DEPLOY_DATE} from 'config/deployed'

const INITIAL_RELEASE_DATE = '2019-07-01'

const monthFormat = {
  en: 'MMMM YYYY',
  es: 'MMMM [de] YYYY',
}
const getFormattedMonth = ({currentLanguage, month}) =>
  moment(month).format(monthFormat[currentLanguage])

const ReleasedUpdated = flowMax(
  branch(() => !LAST_DEPLOY_DATE, renderNothing()),
  addTranslationHelpers,
  addCurrentLanguage,
  ({t, currentLanguage}) => (
    <p css={styles.subparagraph}>
      {t('welcomePage.updated', {
        initialReleaseMonth: getFormattedMonth({
          month: INITIAL_RELEASE_DATE,
          currentLanguage,
        }),
        lastUpdatedMonth: getFormattedMonth({
          month: LAST_DEPLOY_DATE,
          currentLanguage,
        }),
      })}
    </p>
  )
)

const Welcome = flowMax(
  addPrecaching(precacheAllAssets),
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      background={colors.darkSkyBlue}
      footer={<HillsideFooter hardyCss={styles.hardy} />}
      audioSectionId="welcomePage"
      name="Welcome"
    >
      <div css={styles.contentContainer}>
        <h1 css={styles.header}>
          <AudioHighlight clipKey="header" />
        </h1>
        <p css={styles.understand}>
          <AudioHighlight clipKey="understand" />
        </p>
        <Button
          title={t('buttons.letsBegin')}
          linkTo="steps/1"
          css={styles.button}
          audioClipKey="letsBegin"
        />
        <div css={styles.subparagraphRowContainer}>
          <p css={styles.subparagraph}>
            <AudioHighlight clipKey="supported" />
          </p>
          <ReleasedUpdated />
        </div>
        <img src={cloudLeft} css={styles.cloudLeft} alt="" />
        <img src={cloudRight} css={styles.cloudRight} alt="" />
      </div>
    </Page>
  )
)

export default Welcome

const styles = {
  container: {
    [mq.mobile]: {
      paddingTop: 80,
      marginBottom: -13,
    },
    [mq.tablet]: {
      paddingTop: 136,
      marginBottom: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  contentContainer: {
    [mq.mobile]: {
      marginLeft: 21,
      marginRight: 21,
    },
    [mq.tablet]: {
      marginLeft: 40,
      marginRight: 40,
    },
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 694,
    flexGrow: 1,
    position: 'relative',
  },
  header: {
    [mq.mobile]: {
      fontSize: 48,
      lineHeight: '75px',
    },
    [mq.tablet]: {
      fontSize: 75,
      lineHeight: '105px',
    },
    fontWeight: 700,
    color: '#000',
    marginBottom: 19,
  },
  button: {
    [mq.mobile]: {
      ...mobileButtonResponsiveStyles,
      marginBottom: 48,
    },
    [mq.tablet]: {
      ...normalButtonResponsiveStyles,
      marginBottom: 113,
    },
    color: colors.white,
    fontWeight: 600,
    backgroundColor: colors.blueText,
    alignSelf: 'flex-start',
    paddingLeft: 32,
    paddingRight: 32,
  },
  understand: {
    [mq.mobile]: {
      fontSize: 24,
      lineHeight: '32px',
    },
    [mq.tablet]: {
      fontSize: 26,
      lineHeight: '36px',
    },
    fontWeight: 400,
    marginBottom: 29,
    paddingRight: 24,
  },
  subparagraph: {
    [mq.mobile]: {
      marginBottom: 22,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    [mq.tablet]: {
      marginBottom: 0,
    },
    maxWidth: 400,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  subparagraphRowContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 43,
    },
    display: 'flex',
    zIndex: zIndices.welcomeContent,
  },
  cloudLeft: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.tablet]: {
      display: 'flex',
    },
    position: 'absolute',
    left: -182,
    top: 180,
  },
  cloudRight: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.tablet]: {
      display: 'flex',
    },
    position: 'absolute',
    right: -119,
    top: 296,
  },
  hardy: {
    [mq.tablet]: {
      left: '87.4%',
      bottom: 370,
    },
  },
}
