import React from 'react'
import PropTypes from 'prop-types'
import {addStateHandlers} from 'ad-hok'
import {flow} from 'lodash/fp'

import {classNamePropType} from 'util/propTypes'
import {ReactComponent as PlaceholderImage} from 'assets/images/image-placeholder.svg'
import colors from 'style/colors'

const Image = flow(
  addStateHandlers(
    {isLoaded: false},
    {onLoaded: () => () => ({isLoaded: true})}
  ),
  ({
    aspectRatio,
    isLoaded,
    onLoaded,
    className,
    alt,
    ensureImageStretchesFullyAcrossContainer,
    ...imgProps
  }) => (
    <span
      className={className}
      css={[styles.container, {paddingTop: `${(1 / aspectRatio) * 100}%`}]}
    >
      <PlaceholderImage css={styles.placeholderImage} />
      <img
        alt={alt}
        {...imgProps}
        onLoad={onLoaded}
        css={[
          styles.image,
          ensureImageStretchesFullyAcrossContainer &&
            styles.imageSlightlyStretchedPastParentBounds,
          isLoaded ? styles.imageLoaded : styles.imageNotLoaded,
        ]}
      />
    </span>
  )
)

Image.propTypes = {
  aspectRatio: PropTypes.number.isRequired,
  className: classNamePropType,
  ensureImageStretchesFullyAcrossContainer: PropTypes.bool,
}

export default Image

const styles = {
  container: {
    position: 'relative',
    display: 'block',
    overflow: 'hidden',
  },
  image: {
    transition: 'all 0.3s ease-out',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  imageSlightlyStretchedPastParentBounds: {
    height: '101%',
    top: '-0.5%',
    bottom: '-0.5%',
  },
  imageNotLoaded: {
    opacity: 0,
  },
  imageLoaded: {
    opacity: 1,
  },
  placeholderImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: colors.greyPlaceholderBackground,
    '& g': {
      transformOrigin: '50% 50%',
    },
  },
}
