import PropTypes from 'prop-types'

export const cssPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.array,
])
export const classNamePropType = PropTypes.string
export const childrenPropType = PropTypes.node
export const imageSourcePropType = PropTypes.string
export const refPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.func,
])
export const domNodePropType = PropTypes.object
export const componentPropType = PropTypes.func
