import React from 'react'
import {addStateHandlers, addHandlers, flowMax, addRef} from 'ad-hok'
import {navigate} from '@reach/router'

import Routes from 'Routes'
import Menu from 'components/Menu'
import PatientTitleBar from 'components/PatientTitleBar'
import addEffectOnUnmount from 'util/addEffectOnUnmount'
import {InactivityContext} from 'context/inactivityContext'
import {addTrackingToHandler, addTrackingUserProps} from 'util/tracking'
import addResetApp from 'util/addResetApp'
import SkipLink from 'components/SkipLink'

const INACTIVITY_TIMEOUT_MINUTES = 10

const addInactivityManagement = flowMax(
  addResetApp,
  addRef('inactivityTimeoutIdRef'),
  addHandlers({
    clearInactivityTimeout: ({inactivityTimeoutIdRef}) => () => {
      if (inactivityTimeoutIdRef.current) {
        clearTimeout(inactivityTimeoutIdRef.current)
      }
    },
    startInactivityTimeout: ({inactivityTimeoutIdRef, resetApp}) => () => {
      const timeoutId = setTimeout(() => {
        resetApp()
        navigate('/')
      }, INACTIVITY_TIMEOUT_MINUTES * 60 * 1000)

      // eslint-disable-next-line no-param-reassign
      inactivityTimeoutIdRef.current = timeoutId
    },
  }),
  addEffectOnUnmount(({clearInactivityTimeout}) => {
    clearInactivityTimeout()
  }),
  addHandlers({
    onInactivityReset: ({
      clearInactivityTimeout,
      startInactivityTimeout,
    }) => () => {
      clearInactivityTimeout()
      startInactivityTimeout()
    },
  })
)

const PatientApp = flowMax(
  addTrackingUserProps({App: 'patient'}),
  addStateHandlers(
    {isMenuOpen: false},
    {
      onMenuOpen: () => () => ({isMenuOpen: true}),
      onMenuClose: () => () => ({isMenuOpen: false}),
    }
  ),
  addTrackingToHandler('onMenuOpen', 'Open menu'),
  addInactivityManagement,
  ({isMenuOpen, onMenuOpen, onMenuClose, onInactivityReset}) => (
    <InactivityContext.Provider value={{onInactivityReset}}>
      <SkipLink />
      <PatientTitleBar onMenuOpen={onMenuOpen} />
      <Routes />
      <Menu isOpen={isMenuOpen} onClose={onMenuClose} />
    </InactivityContext.Provider>
  )
)

export default PatientApp
