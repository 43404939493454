import React from 'react'
import hardyThinking from 'assets/images/hardy-thinking.svg'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import Page from 'components/Page'
import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import colors from 'style/colors'

const NotFound = flow(
  addTranslationHelpers,
  ({t, homeUrl = '/'}) => (
    <Page css={styles.container}>
      <img src={hardyThinking} css={styles.image} alt="" />
      <h1 css={styles.header}>{t('notFoundPage.header')}</h1>
      <p css={styles.message}>
        {t('notFoundPage.message')}
        <br />
        <Button mode="link" linkTo={homeUrl}>
          {t('notFoundPage.link')}
        </Button>
      </p>
    </Page>
  )
)

NotFound.propTypes = {
  homeUrl: PropTypes.string,
}

export default NotFound

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.skyBlue,
    paddingTop: 148,
    paddingBottom: 58,
    paddingLeft: 18,
    paddingRight: 18,
  },
  header: {
    fontSize: 48,
    lineHeight: '60px',
    fontWeight: '700',
    marginBottom: 35,
    textAlign: 'center',
  },
  message: {
    fontSize: 26,
    lineHeight: '38px',
    textAlign: 'center',
  },
  image: {
    marginBottom: 24,
  },
}
