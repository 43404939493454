import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import {flow} from 'lodash/fp'
import {addProps} from 'ad-hok'
import {last} from 'lodash'

import leftTreesBehindHillIllustration from 'assets/images/journey-left-trees-behind-hill.svg'
import leftTreesInFrontOfHillIllustration from 'assets/images/journey-left-trees-in-front-of-hill.svg'
import rightTreesBehindHillIllustration from 'assets/images/journey-right-trees-behind-hill.svg'
import hardyIllustration from 'assets/images/journey-hardy.svg'
import hillIllustration from 'assets/images/journey-hill.svg'
import PoseGroup from 'components/PoseGroup'
import {mq} from 'style/mediaQueries'
import zIndices from 'style/zIndices'
import {feachWithKey} from 'util/fp'
import JourneyClouds, {journeyHillHeight} from 'components/JourneyClouds'
import colors from 'style/colors'

const swooshPaths = [
  {
    path: 'M8.69922 60.7756C85.835 32.2561 167.265 23.0006 249.5 25.3489',
    yOffset: -1,
  },
  {path: 'M9 60.7756C165.6 2.87559 339.9 24.3756 502.7 61.1756', yOffset: -1},
  {
    path:
      'M9 60.7756C165.6 2.87559 339.9 24.3756 502.7 61.1756C575.6 77.6756 649.1 97.1756 723.1 105.976',
    yOffset: -1,
  },
  {
    path:
      'M9 82.6C165.6 24.7 339.9 46.2 502.7 83C575.6 99.5 649.1 119 723.1 127.8C733.7 129.1 744.2 130.1 754.8 131C864.5 144.5 976.5 114.5 1072.5 61.8C1081.2 57.3 1089.9 52.5 1098.4 47.6C1106.9 42.7 1115.3 37.7 1123.7 32.5C1132.2 25.7 1138.9 17.4 1141.9 7',
    yOffset: -23,
  },
]

const SwooshPath = posed.path({
  preEnterSwoosh: {pathLength: 0},
  enterSwoosh: {
    pathLength: 100,
    transition: ({current}) => ({
      duration: (1500 * current) / (1 + 0.2 * (current - 1)),
      ease: 'easeOut',
    }),
  },
})

const swooshFadeGradientId = 'swoosh-fade-gradient'
const fullPathFadeGradientId = 'full-path-fade-gradient'

const Swoosh = flow(
  addProps(({current}) => ({swooshPath: swooshPaths[current - 1]})),
  ({current, swooshPath: {path, yOffset = 0}}) => (
    <svg
      width="1000"
      height="160"
      viewBox="0 0 1000 160"
      fill="none"
      css={styles.swooshContainer}
    >
      <defs>
        <linearGradient
          id={swooshFadeGradientId}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#80d2ff" stopOpacity={0} />
          <stop
            offset={`${48 / current}%`}
            stopColor="#80d2ff"
            stopOpacity={100}
          />
        </linearGradient>
        <linearGradient
          id={fullPathFadeGradientId}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor={colors.mutedGreen} stopOpacity={0} />
          <stop
            offset={`${48 / swooshPaths.length}%`}
            stopColor={colors.mutedGreen}
            stopOpacity={100}
          />
        </linearGradient>
      </defs>
      <path
        d={last(swooshPaths).path}
        stroke={`url(#${fullPathFadeGradientId})`}
        strokeWidth="50"
        strokeMiterlimit="10"
        current={current}
        transform={`translate(0 ${last(swooshPaths).yOffset})`}
      />
      <PoseGroup
        animateOnMount
        preEnterPose="preEnterSwoosh"
        enterPose="enterSwoosh"
        exitPose="exitSwoosh"
      >
        <SwooshPath
          d={path}
          stroke={`url(#${swooshFadeGradientId})`}
          strokeWidth="50"
          strokeMiterlimit="10"
          current={current}
          key="swoosh"
          transform={`translate(0 ${yOffset})`}
        />
      </PoseGroup>
    </svg>
  )
)

Swoosh.propTypes = {
  current: PropTypes.number.isRequired,
}

const JourneyHillside = flow(
  addProps(({isMenu}) => ({FooterTag: isMenu ? 'div' : 'footer'})),
  ({current, isMenu, FooterTag}) => (
    <FooterTag css={styles.hillsideOuterContainer} aria-hidden="true">
      <div css={styles.hillsideContainer}>
        <JourneyClouds />
        <div css={styles.hillContainer} />
        <div css={[styles.treesContainer, styles.treesContainerBehindHill]}>
          <div css={styles.treesInnerContainer}>
            <img
              src={leftTreesBehindHillIllustration}
              alt=""
              css={[styles.trees, styles.leftTreesBehindHill]}
            />
            <img
              src={rightTreesBehindHillIllustration}
              alt=""
              css={[styles.trees, styles.rightTreesBehindHill]}
            />
          </div>
        </div>
        <div css={[styles.treesContainer, styles.treesContainerInFrontOfHill]}>
          <div css={styles.treesInnerContainer}>
            <img
              src={leftTreesInFrontOfHillIllustration}
              alt=""
              css={[styles.trees, styles.leftTreesInFrontOfHill]}
            />
            <img
              src={hardyIllustration}
              alt=""
              css={[
                styles.hardy,
                !isMenu && styles[`hardy${current}`],
                isMenu && styles.hardyMenu,
              ]}
            />
            {!isMenu && <Swoosh current={current} />}
          </div>
        </div>
      </div>
    </FooterTag>
  )
)

JourneyHillside.propTypes = {
  current: PropTypes.number.isRequired,
  isMenu: PropTypes.bool,
}

export default JourneyHillside

const hillTileWidth = 1040

const defaultHardyPosition = {
  left: '50%',
  bottom: 400,
}

const styles = {
  hillsideOuterContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    overflow: 'hidden',
  },
  hillsideContainer: mq({
    position: 'absolute',
    bottom: {
      mobile: -383,
      desktop: 0,
    },
    left: 0,
    right: 0,
    top: 0,
  }),
  hillContainer: {
    backgroundImage: `url(${hillIllustration})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center top',
    height: journeyHillHeight,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndices.journeyHill,
  },
  leftTreesBehindHill: {
    position: 'absolute',
    left: 17,
    bottom: 383,
  },
  leftTreesInFrontOfHill: {
    position: 'absolute',
    left: 302,
    bottom: 401,
  },
  rightTreesBehindHill: {
    position: 'absolute',
    left: 750,
    bottom: 338,
  },
  treesContainer: mq({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowX: 'hidden',
  }),
  treesContainerBehindHill: {
    zIndex: zIndices.journeyTreesBehindHill,
  },
  treesContainerInFrontOfHill: {
    zIndex: zIndices.journeyTreesInFrontOfHill,
  },
  treesInnerContainer: {
    width: hillTileWidth,
    position: 'relative',
    left: -39,
  },
  trees: mq({
    display: {
      mobile: 'none',
      desktop: 'block',
    },
  }),
  hardy: {
    position: 'absolute',
    zIndex: zIndices.journeyHardy,
  },
  hardyMenu: defaultHardyPosition,
  swooshContainer: {
    position: 'absolute',
    left: -49,
    bottom: 162,
    zIndex: zIndices.journeySwoosh,
    [mq.mobile]: {
      display: 'none',
    },
    [mq.desktop]: {
      display: 'block',
    },
  },
}

const hardyOffsets = [
  {left: 85, bottom: 422},
  {left: 405, bottom: 413},
  {left: 638, bottom: 385},
  {left: 919, bottom: 378},
]

feachWithKey(({left, bottom}, index) => {
  styles[`hardy${index + 1}`] = {
    [mq.mobile]: defaultHardyPosition,
    [mq.desktop]: {left, bottom},
  }
})(hardyOffsets)
