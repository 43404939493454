import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import BaseButton from 'react-bootstrap/Button'
import {addProps, flowMax} from 'ad-hok'

import colors from 'style/colors'
import shadows from 'style/shadows'
import {childrenPropType} from 'util/propTypes'
import Link from 'components/Link'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import {addTrackingOnClick} from 'util/tracking'

const ButtonWithRef = flowMax(
  addProps(({mode = 'primary'}) => ({mode})),
  addProps(({mode, title, children, responsive, slim}) => ({
    css: [
      mode !== 'plain' && mode !== 'link' && styles.button,
      mode === 'link' && styles.plain,
      styles[mode],
      responsive && {
        [mq.mobile]: mobileButtonResponsiveStyles,
        [mq.tablet]: normalButtonResponsiveStyles,
      },
      slim && styles.slim,
    ],
    content: title || children,
  })),
  addProps(({content, audioClipKey, mode}) => ({
    content: audioClipKey ? (
      <AudioHighlight clipKey={audioClipKey} dark={mode === 'primary'}>
        {content}
      </AudioHighlight>
    ) : (
      content
    ),
  })),
  addTrackingOnClick(({trackAs}) => trackAs),
  ({
    content,
    linkTo,
    historyReplace,
    css,
    disabled,
    responsive,
    slim,
    mode,
    audioClipKey,
    trackAs,
    forwardedRef,
    ...props
  }) =>
    linkTo && !disabled ? (
      <Link
        mode="plain"
        css={css}
        to={linkTo}
        ref={forwardedRef}
        replace={historyReplace}
        {...props}
      >
        {content}
      </Link>
    ) : (
      <BaseButton css={css} {...props} ref={forwardedRef} disabled={disabled}>
        {content}
      </BaseButton>
    )
)

const Button = forwardRef((initialProps, ref) => (
  <ButtonWithRef {...initialProps} forwardedRef={ref} />
))

/* eslint-disable react/no-unused-prop-types */
Button.propTypes = {
  title: PropTypes.string,
  linkTo: PropTypes.string,
  historyReplace: PropTypes.bool,
  children: childrenPropType,
  mode: PropTypes.oneOf([
    'plain',
    'primary',
    'secondary',
    'link',
    'transparent',
  ]),
  disabled: PropTypes.bool,
  responsive: PropTypes.bool,
  slim: PropTypes.bool,
  audioClipKey: PropTypes.string,
  trackAs: PropTypes.string,
}
/* eslint-enable react/no-unused-prop-types */

export default Button

export const mobileButtonResponsiveStyles = {
  minHeight: 56,
  borderRadius: 28,
  fontSize: 24,
  lineHeight: '32px',
  paddingLeft: 28,
  paddingRight: 28,
}

export const normalButtonResponsiveStyles = {
  minHeight: 72,
  borderRadius: 36,
  fontSize: 26,
  lineHeight: '36px',
  paddingLeft: 40,
  paddingRight: 40,
}

export const styles = {
  button: {
    alignItems: 'center',
    boxShadow: shadows.card,
    color: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'center',
    ...normalButtonResponsiveStyles,
  },
  /* eslint-disable styles-object/no-unused-styles */
  plain: {
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
  },
  primary: {
    backgroundColor: colors.blue,
    transition: 'all 0.2s',
    '&:hover, &:active': {
      backgroundColor: colors.darkerBlue,
    },
  },
  secondary: {
    backgroundColor: colors.green,
  },
  link: {
    fontSize: 26,
    lineHeight: '36px',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  transparent: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  /* eslint-enable styles-object/no-unused-styles */
  slim: {
    [mq.mobile]: {
      minHeight: 48,
    },
  },
}
