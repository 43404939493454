import React from 'react'
import hardyThinking from 'assets/images/hardy-thinking.svg'
import {flow} from 'lodash/fp'
import {addHandlers} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import {styles as notFoundStyles} from 'pages/NotFound'

const ErrorPage = flow(
  addHandlers({
    onReload: () => () => {
      window.location.reload()
    },
  }),
  addTranslationHelpers,
  ({t, onReload}) => (
    <section css={[styles.container, notFoundStyles.container]}>
      <img src={hardyThinking} css={notFoundStyles.image} alt="" />
      <h1 css={notFoundStyles.header}>{t('errorPage.header')}</h1>
      <p css={notFoundStyles.message}>
        {t('errorPage.message')}
        <br />
        <Button mode="link" onClick={onReload}>
          {t('errorPage.link')}
        </Button>
      </p>
    </section>
  )
)

export default ErrorPage

const styles = {
  container: {
    minHeight: '100vh',
  },
}
