import React from 'react'
import PropTypes from 'prop-types'

import Page from 'components/Page'
import Video from 'components/Video'
import ClinicianNav from 'components/ClinicianNav'
import {mq} from 'style/mediaQueries'
import {imageSourcePropType} from 'util/propTypes'

const ClinicianVideoPage = ({
  videoIdKey,
  title,
  duration,
  pageKey,
  fallbackCacheKey,
  fallbackCacheCaptionsUrl,
  fallbackCacheThumbnail,
  name,
}) => (
  <Page
    css={styles.container}
    footer={<ClinicianNav currentPage={pageKey} />}
    name={name}
  >
    <Video
      idKey={videoIdKey}
      title={title}
      duration={duration}
      fallbackCacheKey={fallbackCacheKey}
      fallbackCacheCaptionsUrl={fallbackCacheCaptionsUrl}
      fallbackCacheThumbnail={fallbackCacheThumbnail}
    />
  </Page>
)

ClinicianVideoPage.propTypes = {
  videoIdKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  pageKey: PropTypes.string.isRequired,
  fallbackCacheKey: PropTypes.string,
  fallbackCacheCaptionsUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  fallbackCacheThumbnail: imageSourcePropType,
  name: PropTypes.string.isRequired,
}

export default ClinicianVideoPage

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    [mq.mobile]: {
      paddingLeft: 21,
      paddingRight: 21,
      paddingTop: 76,
    },
    [mq.tablet]: {
      paddingLeft: 81,
      paddingRight: 82,
      paddingTop: 100,
    },
  },
}
