import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import {addTranslationHelpers} from 'util/i18n'
import BackButton from 'components/BackButton'
import {questions} from 'pages/FaqQuestions'
import doubleLeftCaretWhiteIcon from 'assets/images/double-left-caret-white-circle.svg'
import leftCaretWhiteIcon from 'assets/images/left-caret-white-circle.svg'
import leftCaretWhiteDisabledIcon from 'assets/images/left-caret-white-circle-disabled.svg'
import rightCaretWhiteIcon from 'assets/images/right-caret-white-circle.svg'
import {height as titleBarHeight} from 'components/TitleBar'
import colors from 'style/colors'
import zIndices from 'style/zIndices'
import shadows from 'style/shadows'
import {mq, BreakpointSwitch} from 'style/mediaQueries'
import {maxWidth} from 'style/layout'

const getPreviousQuestionUrl = ({current}) =>
  `/common-questions/${((current + questions.length - 2) % questions.length) +
    1}`

export const getNextQuestionUrl = ({current, isLastQuestion}) =>
  isLastQuestion
    ? '/steps/3'
    : `/common-questions/${(current % questions.length) + 1}`

const AllQuestionsLink = flow(
  addTranslationHelpers,
  ({number, t}) => (
    <BackButton
      css={styles.allQuestionsButtonContainer}
      data-testid={`faq-question-all-questions-${number}`}
    >
      <img
        css={styles.allQuestionsButtonIcon}
        src={doubleLeftCaretWhiteIcon}
        alt=""
      />
      <span css={styles.allQuestionsButtonLabel}>
        {t('faqQuestionPage.allQuestionsButton')}
      </span>
    </BackButton>
  )
)

AllQuestionsLink.propTypes = {
  number: PropTypes.number.isRequired,
}

const CurrentQuestionNumber = flow(
  addTranslationHelpers,
  ({number, t}) => (
    <span css={styles.currentQuestionNumber}>
      {t('faqQuestionPage.currentQuestionNumber', {
        current: number,
        total: questions.length,
      })}
    </span>
  )
)

CurrentQuestionNumber.propTypes = {
  number: PropTypes.number.isRequired,
}

const PreviousQuestionLink = flow(
  addTranslationHelpers,
  ({number, isFirstQuestion, t}) => (
    <Button
      mode="plain"
      css={[
        styles.previousNextQuestionButtonContainer,
        styles.previousQuestionButtonContainer,
      ]}
      linkTo={getPreviousQuestionUrl({current: number})}
      historyReplace
      data-testid={`faq-question-previous-${number}`}
      disabled={isFirstQuestion}
    >
      <img
        css={styles.previousNextQuestionIcon}
        src={isFirstQuestion ? leftCaretWhiteDisabledIcon : leftCaretWhiteIcon}
        alt={t('faqQuestionPage.previousQuestion')}
      />
    </Button>
  )
)

PreviousQuestionLink.propTypes = {
  number: PropTypes.number.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
}

const NextQuestionLink = flow(
  addTranslationHelpers,
  ({number, isLastQuestion, t}) => (
    <Button
      mode="plain"
      css={styles.previousNextQuestionButtonContainer}
      linkTo={getNextQuestionUrl({current: number, isLastQuestion})}
      historyReplace={!isLastQuestion}
      data-testid={`faq-question-next-${number}`}
    >
      <img
        css={styles.previousNextQuestionIcon}
        src={rightCaretWhiteIcon}
        alt={t(
          isLastQuestion
            ? 'buttons.continueJourney'
            : 'faqQuestionPage.nextQuestion'
        )}
      />
    </Button>
  )
)

NextQuestionLink.propTypes = {
  number: PropTypes.number.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
}

const FaqNavHeader = flow(
  addTranslationHelpers,
  ({number, isFirstQuestion, isLastQuestion}) => (
    <div css={styles.container}>
      <div css={styles.maxWidthContainer}>
        <BreakpointSwitch
          mobile={
            <>
              <div css={styles.rowContainer}>
                <AllQuestionsLink number={number} />
              </div>
              <div css={styles.rowContainer}>
                <PreviousQuestionLink
                  number={number}
                  isFirstQuestion={isFirstQuestion}
                />
                <CurrentQuestionNumber number={number} />
                <NextQuestionLink
                  number={number}
                  isLastQuestion={isLastQuestion}
                />
              </div>
            </>
          }
          tablet={
            <>
              <AllQuestionsLink number={number} />
              <CurrentQuestionNumber number={number} />
              <PreviousQuestionLink
                number={number}
                isFirstQuestion={isFirstQuestion}
              />
              <NextQuestionLink
                number={number}
                isLastQuestion={isLastQuestion}
              />
            </>
          }
        />
      </div>
    </div>
  )
)

FaqNavHeader.propTypes = {
  number: PropTypes.number.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
}

export default FaqNavHeader

const styles = {
  currentQuestionNumber: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 600,
    fontStyle: 'italic',
  },
  container: {
    position: 'fixed',
    top: titleBarHeight,
    left: 0,
    right: 0,
    backgroundColor: colors.darkerBlueBackground,
    zIndex: zIndices.faqNavHeader,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [mq.tablet]: {
      paddingLeft: 30,
      paddingRight: 28,
    },
  },
  maxWidthContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.tablet]: {
      height: 72,
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 0,
      paddingTop: 4,
    },
    display: 'flex',
    width: maxWidth,
    maxWidth: '100%',
  },
  allQuestionsButtonIcon: {
    [mq.mobile]: {
      width: 44,
    },
    [mq.tablet]: {
      width: 'auto',
    },
    borderRadius: 25,
    boxShadow: shadows.card,
  },
  previousNextQuestionIcon: {
    [mq.mobile]: {
      width: 44,
    },
    [mq.tablet]: {
      width: 'auto',
    },
    borderRadius: 25,
    boxShadow: shadows.card,
  },
  previousNextQuestionButtonContainer: {
    [mq.mobile]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    [mq.tablet]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  allQuestionsButtonContainer: {
    [mq.mobile]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    [mq.tablet]: {
      paddingTop: 10,
      paddingBottom: 10,
      marginRight: 77,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  allQuestionsButtonLabel: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
    color: colors.blue,
    marginLeft: 10,
  },
  previousQuestionButtonContainer: {
    [mq.tablet]: {
      marginLeft: 'auto',
      marginRight: 22,
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 58,
    paddingLeft: 21,
    paddingRight: 21,
    borderBottomWidth: 1,
    borderBottomColor: colors.paleBlueBackground,
    borderBottomStyle: 'solid',
    '&:last-child': {
      borderBottomStyle: 'none',
    },
  },
}
