import {createPortal} from 'react-dom'
import {flowMax, branch, renderNothing} from 'ad-hok'

import {childrenPropType, domNodePropType} from 'util/propTypes'

const Portal = flowMax(
  branch(({to}) => !to, renderNothing()),
  ({to, children}) => createPortal(children, to)
)

Portal.propTypes = {
  to: domNodePropType,
  children: childrenPropType.isRequired,
}

export default Portal
