import React from 'react'
import PropTypes from 'prop-types'
import {addProps, flowMax} from 'ad-hok'
import posed, {PoseGroup} from 'react-pose'
import {upperFirst} from 'lodash'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import Button from 'components/Button'
import patientAvatar1 from 'assets/images/patient-avatar-1.svg'
import patientAvatar2 from 'assets/images/patient-avatar-2.svg'
import patientAvatar3 from 'assets/images/patient-avatar-3.svg'
import patientAvatar4 from 'assets/images/patient-avatar-4.svg'
import colors from 'style/colors'
import {questions} from 'pages/FaqQuestions'
import SpeechBubble from 'components/SpeechBubble'
import FaqAnswer, {
  styles as answerStyles,
  QuestionOrAnswer,
} from 'components/FaqAnswer'
import FaqNavHeader, {getNextQuestionUrl} from 'components/FaqNavHeader'
import {mq, addResponsiveBreakpointProps} from 'style/mediaQueries'
import {addTrackingProps} from 'util/tracking'

const patientAvatars = [
  patientAvatar1,
  patientAvatar2,
  patientAvatar3,
  patientAvatar4,
]
const getPatientAvatar = ({questionNumber}) =>
  patientAvatars[questionNumber % patientAvatars.length]

const QuestionAndAnswerContainer = posed.div({
  enter: {staggerChildren: 300},
})

const QuestionAndAnswer = flowMax(
  addTranslationHelpers,
  addProps(({question: {key, answers}, t}) => ({
    question: t(`faqQuestions.${key}.question`),
    answers,
    answersKeyPrefix: `faqQuestions.${key}.answers`,
  })),
  addResponsiveBreakpointProps('tablet'),
  addProps(({number}) => ({questionNumber: number})),
  addProps(({questionNumber}) => ({
    patientAvatar: getPatientAvatar({questionNumber}),
  })),
  ({
    question,
    answers,
    answersKeyPrefix,
    isBelowTablet,
    questionNumber,
    patientAvatar,
  }) => (
    <PoseGroup preEnterPose="preEnter" animateOnMount>
      <QuestionAndAnswerContainer
        css={styles.questionAndAnswerContainer}
        key={isBelowTablet ? 'mobile' : 'tablet'}
      >
        <QuestionOrAnswer css={answerStyles.questionAndAnswerRowContainer}>
          <div css={answerStyles.avatarContainer} />
          <SpeechBubble
            corner={{mobile: 'rightBottom', tablet: 'bottomRight'}}
            text={question}
            audioClipKey="question"
            backgroundColor={colors.white}
            css={[
              answerStyles.speechBubbleContainer,
              styles.questionSpeechBubbleContainer,
            ]}
            textCss={[
              answerStyles.speechBubbleText,
              styles.questionSpeechBubbleText,
            ]}
            offset={{mobile: 74, tablet: 0}}
            TextTag="h1"
          />
          <div css={answerStyles.avatarContainer}>
            <img src={patientAvatar} alt="" css={answerStyles.avatar} />
          </div>
        </QuestionOrAnswer>
        {answers.map((answer, index) => (
          <FaqAnswer
            answer={answer}
            keyPrefix={answersKeyPrefix}
            questionNumber={questionNumber}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </QuestionAndAnswerContainer>
    </PoseGroup>
  )
)

QuestionAndAnswer.propTypes = {
  number: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
}

const FaqQuestion = flowMax(
  addProps(({number}) => ({number: Number(number)})),
  addTrackingProps(({number}) => ({
    'FAQ question number': number,
  })),
  addProps(({number}) => ({
    question: questions[number - 1],
    isFirstQuestion: number === 1,
    isLastQuestion: number === questions.length,
  })),
  addTranslationHelpers,
  addResponsiveBreakpointProps('tablet'),
  ({number, t, isFirstQuestion, isLastQuestion, question, isBelowTablet}) => (
    <Page
      css={styles.container}
      background={colors.paleBlueBackground}
      audioSectionId={`faqQuestionPage${upperFirst(question.key)}`}
      audioSectionHighlightScrollOffset={isBelowTablet ? 116 : 72}
      name="FAQ question"
    >
      <FaqNavHeader
        number={number}
        isFirstQuestion={isFirstQuestion}
        isLastQuestion={isLastQuestion}
      />
      <QuestionAndAnswer number={number} question={question} />
      <div css={styles.questionNavigationRowContainer}>
        <Button
          responsive
          linkTo={
            isLastQuestion ? '/steps/3' : getNextQuestionUrl({current: number})
          }
          historyReplace={!isLastQuestion}
          data-testid={`faq-question-next-question-${number}`}
          audioClipKey={isLastQuestion ? 'done' : null}
        >
          {isLastQuestion
            ? t('buttons.continueJourney')
            : t('faqQuestionPage.nextQuestion')}
        </Button>
      </div>
    </Page>
  )
)

FaqQuestion.propTypes = {
  number: PropTypes.string,
}

export default FaqQuestion

const styles = {
  container: {
    [mq.mobile]: {
      paddingTop: 200,
      paddingLeft: 8,
      paddingRight: 8,
    },
    [mq.tablet]: {
      paddingTop: 158,
      paddingLeft: 42,
      paddingRight: 42,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 44,
  },
  questionNavigationRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 36,
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  questionSpeechBubbleContainer: {
    [mq.mobile]: {
      paddingLeft: 18,
      paddingRight: 12,
      paddingBottom: 15,
    },
    [mq.tablet]: {
      paddingLeft: 26,
      paddingRight: 24,
      paddingBottom: 21,
    },
    paddingTop: 17,
  },
  questionSpeechBubbleText: {
    fontWeight: 700,
  },
  questionAndAnswerContainer: {
    alignSelf: 'center',
  },
}
