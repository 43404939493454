import React from 'react'
import Video from 'components/Video'
import PropTypes from 'prop-types'
import {addTrackingToHandler, addTrackingEventDebouncing} from 'util/tracking'
import {get as fget, flow} from 'lodash/fp'
import {a11yStyles} from 'util/a11y'
import {flowMax, addStateHandlers, addEffect, addRef, addHandlers} from 'ad-hok'
import {each} from 'lodash'

import addCallbackRefAndNode from 'util/addCallbackRefAndNode'
import Portal from 'components/Portal'
import Lightbox from 'components/Lightbox'
import {imageSourcePropType} from 'util/propTypes'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import LightboxCloseButton from 'components/LightboxCloseButton'
import {invoke} from 'util/fp'

const addVideoUnmountingOnModalClose = flow(
  addStateHandlers(
    {hasModalBeenClosed: false},
    {
      onModalClose: () => () => ({
        hasModalBeenClosed: true,
      }),
    }
  ),
  addEffectOnPropChange(
    ['showingModal'],
    ({showingModal, onModalClose}, prevProps) => {
      if (!showingModal && prevProps.showingModal) {
        onModalClose()
      }
    }
  )
)

const getVideoPortalTarget = ({
  showingModal,
  lightboxPortalTargetNode,
  hasModalBeenClosed,
  visuallyHiddenVideoContainerNode,
}) => {
  if (showingModal) return lightboxPortalTargetNode
  if (hasModalBeenClosed) return null
  return visuallyHiddenVideoContainerNode
}

const FOCUSABLE =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), video'
const addKeyboardNavigationManagement = flow(
  addRef('focusableElements', {elements: [], tabIndices: []}),
  addHandlers({
    hideFocusableElements: ({focusableElements}) => ({parentElement}) => {
      const elements = parentElement.querySelectorAll(FOCUSABLE)
      const {tabIndices} = focusableElements.current
      each(elements, (element, index) => {
        tabIndices[index] = element.getAttribute('tabindex')
        element.setAttribute('tabindex', '-1')
      })
      focusableElements.current.elements = elements
    },
  }),
  addRef('hasHiddenRef', false),
  addHandlers({
    onReady: ({
      hideFocusableElements,
      visuallyHiddenVideoContainerNode,
    }) => () => {
      hideFocusableElements({parentElement: visuallyHiddenVideoContainerNode})
    },
  }),
  addEffect(
    ({
      showingModal,
      focusableElements: {
        current: {elements, tabIndices},
      },
    }) => () => {
      if (!showingModal) return
      each(elements, (element, index) => {
        invoke(
          flow(
            () => tabIndices[index],
            originalTabIndex => {
              if (originalTabIndex != null) {
                element.setAttribute('tabindex', originalTabIndex)
              } else {
                element.removeAttribute('tabindex')
              }
            }
          )
        )
      })
    },
    ['showingModal']
  )
)

const VideoLightbox = flowMax(
  addCallbackRefAndNode(
    'lightboxPortalTargetCallbackRef',
    'lightboxPortalTargetNode'
  ),
  addCallbackRefAndNode(
    'visuallyHiddenVideoContainerCallbackRef',
    'visuallyHiddenVideoContainerNode'
  ),
  addVideoUnmountingOnModalClose,
  addTrackingEventDebouncing(300),
  addTrackingToHandler('onEnded', fget('videoEndedTrackingEvent')),
  addTrackingToHandler('onStart', fget('videoStartedTrackingEvent')),
  addKeyboardNavigationManagement,
  ({
    showingModal,
    setShowingModal,
    videoIdKey,
    videoTestId,
    videoFallbackCacheKey,
    videoFallbackCacheCaptionsUrl,
    videoFallbackCacheThumbnail,
    closeButtonTestId,
    lightboxPortalTargetCallbackRef,
    lightboxPortalTargetNode,
    visuallyHiddenVideoContainerCallbackRef,
    visuallyHiddenVideoContainerNode,
    hasModalBeenClosed,
    onEnded,
    onStart,
    onReady,
    audioSectionId,
    hasSpanishVersion,
  }) => (
    <>
      <div
        css={a11yStyles.visuallyHidden}
        aria-hidden="true"
        ref={visuallyHiddenVideoContainerCallbackRef}
      />
      <Portal
        to={getVideoPortalTarget({
          showingModal,
          lightboxPortalTargetNode,
          hasModalBeenClosed,
          visuallyHiddenVideoContainerNode,
        })}
      >
        <Video
          idKey={videoIdKey}
          containerCss={styles.videoContainer}
          data-testid={videoTestId}
          fallbackCacheKey={videoFallbackCacheKey}
          fallbackCacheCaptionsUrl={videoFallbackCacheCaptionsUrl}
          fallbackCacheThumbnail={videoFallbackCacheThumbnail}
          onEnded={onEnded}
          onStart={onStart}
          onReady={onReady}
          hasSpanishVersion={hasSpanishVersion}
        />
        <LightboxCloseButton
          onClick={() => setShowingModal(false)}
          data-testid={closeButtonTestId}
        />
      </Portal>
      <Lightbox
        show={showingModal}
        onHide={() => setShowingModal(false)}
        bodyCss={styles.videoModalBody}
        showCloseButton={false}
        audioSectionId={audioSectionId}
        childPortalTargetRef={lightboxPortalTargetCallbackRef}
      />
    </>
  )
)

VideoLightbox.propTypes = {
  showingModal: PropTypes.bool.isRequired,
  setShowingModal: PropTypes.func.isRequired,
  videoIdKey: PropTypes.string.isRequired,
  videoTestId: PropTypes.string,
  videoFallbackCacheKey: PropTypes.string,
  videoFallbackCacheCaptionsUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  videoFallbackCacheThumbnail: imageSourcePropType,
  closeButtonTestId: PropTypes.string,
  audioSectionId: PropTypes.string.isRequired,
  hasSpanishVersion: PropTypes.bool,
}

export default VideoLightbox

const styles = {
  videoContainer: {
    width: '100%',
  },
  videoModalBody: {
    paddingTop: 20,
    paddingLeft: 18,
    paddingRight: 18,
    paddingBottom: 15,
  },
}
