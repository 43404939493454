import {connect} from 'react-redux'

import {
  playAudioSection,
  restorePreviousAudioSection,
} from 'redux-local/actions'

const withAudioModeSectionRestoration = connect(
  null,
  (dispatch, {audioSectionId}) => ({
    onAudioModeStart: () => {
      dispatch(playAudioSection(audioSectionId))
    },
    onAudioModeRestore: () => {
      dispatch(restorePreviousAudioSection())
    },
  })
)

export default withAudioModeSectionRestoration
