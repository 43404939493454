import React from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'
import {addProps, addHandlers} from 'ad-hok'

import radioButtonUnselected from 'assets/images/radio-button-unselected.svg'
import radioButtonSelected from 'assets/images/radio-button-selected-blue.svg'
import colors from 'style/colors'
import shadows from 'style/shadows'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import {a11yStyles} from 'util/a11y'

const getRadioButtonSource = ({isSelected, isClicking}) => {
  if (isSelected) return radioButtonSelected
  if (isClicking) return radioButtonSelected
  return radioButtonUnselected
}

const QuizAnswer = flow(
  addProps(({index}) => ({id: `quiz-answer-${index}`})),
  addHandlers({
    onChange: ({onClick}) => event => {
      onClick(event.target)
    },
  }),
  ({
    isCorrect,
    isSelected,
    disabled,
    isClicking,
    text,
    onChange,
    onClick,
    onMouseDown,
    // onMouseEnter,
    index,
    id,
    audioClipKey,
    setInputRef,
  }) => (
    <>
      <input
        type="radio"
        name="quiz-answer"
        id={id}
        css={styles.radioInput}
        onChange={onChange}
        disabled={disabled}
        ref={setInputRef}
      />
      <label
        htmlFor={id}
        onClick={onClick}
        onMouseDown={onMouseDown}
        css={[
          styles.container,
          isCorrect && isSelected && styles.containerCorrect,
          !isCorrect && isSelected && styles.containerIncorrect,
          disabled && styles.containerDisabled,
          (isSelected || isClicking) && styles.containerChosen,
        ]}
        disabled={disabled}
      >
        <img
          src={getRadioButtonSource({isCorrect, isSelected, isClicking})}
          alt=""
          css={styles.radioButtonImage}
        />
        <span css={styles.textContainer}>
          <AudioHighlight clipKey={audioClipKey}>
            <span css={styles.abcLabel}>{'abc'[index]}. </span>
            <span>{text}</span>
          </AudioHighlight>
        </span>
      </label>
    </>
  )
)

QuizAnswer.propTypes = {
  isCorrect: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isClicking: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  audioClipKey: PropTypes.string.isRequired,
  setInputRef: PropTypes.func.isRequired,
}

export default QuizAnswer

const styles = {
  container: {
    width: 522,
    maxWidth: '100%',
    minHeight: 143,
    [mq.mobile]: {
      paddingTop: 18,
      paddingBottom: 18,
      paddingLeft: 26,
    },
    [mq.desktop]: {
      paddingTop: 10,
      paddingBottom: 7,
      paddingLeft: 37,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: 22,
    borderRadius: 10,
    backgroundColor: colors.white,
    marginBottom: 16,
    borderWidth: 2,
    borderColor: 'transparent',
    borderStyle: 'solid',
    boxShadow: shadows.card,
    cursor: 'pointer',
  },
  containerCorrect: {
    backgroundColor: colors.greenBackground,
    borderColor: colors.darkerGreen,
  },
  containerIncorrect: {
    backgroundColor: colors.greyBackground,
    borderColor: colors.greyOutline,
  },
  containerDisabled: {
    cursor: 'default',
  },
  textContainer: {
    fontSize: 26,
    lineHeight: '35px',
    fontWeight: 400,
    color: colors.black,
    marginLeft: 24,
    textAlign: 'left',
    display: 'block',
  },
  abcLabel: {
    fontWeight: 600,
  },
  radioInput: {
    ...a11yStyles.visuallyHidden,
    '&:focus + label img': a11yStyles.defaultFocus,
  },
  radioButtonImage: {
    borderRadius: '50%',
  },
  containerChosen: {},
}
