import {css} from '@emotion/core'

// eslint-disable-next-line import/prefer-default-export
export const a11yStyles = {
  visuallyHidden: {
    position: 'absolute',
    top: -99999,
    height: 1,
    width: 1,
  },
  notVisuallyHidden: {
    position: 'static',
    top: 0,
    height: 'auto',
    width: 'auto',
  },
  defaultFocus: css`
    outline: rgb(59, 153, 252) auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  `,
}
