import React from 'react'
import {flow} from 'lodash/fp'

import ClinicianVideoPage from 'components/ClinicianVideoPage'
import {addTranslationHelpers} from 'util/i18n'
// eslint-disable-next-line known-imports/no-unused-vars
import rhythmVideo from 'assets/videos/clinician-rhythm-1080p.mp4'
import rhythmVideoThumbnail from 'assets/images/clinician-rhythm-video-thumbnail.png'

const ClinicianRhythm = flow(
  addTranslationHelpers,
  ({t}) => (
    <ClinicianVideoPage
      videoIdKey="clinicianRhythm"
      title={t('clinicianRhythmPage.videoTitle')}
      duration={20}
      pageKey="rhythm"
      fallbackCacheKey="rhythmVideo"
      fallbackCacheThumbnail={rhythmVideoThumbnail}
      name="Video: normal rhythm vs. AFib"
    />
  )
)

export default ClinicianRhythm
