import {find} from 'lodash'
import {flowMax} from 'ad-hok'

import toast from 'util/toast'
import {addQueryParams} from 'util/queryString'
import addCssHelper from 'util/addCssHelper'
import addEffectOnMount from 'util/addEffectOnMount'
import {isClinicalSetting} from 'util/environment'
import {addTranslationHelpers} from 'util/i18n'

const postMessageToServiceWorker = message => {
  if (!('serviceWorker' in navigator)) return null

  // const workbox = new Workbox('/service-worker.js')

  // workbox.addEventListener('activated', () => {
  //   workbox.messageSW({type: 'PRECACHE'})
  // })
  return navigator.serviceWorker.ready.then(
    registration =>
      new Promise((resolve, reject) => {
        const messageChannel = new MessageChannel()
        messageChannel.port1.onmessage = event => {
          if (event.data.error) {
            reject(event.data.error)
          } else {
            resolve(event.data)
          }
        }

        registration.active.postMessage(message, [messageChannel.port2])
      })
  )
}

export const precacheAllAssets = () =>
  postMessageToServiceWorker({type: 'PRECACHE'})

export const precacheAllClinicianAssets = () =>
  postMessageToServiceWorker({type: 'PRECACHE_CLINICIAN'})

const precacheAndToast = ({precache, cssHelper, t}) =>
  precache().then(() => {
    toast({cssHelper})(t('offline.donePrecaching'))
  })

export const addPrecaching = precache =>
  flowMax(
    addQueryParams(['precache']),
    addCssHelper(),
    addTranslationHelpers,
    addEffectOnMount(({precache: precacheQueryString, cssHelper, t}) => () => {
      if (isClinicalSetting() || precacheQueryString) {
        precacheAndToast({precache, cssHelper, t})
      }
    })
  )

export const getMatchingCachedUrl = async ({urlPattern}) => {
  if (!window.caches) return null

  const cachesKeys = await window.caches.keys()
  const matches = await Promise.all(
    cachesKeys.map(async cachesKey => {
      const cache = await window.caches.open(cachesKey)
      const cacheKeys = await cache.keys()
      const match = find(cacheKeys, ({url}) => urlPattern.test(url))
      if (match) return match.url
      return null
    })
  )
  return find(matches, x => x)
}
