import welcomeHeaderAudio from 'assets/audio/welcomePage/header.mp3'
import welcomeUnderstandAudio from 'assets/audio/welcomePage/understand.mp3'
import welcomeSupportedAudio from 'assets/audio/welcomePage/supported.mp3'
import welcomeHeaderAudioSpanish from 'assets/audio/spanish/welcomePage/header.mp3'
import welcomeUnderstandAudioSpanish from 'assets/audio/spanish/welcomePage/understand.mp3'
import welcomeSupportedAudioSpanish from 'assets/audio/spanish/welcomePage/supported.mp3'
import introVideoVideoTitleAudio from 'assets/audio/introVideoPage/videoTitle.mp3'
import introVideoPlayAudio from 'assets/audio/introVideoPage/play.mp3'
import introVideoVideoTitleAudioSpanish from 'assets/audio/spanish/introVideoPage/videoTitle.mp3'
import introReviewHeaderAudio from 'assets/audio/introReviewPage/header.mp3'
import introReviewHeaderAudioSpanish from 'assets/audio/spanish/introReviewPage/header.mp3'
import buttonsNextAudio from 'assets/audio/buttons/next.mp3'
import buttonsDoneAudio from 'assets/audio/buttons/done.mp3'
import buttonsContinueAudio from 'assets/audio/buttons/continue.mp3'
import buttonsStartAudio from 'assets/audio/buttons/start.mp3'
import buttonsLetsBeginPressTheButtonAudio from 'assets/audio/welcomePage/button.mp3'
import buttonsLetsBeginAudio from 'assets/audio/buttons/letsBegin.mp3'
import buttonsStartAudioSpanish from 'assets/audio/spanish/buttons/start.mp3'
import buttonsNextAudioSpanish from 'assets/audio/spanish/buttons/next.mp3'
import buttonsDoneAudioSpanish from 'assets/audio/spanish/buttons/done.mp3'
import buttonsContinueAudioSpanish from 'assets/audio/spanish/buttons/continue.mp3'
import buttonsLetsBeginAudioSpanish from 'assets/audio/spanish/welcomePage/button.mp3'
import introKeyMessagesStrokeAudio from 'assets/audio/introKeyMessagesPage/stroke.mp3'
import introKeyMessagesBloodThinnerAudio from 'assets/audio/introKeyMessagesPage/bloodThinner.mp3'
import introKeyMessagesBenefitsAudio from 'assets/audio/introKeyMessagesPage/benefits.mp3'
import introKeyMessagesChoiceAudio from 'assets/audio/introKeyMessagesPage/choice.mp3'
import introKeyMessagesStrokeAudioSpanish from 'assets/audio/spanish/introKeyMessagesPage/stroke.mp3'
import introKeyMessagesBloodThinnerAudioSpanish from 'assets/audio/spanish/introKeyMessagesPage/bloodThinner.mp3'
import introKeyMessagesBenefitsAudioSpanish from 'assets/audio/spanish/introKeyMessagesPage/benefits.mp3'
import introKeyMessagesChoiceAudioSpanish from 'assets/audio/spanish/introKeyMessagesPage/choice.mp3'
import quizIntroHeaderAudio from 'assets/audio/quizIntroPage/header.mp3'
import quizIntroQuestionAudio from 'assets/audio/quizIntroPage/question.mp3'
import quizIntroHeaderAudioSpanish from 'assets/audio/spanish/quizIntroPage/header.mp3'
import quizIntroQuestionAudioSpanish from 'assets/audio/spanish/quizIntroPage/question.mp3'
// import quizPickOneAudio from 'assets/audio/quizQuestionsPage/pickOne.mp3'
import quizWhyDangerousAudio from 'assets/audio/quizQuestionsPage/whyDangerous.mp3'
import quizCanLeadToHeartAttackAudio from 'assets/audio/quizQuestionsPage/canLeadToHeartAttack.mp3'
import quizCanLeadToStrokeAudio from 'assets/audio/quizQuestionsPage/canLeadToStroke.mp3'
import quizInfectiousAudio from 'assets/audio/quizQuestionsPage/infectious.mp3'
import quizHeartConditionAudio from 'assets/audio/quizQuestionsPage/heartCondition.mp3'
import quizBloodThinnerAudio from 'assets/audio/quizQuestionsPage/bloodThinner.mp3'
import quizMakesThickAudio from 'assets/audio/quizQuestionsPage/makesThick.mp3'
import quizCuresAudio from 'assets/audio/quizQuestionsPage/cures.mp3'
import quizProtectsAudio from 'assets/audio/quizQuestionsPage/protects.mp3'
import quizLowerRiskAudio from 'assets/audio/quizQuestionsPage/lowerRisk.mp3'
import quizChangeMindAudio from 'assets/audio/quizQuestionsPage/changeMind.mp3'
import quizNoAudio from 'assets/audio/quizQuestionsPage/no.mp3'
import quizYesAudio from 'assets/audio/quizQuestionsPage/yes.mp3'
import quizYourChoiceAudio from 'assets/audio/quizQuestionsPage/yourChoice.mp3'
import quizTryAgainAudio from 'assets/audio/quizQuestionsPage/tryAgain.mp3'
import quizCorrectAudio from 'assets/audio/quizQuestionsPage/correct.mp3'
import quizCorrectCanLeadToStrokeAudio from 'assets/audio/quizQuestionsPage/correctCanLeadToStroke.mp3'
import quizCorrectProtectsAudio from 'assets/audio/quizQuestionsPage/correctProtects.mp3'
import quizCorrectYesAudio from 'assets/audio/quizQuestionsPage/correctYes.mp3'
import quizWhyDangerousAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/whyDangerous.mp3'
import quizCanLeadToHeartAttackAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/canLeadToHeartAttack.mp3'
import quizCanLeadToStrokeAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/canLeadToStroke.mp3'
import quizInfectiousAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/infectious.mp3'
import quizHeartConditionAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/heartCondition.mp3'
import quizBloodThinnerAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/bloodThinner.mp3'
import quizMakesThickAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/makesThick.mp3'
import quizCuresAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/cures.mp3'
import quizProtectsAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/protects.mp3'
import quizLowerRiskAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/lowerRisk.mp3'
import quizChangeMindAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/changeMind.mp3'
import quizNoAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/no.mp3'
import quizYesAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/yes.mp3'
import quizYourChoiceAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/yourChoice.mp3'
import quizTryAgainAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/tryAgain.mp3'
import quizCorrectAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/correct.mp3'
import quizCorrectCanLeadToStrokeAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/correctCanLeadToStroke.mp3'
import quizCorrectProtectsAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/correctProtects.mp3'
import quizCorrectYesAudioSpanish from 'assets/audio/spanish/quizQuestionsPage/correctYes.mp3'
import faqIntroHeaderAudio from 'assets/audio/faqIntroPage/header.mp3'
import faqIntroQuestionAudio from 'assets/audio/faqIntroPage/question.mp3'
import faqIntroHeaderAudioSpanish from 'assets/audio/spanish/faqIntroPage/header.mp3'
import faqIntroQuestionAudioSpanish from 'assets/audio/spanish/faqIntroPage/question.mp3'
import faqBalanceQuestionAudio from 'assets/audio/faqQuestions/balanceQuestion.mp3'
import faqBalanceQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/balanceQuestion.mp3'
import faqCanReverseQuestionAudio from 'assets/audio/faqQuestions/canReverseQuestion.mp3'
import faqCanReverseBadInjuryAudio from 'assets/audio/faqQuestions/canReverseBadInjury.mp3'
import faqCanReverseQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/canReverseQuestion.mp3'
import faqCanReverseBadInjuryAudioSpanish from 'assets/audio/spanish/faqQuestions/canReverseBadInjury.mp3'
import faqCostQuestionAudio from 'assets/audio/faqQuestions/costQuestion.mp3'
import faqCostPriceAudio from 'assets/audio/faqQuestions/costPrice.mp3'
import faqCostInGeneralAudio from 'assets/audio/faqQuestions/costInGeneral.mp3'
import faqCostMonitoringAudio from 'assets/audio/faqQuestions/costMonitoring.mp3'
import faqCostQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/costQuestion.mp3'
import faqCostPriceAudioSpanish from 'assets/audio/spanish/faqQuestions/costPrice.mp3'
import faqCostMonitoringAudioSpanish from 'assets/audio/spanish/faqQuestions/costMonitoring.mp3'
import faqCostInGeneralAudioSpanish from 'assets/audio/spanish/faqQuestions/costInGeneral.mp3'
import faqAspirinQuestionAudio from 'assets/audio/faqQuestions/aspirinQuestion.mp3'
import faqAspirinProtectAudio from 'assets/audio/faqQuestions/aspirinProtect.mp3'
import faqAspirinCommonAudio from 'assets/audio/faqQuestions/aspirinCommon.mp3'
import faqAspirinQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/aspirinQuestion.mp3'
import faqAspirinProtectAudioSpanish from 'assets/audio/spanish/faqQuestions/aspirinProtect.mp3'
import faqAspirinCommonAudioSpanish from 'assets/audio/spanish/faqQuestions/aspirinCommon.mp3'
import faqInjuredQuestionAudio from 'assets/audio/faqQuestions/injuredQuestion.mp3'
import faqInjuredMinorAudio from 'assets/audio/faqQuestions/injuredMinor.mp3'
import faqInjuredCalmAudio from 'assets/audio/faqQuestions/injuredCalm.mp3'
import faqInjuredPressureAudio from 'assets/audio/faqQuestions/injuredPressure.mp3'
import faqInjuredAboveHeartAudio from 'assets/audio/faqQuestions/injuredAboveHeart.mp3'
import faqInjuredHoldAudio from 'assets/audio/faqQuestions/injuredHold.mp3'
import faqInjuredQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredQuestion.mp3'
import faqInjuredMinorAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredMinor.mp3'
import faqInjuredCalmAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredCalm.mp3'
import faqInjuredPressureAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredPressure.mp3'
import faqInjuredAboveHeartAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredAboveHeart.mp3'
import faqInjuredHoldAudioSpanish from 'assets/audio/spanish/faqQuestions/injuredHold.mp3'
import faqSurgeryQuestionAudio from 'assets/audio/faqQuestions/surgeryQuestion.mp3'
import faqSurgeryInstructionsAudio from 'assets/audio/faqQuestions/surgeryInstructions.mp3'
import faqSurgeryQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/surgeryQuestion.mp3'
import faqSurgeryInstructionsAudioSpanish from 'assets/audio/spanish/faqQuestions/surgeryInstructions.mp3'
import faqSeriousQuestionAudio from 'assets/audio/faqQuestions/seriousQuestion.mp3'
import faqSeriousErAudio from 'assets/audio/faqQuestions/seriousEr.mp3'
import faqSeriousSeverePainAudio from 'assets/audio/faqQuestions/seriousSeverePain.mp3'
import faqSeriousAutoAccidentAudio from 'assets/audio/faqQuestions/seriousAutoAccident.mp3'
import faqSeriousCallAudio from 'assets/audio/faqQuestions/seriousCall.mp3'
import faqSeriousPersistentPainAudio from 'assets/audio/faqQuestions/seriousPersistentPain.mp3'
import faqSeriousBleedingContinuesAudio from 'assets/audio/faqQuestions/seriousBleedingContinues.mp3'
import faqSeriousCallDoctorAudio from 'assets/audio/faqQuestions/seriousCallDoctor.mp3'
import faqSeriousQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousQuestion.mp3'
import faqSeriousErAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousEr.mp3'
import faqSeriousSeverePainAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousSeverePain.mp3'
import faqSeriousAutoAccidentAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousAutoAccident.mp3'
import faqSeriousCallAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousCall.mp3'
import faqSeriousPersistentPainAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousPersistentPain.mp3'
import faqSeriousBleedingContinuesAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousBleedingContinues.mp3'
import faqSeriousCallDoctorAudioSpanish from 'assets/audio/spanish/faqQuestions/seriousCallDoctor.mp3'
import faqHowLongQuestionAudio from 'assets/audio/faqQuestions/howLongQuestion.mp3'
import faqHowLongRecommendedAudio from 'assets/audio/faqQuestions/howLongRecommended.mp3'
import faqHowLongQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/howLongQuestion.mp3'
import faqHowLongRecommendedAudioSpanish from 'assets/audio/spanish/faqQuestions/howLongRecommended.mp3'
import faqCanTakeQuestionAudio from 'assets/audio/faqQuestions/canTakeQuestion.mp3'
import faqCanTakeMostAudio from 'assets/audio/faqQuestions/canTakeMost.mp3'
import faqCanTakeImportantAudio from 'assets/audio/faqQuestions/canTakeImportant.mp3'
import faqCanTakeQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/canTakeQuestion.mp3'
import faqCanTakeMostAudioSpanish from 'assets/audio/spanish/faqQuestions/canTakeMost.mp3'
import faqCanTakeImportantAudioSpanish from 'assets/audio/spanish/faqQuestions/canTakeImportant.mp3'
import faqRiskyActivityQuestionAudio from 'assets/audio/faqQuestions/riskyActivityQuestion.mp3'
import faqRiskyActivityCheckAudio from 'assets/audio/faqQuestions/riskyActivityCheck.mp3'
import faqRiskyActivityQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/riskyActivityQuestion.mp3'
import faqRiskyActivityCheckAudioSpanish from 'assets/audio/spanish/faqQuestions/riskyActivityCheck.mp3'
import faqEcgQuestionAudio from 'assets/audio/faqQuestions/ecgQuestion.mp3'
import faqEcgCheckAudio from 'assets/audio/faqQuestions/ecgCheck.mp3'
import faqEcgQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/ecgQuestion.mp3'
import faqEcgCheckAudioSpanish from 'assets/audio/spanish/faqQuestions/ecgCheck.mp3'
import faqWhichThinnerQuestionAudio from 'assets/audio/faqQuestions/whichThinnerQuestion.mp3'
import faqWhichThinnerChoiceAudio from 'assets/audio/faqQuestions/whichThinnerChoice.mp3'
import faqWhichThinnerChoiceWarfarinAudio from 'assets/audio/faqQuestions/whichThinnerChoiceWarfarin.mp3'
import faqWhichThinnerRegularBloodTestsAudio from 'assets/audio/faqQuestions/whichThinnerRegularBloodTests.mp3'
import faqWhichThinnerDietaryChangesAudio from 'assets/audio/faqQuestions/whichThinnerDietaryChanges.mp3'
import faqWhichThinnerThinnersListAudio from 'assets/audio/faqQuestions/whichThinnerThinnersList.mp3'
import faqWhichThinnerRecommendedAudio from 'assets/audio/faqQuestions/whichThinnerRecommended.mp3'
import faqWhichThinnerCostAudio from 'assets/audio/faqQuestions/whichThinnerCost.mp3'
import faqWhichThinnerNotAdjustedAudio from 'assets/audio/faqQuestions/whichThinnerNotAdjusted.mp3'
import faqWhichThinnerDontMissAudio from 'assets/audio/faqQuestions/whichThinnerDontMiss.mp3'
import faqWhichThinnerLowerRiskAudio from 'assets/audio/faqQuestions/whichThinnerLowerRisk.mp3'
import faqWhichThinnerTableBelowAudio from 'assets/audio/faqQuestions/whichThinnerTableBelow.mp3'
import faqWhichThinnerQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerQuestion.mp3'
import faqWhichThinnerChoiceAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerChoice.mp3'
import faqWhichThinnerChoiceWarfarinAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerChoiceWarfarin.mp3'
import faqWhichThinnerRegularBloodTestsAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerRegularBloodTests.mp3'
import faqWhichThinnerDietaryChangesAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerDietaryChanges.mp3'
import faqWhichThinnerThinnersListAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerThinnersList.mp3'
import faqWhichThinnerCostAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerCost.mp3'
import faqWhichThinnerRecommendedAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerRecommended.mp3'
import faqWhichThinnerNotAdjustedAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerNotAdjusted.mp3'
import faqWhichThinnerDontMissAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerDontMiss.mp3'
import faqWhichThinnerLowerRiskAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerLowerRisk.mp3'
import faqWhichThinnerTableBelowAudioSpanish from 'assets/audio/spanish/faqQuestions/whichThinnerTableBelow.mp3'
import faqOtherOptionsQuestionAudio from 'assets/audio/faqQuestions/otherOptionsQuestion.mp3'
import faqOtherOptionsWatchmanAudio from 'assets/audio/faqQuestions/otherOptionsWatchman.mp3'
import faqOtherOptionsQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/otherOptionsQuestion.mp3'
import faqOtherOptionsWatchmanAudioSpanish from 'assets/audio/spanish/faqQuestions/otherOptionsWatchman.mp3'
import faqRiskQuestionAudio from 'assets/audio/faqQuestions/riskQuestion.mp3'
import faqRiskScoringSystemAudio from 'assets/audio/faqQuestions/riskScoringSystem.mp3'
import faqRiskTwoPointsAudio from 'assets/audio/faqQuestions/riskTwoPoints.mp3'
import faqRiskAge75Audio from 'assets/audio/faqQuestions/riskAge75.mp3'
import faqRiskPriorStrokeAudio from 'assets/audio/faqQuestions/riskPriorStroke.mp3'
import faqRiskOnePointAudio from 'assets/audio/faqQuestions/riskOnePoint.mp3'
import faqRiskHeartFailureAudio from 'assets/audio/faqQuestions/riskHeartFailure.mp3'
import faqRiskDiabetesAudio from 'assets/audio/faqQuestions/riskDiabetes.mp3'
import faqRiskHighBloodPressureAudio from 'assets/audio/faqQuestions/riskHighBloodPressure.mp3'
import faqRiskVascularDiseaseAudio from 'assets/audio/faqQuestions/riskVascularDisease.mp3'
import faqRiskAge65Audio from 'assets/audio/faqQuestions/riskAge65.mp3'
import faqRiskFemaleAudio from 'assets/audio/faqQuestions/riskFemale.mp3'
import faqRiskAddUpAudio from 'assets/audio/faqQuestions/riskAddUp.mp3'
import faqRiskScoreZeroAudio from 'assets/audio/faqQuestions/riskScoreZero.mp3'
import faqRiskScoreOneAudio from 'assets/audio/faqQuestions/riskScoreOne.mp3'
import faqRiskScoreTwoAudio from 'assets/audio/faqQuestions/riskScoreTwo.mp3'
import faqRiskQuestionAudioSpanish from 'assets/audio/spanish/faqQuestions/riskQuestion.mp3'
import faqRiskScoringSystemAudioSpanish from 'assets/audio/spanish/faqQuestions/riskScoringSystem.mp3'
import faqRiskTwoPointsAudioSpanish from 'assets/audio/spanish/faqQuestions/riskTwoPoints.mp3'
import faqRiskAge75AudioSpanish from 'assets/audio/spanish/faqQuestions/riskAge75.mp3'
import faqRiskPriorStrokeAudioSpanish from 'assets/audio/spanish/faqQuestions/riskPriorStroke.mp3'
import faqRiskOnePointAudioSpanish from 'assets/audio/spanish/faqQuestions/riskOnePoint.mp3'
import faqRiskHeartFailureAudioSpanish from 'assets/audio/spanish/faqQuestions/riskHeartFailure.mp3'
import faqRiskDiabetesAudioSpanish from 'assets/audio/spanish/faqQuestions/riskDiabetes.mp3'
import faqRiskHighBloodPressureAudioSpanish from 'assets/audio/spanish/faqQuestions/riskHighBloodPressure.mp3'
import faqRiskVascularDiseaseAudioSpanish from 'assets/audio/spanish/faqQuestions/riskVascularDisease.mp3'
import faqRiskAge65AudioSpanish from 'assets/audio/spanish/faqQuestions/riskAge65.mp3'
import faqRiskFemaleAudioSpanish from 'assets/audio/spanish/faqQuestions/riskFemale.mp3'
import faqRiskAddUpAudioSpanish from 'assets/audio/spanish/faqQuestions/riskAddUp.mp3'
import faqRiskScoreZeroAudioSpanish from 'assets/audio/spanish/faqQuestions/riskScoreZero.mp3'
import faqRiskScoreOneAudioSpanish from 'assets/audio/spanish/faqQuestions/riskScoreOne.mp3'
import faqRiskScoreTwoAudioSpanish from 'assets/audio/spanish/faqQuestions/riskScoreTwo.mp3'
import patientStoryGladys1Audio from 'assets/audio/patientStories/gladys1.mp3'
import patientStoryGladys2Audio from 'assets/audio/patientStories/gladys2.mp3'
import patientStoryGladys3Audio from 'assets/audio/patientStories/gladys3.mp3'
import patientStoryGladys1AudioSpanish from 'assets/audio/spanish/patientStories/gladys1.mp3'
import patientStoryGladys2AudioSpanish from 'assets/audio/spanish/patientStories/gladys2.mp3'
import patientStoryGladys3AudioSpanish from 'assets/audio/spanish/patientStories/gladys3.mp3'
import patientStoryLouis1Audio from 'assets/audio/patientStories/louis1.mp3'
import patientStoryLouis2Audio from 'assets/audio/patientStories/louis2.mp3'
import patientStoryLouis3Audio from 'assets/audio/patientStories/louis3.mp3'
import patientStoryLouis1AudioSpanish from 'assets/audio/spanish/patientStories/louis1.mp3'
import patientStoryLouis2AudioSpanish from 'assets/audio/spanish/patientStories/louis2.mp3'
import patientStoryLouis3AudioSpanish from 'assets/audio/spanish/patientStories/louis3.mp3'
import patientStoryMarisa1Audio from 'assets/audio/patientStories/marisa1.mp3'
import patientStoryMarisa2Audio from 'assets/audio/patientStories/marisa2.mp3'
import patientStoryMarisa3Audio from 'assets/audio/patientStories/marisa3.mp3'
import patientStoryMarisa4Audio from 'assets/audio/patientStories/marisa4.mp3'
import patientStoryMarisa5Audio from 'assets/audio/patientStories/marisa5.mp3'
import patientStoryMarisa6Audio from 'assets/audio/patientStories/marisa6.mp3'
import patientStoryMarisa1AudioSpanish from 'assets/audio/spanish/patientStories/marisa1.mp3'
import patientStoryMarisa2AudioSpanish from 'assets/audio/spanish/patientStories/marisa2.mp3'
import patientStoryMarisa3AudioSpanish from 'assets/audio/spanish/patientStories/marisa3.mp3'
import patientStoryMarisa4AudioSpanish from 'assets/audio/spanish/patientStories/marisa4.mp3'
import patientStoryMarisa5AudioSpanish from 'assets/audio/spanish/patientStories/marisa5.mp3'
import patientStoryMarisa6AudioSpanish from 'assets/audio/spanish/patientStories/marisa6.mp3'
import patientStoryJames1Audio from 'assets/audio/patientStories/james1.mp3'
import patientStoryJames2Audio from 'assets/audio/patientStories/james2.mp3'
import patientStoryJames3Audio from 'assets/audio/patientStories/james3.mp3'
import patientStoryJames1AudioSpanish from 'assets/audio/spanish/patientStories/james1.mp3'
import patientStoryJames2AudioSpanish from 'assets/audio/spanish/patientStories/james2.mp3'
import patientStoryJames3AudioSpanish from 'assets/audio/spanish/patientStories/james3.mp3'
import patientStoryBruce1Audio from 'assets/audio/patientStories/bruce1.mp3'
import patientStoryBruce2Audio from 'assets/audio/patientStories/bruce2.mp3'
import patientStoryBruce3Audio from 'assets/audio/patientStories/bruce3.mp3'
import patientStoryBruce4Audio from 'assets/audio/patientStories/bruce4.mp3'
import patientStoryBruce5Audio from 'assets/audio/patientStories/bruce5.mp3'
import patientStoryBruce1AudioSpanish from 'assets/audio/spanish/patientStories/bruce1.mp3'
import patientStoryBruce2AudioSpanish from 'assets/audio/spanish/patientStories/bruce2.mp3'
import patientStoryBruce3AudioSpanish from 'assets/audio/spanish/patientStories/bruce3.mp3'
import patientStoryBruce4AudioSpanish from 'assets/audio/spanish/patientStories/bruce4.mp3'
import patientStoryBruce5AudioSpanish from 'assets/audio/spanish/patientStories/bruce5.mp3'
import patientStoryBarbara1Audio from 'assets/audio/patientStories/barbara1.mp3'
import patientStoryBarbara2Audio from 'assets/audio/patientStories/barbara2.mp3'
import patientStoryBarbara3Audio from 'assets/audio/patientStories/barbara3.mp3'
import patientStoryBarbara4Audio from 'assets/audio/patientStories/barbara4.mp3'
import patientStoryBarbara5Audio from 'assets/audio/patientStories/barbara5.mp3'
import patientStoryBarbara6Audio from 'assets/audio/patientStories/barbara6.mp3'
import patientStoryBarbara1AudioSpanish from 'assets/audio/spanish/patientStories/barbara1.mp3'
import patientStoryBarbara2AudioSpanish from 'assets/audio/spanish/patientStories/barbara2.mp3'
import patientStoryBarbara3AudioSpanish from 'assets/audio/spanish/patientStories/barbara3.mp3'
import patientStoryBarbara4AudioSpanish from 'assets/audio/spanish/patientStories/barbara4.mp3'
import patientStoryBarbara5AudioSpanish from 'assets/audio/spanish/patientStories/barbara5.mp3'
import patientStoryBarbara6AudioSpanish from 'assets/audio/spanish/patientStories/barbara6.mp3'
import patientStorySusan1Audio from 'assets/audio/patientStories/susan1.mp3'
import patientStorySusan2Audio from 'assets/audio/patientStories/susan2.mp3'
import patientStorySusan3Audio from 'assets/audio/patientStories/susan3.mp3'
import patientStorySusan1AudioSpanish from 'assets/audio/spanish/patientStories/susan1.mp3'
import patientStorySusan2AudioSpanish from 'assets/audio/spanish/patientStories/susan2.mp3'
import patientStorySusan3AudioSpanish from 'assets/audio/spanish/patientStories/susan3.mp3'
import patientStoryLarry1Audio from 'assets/audio/patientStories/larry1.mp3'
import patientStoryLarry2Audio from 'assets/audio/patientStories/larry2.mp3'
import patientStoryLarry3Audio from 'assets/audio/patientStories/larry3.mp3'
import patientStoryLarry4Audio from 'assets/audio/patientStories/larry4.mp3'
import patientStoryLarry1AudioSpanish from 'assets/audio/spanish/patientStories/larry1.mp3'
import patientStoryLarry2AudioSpanish from 'assets/audio/spanish/patientStories/larry2.mp3'
import patientStoryLarry3AudioSpanish from 'assets/audio/spanish/patientStories/larry3.mp3'
import patientStoryLarry4AudioSpanish from 'assets/audio/spanish/patientStories/larry4.mp3'
import patientStoryMaria1Audio from 'assets/audio/patientStories/maria1.mp3'
import patientStoryMaria2Audio from 'assets/audio/patientStories/maria2.mp3'
import patientStoryMaria1AudioSpanish from 'assets/audio/spanish/patientStories/maria1.mp3'
import patientStoryMaria2AudioSpanish from 'assets/audio/spanish/patientStories/maria2.mp3'
import wrapUpHeaderAudio from 'assets/audio/wrapUpWorksheetPage/header.mp3'
import wrapUpWorksheetNextStepAudio from 'assets/audio/wrapUpWorksheetPage/nextStep.mp3'
import wrapUpWorksheetDownloadLinkAudio from 'assets/audio/wrapUpWorksheetPage/downloadLink.mp3'
import wrapUpHeaderAudioSpanish from 'assets/audio/spanish/wrapUpWorksheetPage/header.mp3'
import wrapUpWorksheetNextStepAudioSpanish from 'assets/audio/spanish/wrapUpWorksheetPage/nextStep.mp3'
import wrapUpWorksheetDownloadLinkAudioSpanish from 'assets/audio/spanish/wrapUpWorksheetPage/downloadLink.mp3'
import wrapUpDoneThanksAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/thanks.mp3'
import wrapUpDoneThanksAudio from 'assets/audio/wrapUpDonePage/thanks.mp3'
import wrapUpDoneLearnMoreAudio from 'assets/audio/wrapUpDonePage/learnMore.mp3'
import wrapUpDoneWhatIsAudio from 'assets/audio/wrapUpDonePage/whatIs.mp3'
import wrapUpDoneHeartDiseaseAudio from 'assets/audio/wrapUpDonePage/heartDisease.mp3'
import wrapUpDoneRiskFactorsAudio from 'assets/audio/wrapUpDonePage/riskFactors.mp3'
import wrapUpDoneMedicationTrackerAudio from 'assets/audio/wrapUpDonePage/medicationTracker.mp3'
import wrapUpDoneLearnMoreAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/learnMore.mp3'
import wrapUpDoneWhatIsAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/whatIs.mp3'
import wrapUpDoneHeartDiseaseAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/heartDisease.mp3'
import wrapUpDoneRiskFactorsAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/riskFactors.mp3'
import wrapUpDoneMedicationTrackerAudioSpanish from 'assets/audio/spanish/wrapUpDonePage/medicationTracker.mp3'
import menuIntroAudio from 'assets/audio/stepsPage/intro.mp3'
import menuQuizAudio from 'assets/audio/stepsPage/quiz.mp3'
import menuFaqAudio from 'assets/audio/stepsPage/faq.mp3'
import menuWrapUpAudio from 'assets/audio/stepsPage/wrapUp.mp3'
// import menuMenuAudioSpanish from 'assets/audio/spanish/stepsPage/menu.mp3'
import menuIntroAudioSpanish from 'assets/audio/spanish/stepsPage/intro.mp3'
import menuQuizAudioSpanish from 'assets/audio/spanish/stepsPage/quiz.mp3'
import menuFaqAudioSpanish from 'assets/audio/spanish/stepsPage/faq.mp3'
import menuWrapUpAudioSpanish from 'assets/audio/spanish/stepsPage/wrapUp.mp3'
import menuCloseMenuAudioSpanish from 'assets/audio/spanish/stepsPage/closeMenu.mp3'

export default {
  welcomePage: {
    clips: [
      {
        source: {
          en: welcomeHeaderAudio,
          es: welcomeHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: welcomeUnderstandAudio,
          es: welcomeUnderstandAudioSpanish,
        },
        key: 'understand',
      },
      {
        source: {
          en: buttonsLetsBeginPressTheButtonAudio,
          es: buttonsLetsBeginAudioSpanish,
        },
        key: 'letsBegin',
      },
      {
        source: {
          en: welcomeSupportedAudio,
          es: welcomeSupportedAudioSpanish,
        },
        key: 'supported',
      },
    ],
  },
  stepsPage1: {
    clips: [
      {
        source: {
          en: menuIntroAudio,
          es: menuIntroAudioSpanish,
        },
        key: 'intro',
      },
      {
        source: {
          en: buttonsStartAudio,
          es: buttonsStartAudioSpanish,
        },
        key: 'start',
      },
    ],
  },
  stepsPage2: {
    clips: [
      {
        source: {
          en: menuFaqAudio,
          es: menuFaqAudioSpanish,
        },
        key: 'faq',
      },
      {
        source: {
          en: buttonsStartAudio,
          es: buttonsStartAudioSpanish,
        },
        key: 'start',
      },
    ],
  },
  stepsPage3: {
    clips: [
      {
        source: {
          en: menuQuizAudio,
          es: menuQuizAudioSpanish,
        },
        key: 'quiz',
      },
      {
        source: {
          en: buttonsStartAudio,
          es: buttonsStartAudioSpanish,
        },
        key: 'start',
      },
    ],
  },
  stepsPage4: {
    clips: [
      {
        source: {
          en: menuWrapUpAudio,
          es: menuWrapUpAudioSpanish,
        },
        key: 'wrapUp',
      },
      {
        source: {
          en: buttonsStartAudio,
          es: buttonsStartAudioSpanish,
        },
        key: 'start',
      },
    ],
  },
  stepsPageMobile1: {
    clips: [
      {
        source: {
          en: menuIntroAudio,
          es: menuIntroAudioSpanish,
        },
        key: 'intro',
      },
    ],
  },
  stepsPageMobile2: {
    clips: [
      {
        source: {
          en: menuFaqAudio,
          es: menuFaqAudioSpanish,
        },
        key: 'faq',
      },
    ],
  },
  stepsPageMobile3: {
    clips: [
      {
        source: {
          en: menuQuizAudio,
          es: menuQuizAudioSpanish,
        },
        key: 'quiz',
      },
    ],
  },
  stepsPageMobile4: {
    clips: [
      {
        source: {
          en: menuWrapUpAudio,
          es: menuWrapUpAudioSpanish,
        },
        key: 'wrapUp',
      },
    ],
  },
  menu: {
    clips: [
      // {
      //   source: {
      //     es: menuMenuAudioSpanish,
      //   },
      //   key: 'menu',
      // },
      {
        source: {
          en: menuIntroAudio,
          es: menuIntroAudioSpanish,
        },
        key: 'intro',
      },
      {
        source: {
          en: menuFaqAudio,
          es: menuFaqAudioSpanish,
        },
        key: 'faq',
      },
      {
        source: {
          en: menuQuizAudio,
          es: menuQuizAudioSpanish,
        },
        key: 'quiz',
      },
      {
        source: {
          en: menuWrapUpAudio,
          es: menuWrapUpAudioSpanish,
        },
        key: 'wrapUp',
      },
      {
        source: {
          es: menuCloseMenuAudioSpanish,
        },
        key: 'closeMenu',
      },
    ],
  },
  introVideoPage: {
    clips: [
      {
        source: {
          en: introVideoVideoTitleAudio,
          es: introVideoVideoTitleAudioSpanish,
        },
        key: 'videoTitle',
      },
      {
        source: {
          en: introVideoPlayAudio,
        },
        key: 'play',
      },
    ],
  },
  introReviewPage: {
    clips: [
      {
        source: {
          en: introReviewHeaderAudio,
          es: introReviewHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: buttonsLetsBeginAudio,
          es: buttonsLetsBeginAudioSpanish,
        },
        key: 'letsBegin',
      },
    ],
  },
  introKeyMessagesPage1: {
    clips: [
      {
        source: {
          en: introKeyMessagesStrokeAudio,
          es: introKeyMessagesStrokeAudioSpanish,
        },
        key: 'message',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  introKeyMessagesPage2: {
    clips: [
      {
        source: {
          en: introKeyMessagesBloodThinnerAudio,
          es: introKeyMessagesBloodThinnerAudioSpanish,
        },
        key: 'message',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  introKeyMessagesPage3: {
    clips: [
      {
        source: {
          en: introKeyMessagesBenefitsAudio,
          es: introKeyMessagesBenefitsAudioSpanish,
        },
        key: 'message',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  introKeyMessagesPage4: {
    clips: [
      {
        source: {
          en: introKeyMessagesChoiceAudio,
          es: introKeyMessagesChoiceAudioSpanish,
        },
        key: 'message',
      },
      {
        source: {
          en: buttonsContinueAudio,
          es: buttonsContinueAudioSpanish,
        },
        key: 'continueJourney',
      },
    ],
  },
  quizIntroPage: {
    clips: [
      {
        source: {
          en: quizIntroHeaderAudio,
          es: quizIntroHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: quizIntroQuestionAudio,
          es: quizIntroQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: buttonsLetsBeginAudio,
          es: buttonsLetsBeginAudioSpanish,
        },
        key: 'letsBegin',
      },
    ],
  },
  quizIntroReplayIntroVideoLightbox: {
    clips: [],
  },
  quizQuestionsPage1: {
    clips: [
      {
        source: {
          en: quizWhyDangerousAudio,
          es: quizWhyDangerousAudioSpanish,
        },
        key: 'question',
      },
      // {
      //   source: {
      //     en: quizPickOneAudio,
      //   },
      //   key: 'pickOne',
      // },
      {
        source: {
          en: quizCanLeadToHeartAttackAudio,
          es: quizCanLeadToHeartAttackAudioSpanish,
        },
        key: 'canLeadToHeartAttack',
      },
      {
        source: {
          en: quizCanLeadToStrokeAudio,
          es: quizCanLeadToStrokeAudioSpanish,
        },
        key: 'canLeadToStroke',
      },
      {
        source: {
          en: quizInfectiousAudio,
          es: quizInfectiousAudioSpanish,
        },
        key: 'infectious',
      },
    ],
  },
  quizTooltip1: {
    clips: [
      {
        source: {
          en: quizHeartConditionAudio,
          es: quizHeartConditionAudioSpanish,
        },
        key: 'tooltip',
      },
    ],
  },
  quizQuestionsPage2: {
    clips: [
      {
        source: {
          en: quizBloodThinnerAudio,
          es: quizBloodThinnerAudioSpanish,
        },
        key: 'question',
      },
      // {
      //   source: {
      //     en: quizPickOneAudio,
      //   },
      //   key: 'pickOne',
      // },
      {
        source: {
          en: quizMakesThickAudio,
          es: quizMakesThickAudioSpanish,
        },
        key: 'makesThick',
      },
      {
        source: {
          en: quizCuresAudio,
          es: quizCuresAudioSpanish,
        },
        key: 'cures',
      },
      {
        source: {
          en: quizProtectsAudio,
          es: quizProtectsAudioSpanish,
        },
        key: 'protects',
      },
    ],
  },
  quizTooltip2: {
    clips: [
      {
        source: {
          en: quizLowerRiskAudio,
          es: quizLowerRiskAudioSpanish,
        },
        key: 'tooltip',
      },
    ],
  },
  quizQuestionsPage3: {
    clips: [
      {
        source: {
          en: quizChangeMindAudio,
          es: quizChangeMindAudioSpanish,
        },
        key: 'question',
      },
      // {
      //   source: {
      //     en: quizPickOneAudio,
      //   },
      //   key: 'pickOne',
      // },
      {
        source: {
          en: quizNoAudio,
          es: quizNoAudioSpanish,
        },
        key: 'no',
      },
      {
        source: {
          en: quizYesAudio,
          es: quizYesAudioSpanish,
        },
        key: 'yes',
      },
    ],
  },
  quizTooltip3: {
    clips: [
      {
        source: {
          en: quizYourChoiceAudio,
          es: quizYourChoiceAudioSpanish,
        },
        key: 'tooltip',
      },
    ],
  },
  quizIncorrect: {
    clips: [
      {
        source: {
          en: quizTryAgainAudio,
          es: quizTryAgainAudioSpanish,
        },
        key: 'tryAgain',
      },
      {
        source: {
          en: introVideoPlayAudio,
        },
        key: 'play',
      },
    ],
  },
  quizCorrect: {
    clips: [
      {
        source: {
          en: quizCorrectAudio,
          es: quizCorrectAudioSpanish,
        },
        key: 'correct',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  quizCorrectMobile1: {
    clips: [
      {
        source: {
          en: quizCorrectAudio,
          es: quizCorrectAudioSpanish,
        },
        key: 'correct',
      },
      {
        source: {
          en: quizCorrectCanLeadToStrokeAudio,
          es: quizCorrectCanLeadToStrokeAudioSpanish,
        },
        key: 'answer',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  quizCorrectMobile2: {
    clips: [
      {
        source: {
          en: quizCorrectAudio,
          es: quizCorrectAudioSpanish,
        },
        key: 'correct',
      },
      {
        source: {
          en: quizCorrectProtectsAudio,
          es: quizCorrectProtectsAudioSpanish,
        },
        key: 'answer',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  quizCorrectMobile3: {
    clips: [
      {
        source: {
          en: quizCorrectAudio,
          es: quizCorrectAudioSpanish,
        },
        key: 'correct',
      },
      {
        source: {
          en: quizCorrectYesAudio,
          es: quizCorrectYesAudioSpanish,
        },
        key: 'answer',
      },
      {
        source: {
          en: buttonsDoneAudio,
          es: buttonsDoneAudioSpanish,
        },
        key: 'continueJourney',
      },
    ],
  },
  quizCorrectLast: {
    clips: [
      {
        source: {
          en: quizCorrectAudio,
          es: quizCorrectAudioSpanish,
        },
        key: 'correct',
      },
      {
        source: {
          en: buttonsDoneAudio,
          es: buttonsDoneAudioSpanish,
        },
        key: 'continueJourney',
      },
    ],
  },
  faqIntroPage: {
    clips: [
      {
        source: {
          en: faqIntroHeaderAudio,
          es: faqIntroHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: faqIntroQuestionAudio,
          es: faqIntroQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: buttonsLetsBeginAudio,
          es: buttonsLetsBeginAudioSpanish,
        },
        key: 'letsBegin',
      },
    ],
  },
  faqQuestionsPage: {
    clips: [
      {
        source: {
          en: faqIntroHeaderAudio,
          es: faqIntroHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: faqBalanceQuestionAudio,
          es: faqBalanceQuestionAudioSpanish,
        },
        key: 'balance-question',
      },
      {
        source: {
          en: faqCanReverseQuestionAudio,
          es: faqCanReverseQuestionAudioSpanish,
        },
        key: 'canReverse-question',
      },
      {
        source: {
          en: faqCostQuestionAudio,
          es: faqCostQuestionAudioSpanish,
        },
        key: 'cost-question',
      },
      {
        source: {
          en: faqAspirinQuestionAudio,
          es: faqAspirinQuestionAudioSpanish,
        },
        key: 'aspirin-question',
      },
      {
        source: {
          en: faqInjuredQuestionAudio,
          es: faqInjuredQuestionAudioSpanish,
        },
        key: 'injured-question',
      },
      {
        source: {
          en: faqSurgeryQuestionAudio,
          es: faqSurgeryQuestionAudioSpanish,
        },
        key: 'surgery-question',
      },
      {
        source: {
          en: faqSeriousQuestionAudio,
          es: faqSeriousQuestionAudioSpanish,
        },
        key: 'serious-question',
      },
      {
        source: {
          en: faqHowLongQuestionAudio,
          es: faqHowLongQuestionAudioSpanish,
        },
        key: 'howLong-question',
      },
      {
        source: {
          en: faqCanTakeQuestionAudio,
          es: faqCanTakeQuestionAudioSpanish,
        },
        key: 'canTake-question',
      },
      {
        source: {
          en: faqRiskyActivityQuestionAudio,
          es: faqRiskyActivityQuestionAudioSpanish,
        },
        key: 'riskyActivity-question',
      },
      {
        source: {
          en: faqEcgQuestionAudio,
          es: faqEcgQuestionAudioSpanish,
        },
        key: 'ecg-question',
      },
      {
        source: {
          en: faqWhichThinnerQuestionAudio,
          es: faqWhichThinnerQuestionAudioSpanish,
        },
        key: 'whichThinner-question',
      },
      {
        source: {
          en: faqOtherOptionsQuestionAudio,
          es: faqOtherOptionsQuestionAudioSpanish,
        },
        key: 'otherOptions-question',
      },
      {
        source: {
          en: faqRiskQuestionAudio,
          es: faqRiskQuestionAudioSpanish,
        },
        key: 'risk-question',
      },
    ],
  },
  faqQuestionPageBalance: {
    clips: [
      {
        source: {
          en: faqBalanceQuestionAudio,
          es: faqBalanceQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: introVideoPlayAudio,
        },
        key: 'play',
      },
    ],
  },
  faqVideoLightbox: {
    clips: [],
  },
  faqQuestionPageCanReverse: {
    clips: [
      {
        source: {
          en: faqCanReverseQuestionAudio,
          es: faqCanReverseQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqCanReverseBadInjuryAudio,
          es: faqCanReverseBadInjuryAudioSpanish,
        },
        key: 'badInjury',
      },
    ],
  },
  faqQuestionPageCost: {
    clips: [
      {
        source: {
          en: faqCostQuestionAudio,
          es: faqCostQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqCostPriceAudio,
          es: faqCostPriceAudioSpanish,
        },
        key: 'price',
      },
      {
        source: {
          en: faqCostInGeneralAudio,
          es: faqCostInGeneralAudioSpanish,
        },
        key: 'inGeneral',
      },
      {
        source: {
          en: faqCostMonitoringAudio,
          es: faqCostMonitoringAudioSpanish,
        },
        key: 'monitoring',
      },
    ],
  },
  faqQuestionPageAspirin: {
    clips: [
      {
        source: {
          en: faqAspirinQuestionAudio,
          es: faqAspirinQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqAspirinProtectAudio,
          es: faqAspirinProtectAudioSpanish,
        },
        key: 'protect',
      },
      {
        source: {
          en: faqAspirinCommonAudio,
          es: faqAspirinCommonAudioSpanish,
        },
        key: 'common',
      },
    ],
  },
  faqQuestionPageInjured: {
    clips: [
      {
        source: {
          en: faqInjuredQuestionAudio,
          es: faqInjuredQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqInjuredMinorAudio,
          es: faqInjuredMinorAudioSpanish,
        },
        key: 'minor',
      },
      {
        source: {
          en: faqInjuredCalmAudio,
          es: faqInjuredCalmAudioSpanish,
        },
        key: 'calm',
      },
      {
        source: {
          en: faqInjuredPressureAudio,
          es: faqInjuredPressureAudioSpanish,
        },
        key: 'pressure',
      },
      {
        source: {
          en: faqInjuredAboveHeartAudio,
          es: faqInjuredAboveHeartAudioSpanish,
        },
        key: 'aboveHeart',
      },
      {
        source: {
          en: faqInjuredHoldAudio,
          es: faqInjuredHoldAudioSpanish,
        },
        key: 'hold',
      },
    ],
  },
  faqQuestionPageSurgery: {
    clips: [
      {
        source: {
          en: faqSurgeryQuestionAudio,
          es: faqSurgeryQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqSurgeryInstructionsAudio,
          es: faqSurgeryInstructionsAudioSpanish,
        },
        key: 'instructions',
      },
    ],
  },
  faqQuestionPageSerious: {
    clips: [
      {
        source: {
          en: faqSeriousQuestionAudio,
          es: faqSeriousQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqSeriousErAudio,
          es: faqSeriousErAudioSpanish,
        },
        key: 'er',
      },
      {
        source: {
          en: faqSeriousSeverePainAudio,
          es: faqSeriousSeverePainAudioSpanish,
        },
        key: 'severePain',
      },
      {
        source: {
          en: faqSeriousAutoAccidentAudio,
          es: faqSeriousAutoAccidentAudioSpanish,
        },
        key: 'autoAccident',
      },
      {
        source: {
          en: faqSeriousCallAudio,
          es: faqSeriousCallAudioSpanish,
        },
        key: 'call',
      },
      {
        source: {
          en: faqSeriousPersistentPainAudio,
          es: faqSeriousPersistentPainAudioSpanish,
        },
        key: 'persistentPain',
      },
      {
        source: {
          en: faqSeriousBleedingContinuesAudio,
          es: faqSeriousBleedingContinuesAudioSpanish,
        },
        key: 'bleedingContinues',
      },
      {
        source: {
          en: faqSeriousCallDoctorAudio,
          es: faqSeriousCallDoctorAudioSpanish,
        },
        key: 'callDoctor',
      },
    ],
  },
  faqQuestionPageHowLong: {
    clips: [
      {
        source: {
          en: faqHowLongQuestionAudio,
          es: faqHowLongQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqHowLongRecommendedAudio,
          es: faqHowLongRecommendedAudioSpanish,
        },
        key: 'recommended',
      },
    ],
  },
  faqQuestionPageCanTake: {
    clips: [
      {
        source: {
          en: faqCanTakeQuestionAudio,
          es: faqCanTakeQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqCanTakeMostAudio,
          es: faqCanTakeMostAudioSpanish,
        },
        key: 'most',
      },
      {
        source: {
          en: faqCanTakeImportantAudio,
          es: faqCanTakeImportantAudioSpanish,
        },
        key: 'important',
      },
    ],
  },
  faqQuestionPageRiskyActivity: {
    clips: [
      {
        source: {
          en: faqRiskyActivityQuestionAudio,
          es: faqRiskyActivityQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqRiskyActivityCheckAudio,
          es: faqRiskyActivityCheckAudioSpanish,
        },
        key: 'check',
      },
    ],
  },
  faqQuestionPageEcg: {
    clips: [
      {
        source: {
          en: faqEcgQuestionAudio,
          es: faqEcgQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqEcgCheckAudio,
          es: faqEcgCheckAudioSpanish,
        },
        key: 'check',
      },
    ],
  },
  faqQuestionPageWhichThinner: {
    clips: [
      {
        source: {
          en: faqWhichThinnerQuestionAudio,
          es: faqWhichThinnerQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqWhichThinnerChoiceAudio,
          es: faqWhichThinnerChoiceAudioSpanish,
        },
        key: 'choice',
      },
      {
        source: {
          en: faqWhichThinnerChoiceWarfarinAudio,
          es: faqWhichThinnerChoiceWarfarinAudioSpanish,
        },
        key: 'choiceWarfarin',
      },
      {
        source: {
          en: faqWhichThinnerRegularBloodTestsAudio,
          es: faqWhichThinnerRegularBloodTestsAudioSpanish,
        },
        key: 'regularBloodTests',
      },
      {
        source: {
          en: faqWhichThinnerDietaryChangesAudio,
          es: faqWhichThinnerDietaryChangesAudioSpanish,
        },
        key: 'dietaryChanges',
      },
      {
        source: {
          en: faqWhichThinnerThinnersListAudio,
          es: faqWhichThinnerThinnersListAudioSpanish,
        },
        key: 'thinnersList',
      },
      {
        source: {
          en: faqWhichThinnerRecommendedAudio,
          es: faqWhichThinnerRecommendedAudioSpanish,
        },
        key: 'recommended',
      },
      {
        source: {
          en: faqWhichThinnerNotAdjustedAudio,
          es: faqWhichThinnerNotAdjustedAudioSpanish,
        },
        key: 'notAdjusted',
      },
      {
        source: {
          en: faqWhichThinnerLowerRiskAudio,
          es: faqWhichThinnerLowerRiskAudioSpanish,
        },
        key: 'lowerRisk',
      },
      {
        source: {
          en: faqWhichThinnerCostAudio,
          es: faqWhichThinnerCostAudioSpanish,
        },
        key: 'cost',
      },
      {
        source: {
          en: faqWhichThinnerDontMissAudio,
          es: faqWhichThinnerDontMissAudioSpanish,
        },
        key: 'dontMiss',
      },
      {
        source: {
          en: faqWhichThinnerTableBelowAudio,
          es: faqWhichThinnerTableBelowAudioSpanish,
        },
        key: 'tableBelow',
      },
    ],
  },
  faqQuestionPageOtherOptions: {
    clips: [
      {
        source: {
          en: faqOtherOptionsQuestionAudio,
          es: faqOtherOptionsQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqOtherOptionsWatchmanAudio,
          es: faqOtherOptionsWatchmanAudioSpanish,
        },
        key: 'watchman',
      },
    ],
  },
  faqQuestionPageRisk: {
    clips: [
      {
        source: {
          en: faqRiskQuestionAudio,
          es: faqRiskQuestionAudioSpanish,
        },
        key: 'question',
      },
      {
        source: {
          en: faqRiskScoringSystemAudio,
          es: faqRiskScoringSystemAudioSpanish,
        },
        key: 'scoringSystem',
      },
      {
        source: {
          en: faqRiskTwoPointsAudio,
          es: faqRiskTwoPointsAudioSpanish,
        },
        key: 'twoPoints',
      },
      {
        source: {
          en: faqRiskAge75Audio,
          es: faqRiskAge75AudioSpanish,
        },
        key: 'age75',
      },
      {
        source: {
          en: faqRiskPriorStrokeAudio,
          es: faqRiskPriorStrokeAudioSpanish,
        },
        key: 'priorStroke',
      },
      {
        source: {
          en: faqRiskOnePointAudio,
          es: faqRiskOnePointAudioSpanish,
        },
        key: 'onePoint',
      },
      {
        source: {
          en: faqRiskHeartFailureAudio,
          es: faqRiskHeartFailureAudioSpanish,
        },
        key: 'heartFailure',
      },
      {
        source: {
          en: faqRiskDiabetesAudio,
          es: faqRiskDiabetesAudioSpanish,
        },
        key: 'diabetes',
      },
      {
        source: {
          en: faqRiskHighBloodPressureAudio,
          es: faqRiskHighBloodPressureAudioSpanish,
        },
        key: 'highBloodPressure',
      },
      {
        source: {
          en: faqRiskVascularDiseaseAudio,
          es: faqRiskVascularDiseaseAudioSpanish,
        },
        key: 'vascularDisease',
      },
      {
        source: {
          en: faqRiskAge65Audio,
          es: faqRiskAge65AudioSpanish,
        },
        key: 'age65',
      },
      {
        source: {
          en: faqRiskFemaleAudio,
          es: faqRiskFemaleAudioSpanish,
        },
        key: 'female',
      },
      {
        source: {
          en: faqRiskAddUpAudio,
          es: faqRiskAddUpAudioSpanish,
        },
        key: 'addUp',
      },
      {
        source: {
          en: faqRiskScoreZeroAudio,
          es: faqRiskScoreZeroAudioSpanish,
        },
        key: 'scoreZero',
      },
      {
        source: {
          en: faqRiskScoreOneAudio,
          es: faqRiskScoreOneAudioSpanish,
        },
        key: 'scoreOne',
      },
      {
        source: {
          en: faqRiskScoreTwoAudio,
          es: faqRiskScoreTwoAudioSpanish,
        },
        key: 'scoreTwo',
      },
      {
        source: {
          en: buttonsContinueAudio,
          es: buttonsContinueAudioSpanish,
        },
        key: 'done',
      },
    ],
  },
  // patientStoryGladysModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryGladysStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryLouisModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryLouisStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryMarisaModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryMarisaStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryJamesModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryJamesStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryBruceModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryBruceStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryBarbaraModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryBarbaraStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStorySusanModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStorySusanStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryLarryModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryLarryStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  // patientStoryMariaModal: {
  //   clips: [
  //     {
  //       source: {
  //         en: patientStoryMariaStoryAudio,
  //       },
  //       key: 'header',
  //     },
  //     {
  //       source: {
  //         en: buttonsListenAudio,
  //       },
  //       key: 'listen',
  //     },
  //     {
  //       source: {
  //         en: buttonsCloseAudio,
  //       },
  //       key: 'close',
  //     },
  //   ],
  // },
  patientStoryGladys: {
    clips: [
      {
        source: {
          en: patientStoryGladys1Audio,
          es: patientStoryGladys1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryGladys2Audio,
          es: patientStoryGladys2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryGladys3Audio,
          es: patientStoryGladys3AudioSpanish,
        },
        key: 'sentence3',
      },
    ],
  },
  patientStoryLouis: {
    clips: [
      {
        source: {
          en: patientStoryLouis1Audio,
          es: patientStoryLouis1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryLouis2Audio,
          es: patientStoryLouis2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryLouis3Audio,
          es: patientStoryLouis3AudioSpanish,
        },
        key: 'sentence3',
      },
    ],
  },
  patientStoryMarisa: {
    clips: [
      {
        source: {
          en: patientStoryMarisa1Audio,
          es: patientStoryMarisa1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryMarisa2Audio,
          es: patientStoryMarisa2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryMarisa3Audio,
          es: patientStoryMarisa3AudioSpanish,
        },
        key: 'sentence3',
      },
      {
        source: {
          en: patientStoryMarisa4Audio,
          es: patientStoryMarisa4AudioSpanish,
        },
        key: 'sentence4',
      },
      {
        source: {
          en: patientStoryMarisa5Audio,
          es: patientStoryMarisa5AudioSpanish,
        },
        key: 'sentence5',
      },
      {
        source: {
          en: patientStoryMarisa6Audio,
          es: patientStoryMarisa6AudioSpanish,
        },
        key: 'sentence6',
      },
    ],
  },
  patientStoryJames: {
    clips: [
      {
        source: {
          en: patientStoryJames1Audio,
          es: patientStoryJames1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryJames2Audio,
          es: patientStoryJames2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryJames3Audio,
          es: patientStoryJames3AudioSpanish,
        },
        key: 'sentence3',
      },
    ],
  },
  patientStoryBruce: {
    clips: [
      {
        source: {
          en: patientStoryBruce1Audio,
          es: patientStoryBruce1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryBruce2Audio,
          es: patientStoryBruce2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryBruce3Audio,
          es: patientStoryBruce3AudioSpanish,
        },
        key: 'sentence3',
      },
      {
        source: {
          en: patientStoryBruce4Audio,
          es: patientStoryBruce4AudioSpanish,
        },
        key: 'sentence4',
      },
      {
        source: {
          en: patientStoryBruce5Audio,
          es: patientStoryBruce5AudioSpanish,
        },
        key: 'sentence5',
      },
    ],
  },
  patientStoryBarbara: {
    clips: [
      {
        source: {
          en: patientStoryBarbara1Audio,
          es: patientStoryBarbara1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryBarbara2Audio,
          es: patientStoryBarbara2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryBarbara3Audio,
          es: patientStoryBarbara3AudioSpanish,
        },
        key: 'sentence3',
      },
      {
        source: {
          en: patientStoryBarbara4Audio,
          es: patientStoryBarbara4AudioSpanish,
        },
        key: 'sentence4',
      },
      {
        source: {
          en: patientStoryBarbara5Audio,
          es: patientStoryBarbara5AudioSpanish,
        },
        key: 'sentence5',
      },
      {
        source: {
          en: patientStoryBarbara6Audio,
          es: patientStoryBarbara6AudioSpanish,
        },
        key: 'sentence6',
      },
    ],
  },
  patientStorySusan: {
    clips: [
      {
        source: {
          en: patientStorySusan1Audio,
          es: patientStorySusan1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStorySusan2Audio,
          es: patientStorySusan2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStorySusan3Audio,
          es: patientStorySusan3AudioSpanish,
        },
        key: 'sentence3',
      },
    ],
  },
  patientStoryLarry: {
    clips: [
      {
        source: {
          en: patientStoryLarry1Audio,
          es: patientStoryLarry1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryLarry2Audio,
          es: patientStoryLarry2AudioSpanish,
        },
        key: 'sentence2',
      },
      {
        source: {
          en: patientStoryLarry3Audio,
          es: patientStoryLarry3AudioSpanish,
        },
        key: 'sentence3',
      },
      {
        source: {
          en: patientStoryLarry4Audio,
          es: patientStoryLarry4AudioSpanish,
        },
        key: 'sentence4',
      },
    ],
  },
  patientStoryMaria: {
    clips: [
      {
        source: {
          en: patientStoryMaria1Audio,
          es: patientStoryMaria1AudioSpanish,
        },
        key: 'sentence1',
      },
      {
        source: {
          en: patientStoryMaria2Audio,
          es: patientStoryMaria2AudioSpanish,
        },
        key: 'sentence2',
      },
    ],
  },
  wrapUpWorksheetPage: {
    clips: [
      {
        source: {
          en: wrapUpHeaderAudio,
          es: wrapUpHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: wrapUpWorksheetNextStepAudio,
          es: wrapUpWorksheetNextStepAudioSpanish,
        },
        key: 'nextStep',
      },
      {
        source: {
          en: wrapUpWorksheetDownloadLinkAudio,
          es: wrapUpWorksheetDownloadLinkAudioSpanish,
        },
        key: 'downloadLink',
      },
      {
        source: {
          en: buttonsNextAudio,
          es: buttonsNextAudioSpanish,
        },
        key: 'next',
      },
    ],
  },
  wrapUpDonePage: {
    clips: [
      {
        source: {
          en: wrapUpHeaderAudio,
          es: wrapUpHeaderAudioSpanish,
        },
        key: 'header',
      },
      {
        source: {
          en: wrapUpDoneThanksAudio,
          es: wrapUpDoneThanksAudioSpanish,
        },
        key: 'thanks',
      },
      {
        source: {
          en: wrapUpDoneLearnMoreAudio,
          es: wrapUpDoneLearnMoreAudioSpanish,
        },
        key: 'learnMore',
      },
      {
        source: {
          en: wrapUpDoneWhatIsAudio,
          es: wrapUpDoneWhatIsAudioSpanish,
        },
        key: 'whatIs',
      },
      {
        source: {
          en: wrapUpDoneHeartDiseaseAudio,
          es: wrapUpDoneHeartDiseaseAudioSpanish,
        },
        key: 'heartDisease',
      },
      {
        source: {
          en: wrapUpDoneRiskFactorsAudio,
          es: wrapUpDoneRiskFactorsAudioSpanish,
        },
        key: 'riskFactors',
      },
      {
        source: {
          en: wrapUpDoneMedicationTrackerAudio,
          es: wrapUpDoneMedicationTrackerAudioSpanish,
        },
        key: 'medicationTracker',
      },
      {
        source: {
          en: buttonsDoneAudio,
          es: buttonsDoneAudioSpanish,
        },
        key: 'done',
      },
    ],
  },
}
