import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'
import {addProps, addStateHandlers, addHandlers, flowMax} from 'ad-hok'
import {connect} from 'react-redux'
import {upperFirst, range} from 'lodash'

import Button from 'components/Button'
import {addTranslationHelpers} from 'util/i18n'
import Lightbox from 'components/Lightbox'
import {imageSourcePropType, classNamePropType} from 'util/propTypes'
import playButtonSmallIcon from 'assets/images/play-button-white-small.svg'
import pauseIcon from 'assets/images/pause.svg'
import {mq} from 'style/mediaQueries'
import colors from 'style/colors'
import shadows from 'style/shadows'
import {audioSelector} from 'redux-local/selectors'
import {
  playAudioSection,
  pseudoActivateAudioMode,
  pauseAudio,
  unpauseAudio,
  pseudoDeactivateAudioMode,
} from 'redux-local/actions'
import AudioHighlight from 'components/AudioHighlight'
import AudioSection from 'components/AudioSection'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import {addTrackingProps, addTrackingOnPropBecomingTruthy} from 'util/tracking'

const NameAndAvatar = flow(
  addTranslationHelpers,
  ({name, avatar, t, className}) => (
    <div className={className} css={styles.leftContainer}>
      <img src={avatar} css={styles.avatar} alt="" />
      <span css={styles.name}>
        <AudioHighlight clipKey="header">
          {t('faqQuestionPage.patientStoryName', {name})}
        </AudioHighlight>
      </span>
    </div>
  )
)

NameAndAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: imageSourcePropType.isRequired,
  className: classNamePropType,
}

const enhancePatientStory = connect(
  audioSelector,
  dispatch => ({
    onPlayAudioSection: id =>
      dispatch(playAudioSection(id, {storePrevious: false})),
    onPseudoActivateAudioMode: () => dispatch(pseudoActivateAudioMode()),
    onPseudoDeactivateAudioMode: () => dispatch(pseudoDeactivateAudioMode()),
    onPause: () => dispatch(pauseAudio()),
    onUnpause: () => dispatch(unpauseAudio()),
  })
)

let PatientStory = flowMax(
  addTranslationHelpers,
  addStateHandlers(
    {showingModal: false},
    {
      onShowLightbox: () => () => ({showingModal: true}),
      onHideLightbox: () => () => ({showingModal: false}),
    }
  ),
  addHandlers({
    onHideLightbox: ({onHideLightbox, onPseudoDeactivateAudioMode}) => () => {
      onPseudoDeactivateAudioMode()
      onHideLightbox()
    },
  }),
  addProps(({keyPrefix, story: {nameKey}, story, t}) => ({
    name: t(`${keyPrefix}.${nameKey}`),
    audioSectionId: `patient${upperFirst(story.key)}`,
  })),
  addTrackingProps(({story}) => ({
    'Patient story': upperFirst(story.key),
  })),
  addTrackingOnPropBecomingTruthy('showingModal', 'Opened patient story'),
  addHandlers({
    onListen: ({
      isAudioModePseudoActive,
      onPlayAudioSection,
      onPseudoActivateAudioMode,
      isPaused,
      onUnpause,
      audioSectionId,
    }) => () => {
      if (isPaused) {
        onUnpause()
      }
      if (!isAudioModePseudoActive) {
        onPseudoActivateAudioMode()
      }
      onPlayAudioSection(audioSectionId)
    },
  }),
  addEffectOnPropChange('showingModal', ({showingModal, onListen}) => {
    if (!showingModal) return
    onListen()
  }),
  addProps(
    ({
      clipIndex,
      isAudioModePseudoActive,
      isPaused,
      story: {numSentences},
    }) => ({
      isPlaying:
        isAudioModePseudoActive && clipIndex < numSentences && !isPaused,
    })
  ),
  addHandlers({
    onClickPlayPauseButton: ({
      isPlaying,
      isPaused,
      onUnpause,
      onPause,
      onListen,
      isAudioModePseudoActive,
    }) => () => {
      if (!isAudioModePseudoActive) {
        onListen()
        return
      }
      if (isPlaying) {
        onPause()
        return
      }
      if (isPaused) {
        onUnpause()
        return
      }
      onListen()
    },
  }),
  ({
    story: {avatar, key, numSentences},
    t,
    showingModal,
    onShowLightbox,
    onHideLightbox,
    keyPrefix,
    name,
    onClickPlayPauseButton,
    isPlaying,
    audioSectionId,
  }) => (
    <>
      <Button
        mode="plain"
        css={styles.container}
        onClick={onShowLightbox}
        key={name}
      >
        <NameAndAvatar name={name} avatar={avatar} />
        <div css={styles.listenButtonContainer}>
          <img src={playButtonSmallIcon} css={styles.listenButtonIcon} alt="" />
          <span css={styles.listenButtonLabel}>{t('buttons.listen')}</span>
        </div>
      </Button>
      <Lightbox
        show={showingModal}
        onHide={onHideLightbox}
        css={styles.lightbox}
        bodyCss={styles.lightboxBody}
        showCloseButton={false}
        audioSectionId={`patient${upperFirst(key)}Modal`}
      >
        <NameAndAvatar
          name={name}
          avatar={avatar}
          css={styles.nameAndAvatarLightbox}
        />
        <Button
          css={[
            styles.listenButtonContainer,
            styles.listenButtonContainerLightbox,
          ]}
          onClick={onClickPlayPauseButton}
        >
          {isPlaying ? (
            <>
              <img src={pauseIcon} css={styles.pauseButtonIcon} alt="" />
              <span css={styles.pauseButtonLabel}>{t('buttons.pause')}</span>
            </>
          ) : (
            <>
              <img
                src={playButtonSmallIcon}
                css={styles.listenButtonIcon}
                alt=""
              />
              <span css={styles.listenButtonLabel}>
                <AudioHighlight clipKey="listen">
                  {t('buttons.listen')}
                </AudioHighlight>
              </span>
            </>
          )}
        </Button>
        <AudioSection id={audioSectionId}>
          <p css={styles.storyText}>
            {range(numSentences).map(sentenceIndex => (
              <Fragment key={sentenceIndex}>
                <AudioHighlight clipKey={`sentence${sentenceIndex + 1}`}>
                  {t(`${keyPrefix}.${key}${sentenceIndex + 1}`)}
                </AudioHighlight>{' '}
              </Fragment>
            ))}
          </p>
        </AudioSection>
        <div css={styles.closeButtonRow}>
          <Button
            mode="plain"
            css={styles.closeButton}
            onClick={onHideLightbox}
            title={t('buttons.close')}
            data-testid={`patient-story-modal-close-${name.toLowerCase()}`}
            audioClipKey="close"
          />
        </div>
      </Lightbox>
    </>
  )
)

PatientStory.propTypes = {
  story: PropTypes.shape({
    nameKey: PropTypes.string.isRequired,
    avatar: imageSourcePropType.isRequired,
    key: PropTypes.string.isRequired,
    numSentences: PropTypes.number.isRequired,
  }).isRequired,
  keyPrefix: PropTypes.string.isRequired,
  isAudioModeActive: PropTypes.bool.isRequired,
  isAudioModePseudoActive: PropTypes.bool.isRequired,
  clipIndex: PropTypes.number,
  onPause: PropTypes.func.isRequired,
  onUnpause: PropTypes.func.isRequired,
  isPaused: PropTypes.bool.isRequired,
  onPseudoActivateAudioMode: PropTypes.func.isRequired,
  onPseudoDeactivateAudioMode: PropTypes.func.isRequired,
}

PatientStory = enhancePatientStory(PatientStory)

const PatientStories = flow(
  addTranslationHelpers,
  addProps(({keyPrefix, content}) => ({
    content: {
      ...content,
      questionKey: `${keyPrefix}.${content.questionKey}`,
    },
  })),
  ({content: {questionKey, stories}, keyPrefix, t}) => (
    <>
      <h2 css={styles.question}>{t(questionKey)}</h2>
      {stories.map(story => (
        <PatientStory story={story} keyPrefix={keyPrefix} key={story.nameKey} />
      ))}
    </>
  )
)

PatientStories.propTypes = {
  content: PropTypes.shape({
    questionKey: PropTypes.string.isRequired,
    stories: PropTypes.arrayOf(
      PropTypes.shape({
        nameKey: PropTypes.string.isRequired,
        avatar: imageSourcePropType.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

export default PatientStories

export const styles = {
  question: {
    fontSize: 26,
    lineHeight: '36px',
    fontWeight: '600',
    marginTop: 18,
    marginBottom: 27,
  },
  container: {
    [mq.mobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 14,
      paddingBottom: 10,
      flexDirection: 'column',
    },
    [mq.mobileMax]: {
      paddingTop: 0,
      paddingBottom: 0,
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 113,
    },
    [mq.tablet]: {
      paddingLeft: 24,
      paddingRight: 31,
    },
    display: 'flex',
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.blueBorder,
    boxShadow: shadows.card,
    marginTop: 6,
    marginBottom: 14,
  },
  avatar: {
    width: 59,
    marginRight: 19,
  },
  leftContainer: {
    [mq.mobile]: {
      marginBottom: 17,
    },
    [mq.mobileMax]: {
      marginBottom: 0,
      marginRight: 'auto',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: 26,
    lineHeight: '36px',
  },
  listenButtonContainer: {
    [mq.mobileMax]: {
      marginLeft: 24,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 11,
    paddingRight: 21,
    minHeight: 48,
    backgroundColor: colors.blue,
    borderRadius: 36,
  },
  listenButtonContainerLightbox: {
    [mq.mobileMax]: {
      marginLeft: 0,
    },
    marginBottom: 30,
  },
  listenButtonIcon: {
    marginRight: 13,
  },
  pauseButtonIcon: {
    marginRight: 13,
  },
  listenButtonLabel: {
    fontSize: 22,
    lineHeight: '32px',
    fontWeight: '500',
    color: colors.white,
    position: 'relative',
    top: 1,
  },
  pauseButtonLabel: {
    fontSize: 22,
    lineHeight: '32px',
    fontWeight: '500',
    color: colors.white,
    position: 'relative',
    top: 1,
  },
  storyText: {
    fontSize: 22,
    lineHeight: '31px',
    maxWidth: 426,
  },
  lightbox: {
    maxWidth: 501,
  },
  lightboxBody: {
    paddingLeft: 26,
    paddingRight: 22,
    paddingBottom: 16,
    alignItems: 'flex-start',
  },
  nameAndAvatarLightbox: {
    [mq.mobile]: {
      marginBottom: 30,
    },
  },
  closeButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    marginTop: 64,
  },
  closeButton: {
    minHeight: 56,
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 35,
    paddingRight: 35,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.offWhiteBorder,
    fontSize: 22,
    lineHeight: '32px',
    fontWeight: '500',
    flexShrink: 0,
  },
}
