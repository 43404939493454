import React from 'react'
import BaseModal from 'react-bootstrap/Modal'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import addEffectOnPropChange from 'util/addEffectOnPropChange'
import AudioSection from 'components/AudioSection'
import withAudioModeSectionRestoration from 'hocs/withAudioModeSectionRestoration'

const enhance = withAudioModeSectionRestoration

const Modal = flow(
  addEffectOnPropChange(
    'show',
    ({show, onAudioModeStart, onAudioModeRestore}, {show: previousShow}) => {
      if (show && !previousShow) {
        onAudioModeStart()
      } else if (!show && previousShow) {
        onAudioModeRestore()
      }
    }
  ),
  ({audioSectionId, onAudioModeStart, onAudioModeRestore, ...props}) => {
    const modal = <BaseModal {...props} />
    if (!audioSectionId) return modal
    return <AudioSection id={audioSectionId}>{modal}</AudioSection>
  }
)

Modal.propTypes = {
  ...BaseModal.propTypes,
  onAudioModeStart: PropTypes.func.isRequired,
  onAudioModeRestore: PropTypes.func.isRequired,
  audioSectionId: PropTypes.string,
}

Modal.Body = BaseModal.Body

export default enhance(Modal)
