export default [
  {
    key: 'whyDangerous',
    answers: ['canLeadToHeartAttack', 'canLeadToStroke', 'infectious'],
    correctAnswerIndex: 1,
  },
  {
    key: 'bloodThinner',
    answers: ['makesThick', 'cures', 'protects'],
    correctAnswerIndex: 2,
  },
  {
    key: 'changeMind',
    answers: ['no', 'yes'],
    correctAnswerIndex: 1,
    questionRendersMultilineOnIpadInEnglish: true,
  },
]
