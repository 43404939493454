import {flow} from 'lodash/fp'
import {upperFirst} from 'lodash'
import {addState, addCallback} from 'ad-hok'

const addCallbackRefAndNode = flow(
  (refPropName, nodePropName) => ({
    refPropName,
    nodePropName,
    setNodePropName: `set${upperFirst(nodePropName)}`,
  }),
  ({refPropName, nodePropName, setNodePropName}) =>
    flow(
      addState(nodePropName, setNodePropName),
      addCallback(
        refPropName,
        ({[setNodePropName]: setNode}) => node => {
          setNode(node)
        },
        []
      )
    )
)

export default addCallbackRefAndNode
