import React from 'react'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'
import {range, isString} from 'lodash'
import {addStateHandlers, addProps, addRef, flowMax} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import checkIcon from 'assets/images/check.svg'
import costIcon from 'assets/images/dollar-sign.svg'
import plusIcon from 'assets/images/plus.svg'
import colors from 'style/colors'
import {classNamePropType} from 'util/propTypes'
import ClinicianReversalAgentsTooltip, {
  reversalAgentTranslationKeys,
} from 'components/ClinicianReversalAgentsTooltip'
import ClinicianNav from 'components/ClinicianNav'
import {BreakpointSwitch, mq} from 'style/mediaQueries'
import zIndices from 'style/zIndices'

const thinnersData = [
  {
    genericName: 'warfarin',
    brandName: 'Coumadin',
    bloodTests: true,
    drugInteractions: true,
    modifyDiet: true,
    cost: 1,
    reversalAgents: 3,
  },
  {
    genericName: 'rivaroxaban',
    brandName: 'Xarelto',
    cost: 4,
    reversalAgents: 1,
  },
  {genericName: 'dabigatran', brandName: 'Pradaxa', cost: 4, reversalAgents: 2},
  {genericName: 'apixaban', brandName: 'Eliquis', cost: 4, reversalAgents: 1},
  {genericName: 'edoxaban', brandName: 'Savaysa', cost: 4, reversalAgents: 1},
]

const addTranslatedKey = (propName = 'text') =>
  flow(
    addTranslationHelpers,
    addProps(({translationKey, t}) => ({
      [propName]: t(`clinicianBloodThinnerTablePage.columns.${translationKey}`),
    }))
  )

const MobileCell = flowMax(
  addTranslatedKey('keyText'),
  addProps(({value}) => ({
    value: isString(value) ? (
      <span css={styles.mobileCellValue}>{value}</span>
    ) : (
      value
    ),
  })),
  ({keyText, value}) => (
    <div css={styles.mobileCellContainer}>
      <span css={styles.mobileCellKey}>{keyText}</span>
      {value}
    </div>
  )
)

MobileCell.propTypes = {
  translationKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

const getYesNoText = ({value, t}) =>
  t(`clinicianBloodThinnerTablePage.${value ? 'yes' : 'no'}`)

const MobileRow = flow(
  addTranslationHelpers,
  ({
    genericName,
    brandName,
    bloodTests,
    drugInteractions,
    modifyDiet,
    cost,
    reversalAgents,
    t,
  }) => (
    <div css={styles.mobileRowContainer}>
      <h2 css={styles.mobileRowHeader}>
        {genericName} ({brandName})
      </h2>
      <MobileCell
        translationKey="bloodTests"
        value={getYesNoText({value: bloodTests, t})}
      />
      <MobileCell
        translationKey="drugInteractions"
        value={getYesNoText({value: drugInteractions, t})}
      />
      <MobileCell
        translationKey="modifyDiet"
        value={getYesNoText({value: modifyDiet, t})}
      />
      <MobileCell translationKey="cost" value={<Cost amount={cost} />} />
      {reversalAgents && (
        <span css={styles.mobileReversalAgents}>
          {t(
            `clinicianBloodThinnerTablePage.reversalAgentsMobile.${
              reversalAgentTranslationKeys[reversalAgents]
            }`
          )}
        </span>
      )}
    </div>
  )
)

MobileRow.propTypes = {
  genericName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  bloodTests: PropTypes.bool,
  drugInteractions: PropTypes.bool,
  modifyDiet: PropTypes.bool,
  cost: PropTypes.number.isRequired,
  reversalAgents: PropTypes.number,
}

const MobileTable = () => (
  <>
    {thinnersData.map(thinnerData => (
      <MobileRow {...thinnerData} key={thinnerData.genericName} />
    ))}
  </>
)

const Cost = flow(
  addTranslationHelpers,
  ({amount, t}) => (
    <div
      css={styles.costIconContainer}
      aria-label={t('clinicianBloodThinnerTablePage.cost', {amount, max: 4})}
    >
      {range(amount).map(index => (
        <img src={costIcon} css={styles.costIcon} alt="" key={index} />
      ))}
    </div>
  )
)

Cost.propTypes = {
  amount: PropTypes.number.isRequired,
}

const ReversalAgents = flow(
  addTranslationHelpers,
  ({amount, t}) => (
    <div
      css={styles.reversalIconContainer}
      aria-label={t(
        `clinicianBloodThinnerTablePage.reversalAgentsMobile.${
          reversalAgentTranslationKeys[amount]
        }`
      )}
    >
      {range(amount).map(index => (
        <img src={plusIcon} css={styles.reversalIcon} alt="" key={index} />
      ))}
    </div>
  )
)

ReversalAgents.propTypes = {
  amount: PropTypes.number.isRequired,
}

const CheckIcon = flow(
  addTranslationHelpers,
  ({t, show}) => (
    <span
      aria-label={t(`clinicianBloodThinnerTablePage.${show ? 'yes' : 'no'}`)}
    >
      {show && <img src={checkIcon} alt="" />}
    </span>
  )
)

CheckIcon.propTypes = {
  show: PropTypes.bool,
}

const TableRow = ({
  genericName,
  brandName,
  bloodTests,
  drugInteractions,
  modifyDiet,
  cost,
  reversalAgents,
}) => (
  <tr>
    <td css={[styles.leftAlign, styles.genericName]}>{genericName}</td>
    <td css={[styles.leftAlign, styles.brandName]}>{brandName}</td>
    <td>
      <CheckIcon show={bloodTests} />
    </td>
    <td>
      <CheckIcon show={drugInteractions} />
    </td>
    <td>
      <CheckIcon show={modifyDiet} />
    </td>
    <td>
      <Cost amount={cost} />
    </td>
    <td>{reversalAgents && <ReversalAgents amount={reversalAgents} />}</td>
  </tr>
)

TableRow.propTypes = MobileRow.propTypes

const ColumnHeader = flowMax(
  addTranslatedKey(),
  addRef('containerRef'),
  ({className, text, children, containerRef}) => (
    <th className={className} ref={containerRef} scope="col">
      {children ? (
        children({text, columnHeaderRef: containerRef})
      ) : (
        <span>{text}</span>
      )}
    </th>
  )
)

ColumnHeader.propTypes = {
  className: classNamePropType,
  translationKey: PropTypes.string.isRequired,
  children: PropTypes.func,
}

const Table = flow(
  addStateHandlers(
    {isReversalAgentsTooltipOpen: false},
    {
      onOpenReversalAgentsTooltip: () => () => ({
        isReversalAgentsTooltipOpen: true,
      }),
      onCloseReversalAgentsTooltip: () => () => ({
        isReversalAgentsTooltipOpen: false,
      }),
    }
  ),
  addRef('containerRef'),
  ({
    isReversalAgentsTooltipOpen,
    onOpenReversalAgentsTooltip,
    onCloseReversalAgentsTooltip,
    containerRef,
  }) => (
    <div>
      <table css={styles.table}>
        <thead>
          <tr css={styles.headerRow}>
            <ColumnHeader
              css={styles.leftAlignHeader}
              translationKey="genericName"
            />
            <ColumnHeader
              css={styles.leftAlignHeader}
              translationKey="brandName"
            />
            <ColumnHeader translationKey="bloodTests" />
            <ColumnHeader translationKey="drugInteractions" />
            <ColumnHeader translationKey="modifyDiet" />
            <ColumnHeader translationKey="cost" />
            <ColumnHeader translationKey="reversalAgents">
              {({text, columnHeaderRef}) => (
                <span>
                  {text}{' '}
                  <ClinicianReversalAgentsTooltip
                    isOpen={isReversalAgentsTooltipOpen}
                    onOpen={onOpenReversalAgentsTooltip}
                    onClose={onCloseReversalAgentsTooltip}
                    columnHeaderRef={columnHeaderRef}
                    containerRef={containerRef}
                  />
                </span>
              )}
            </ColumnHeader>
          </tr>
        </thead>
        <tbody css={styles.tbody}>
          {thinnersData.map(thinnerData => (
            <TableRow {...thinnerData} key={thinnerData.genericName} />
          ))}
        </tbody>
      </table>
      <div
        ref={containerRef}
        css={styles.tooltipOverlayContainer}
        aria-live="assertive"
      />
    </div>
  )
)

export const ClinicianBloodThinnerResponsiveTable = () => (
  <BreakpointSwitch mobile={<MobileTable />} desktop={<Table />} />
)

const ClinicianBloodThinnerTable = flow(
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      footer={<ClinicianNav currentPage="bloodThinnerTable" />}
      name="Blood thinner comparison table"
    >
      <h1 css={styles.header}>{t('clinicianBloodThinnerTablePage.header')}</h1>
      <ClinicianBloodThinnerResponsiveTable />
    </Page>
  )
)

export default ClinicianBloodThinnerTable

const styles = {
  container: {
    [mq.mobile]: {
      paddingLeft: 21,
      paddingRight: 21,
    },
    [mq.tablet]: {
      paddingLeft: 48,
      paddingRight: 37,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 93,
  },
  header: {
    fontSize: 26,
    lineHeight: 'normal',
    fontWeight: 500,
    marginBottom: 32,
  },
  tbody: {
    '& tr': {
      height: 96,
      borderBottomColor: colors.black,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    '& td': {
      paddingLeft: 14,
      paddingRight: 18,
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  },
  leftAlign: {
    textAlign: 'left !important',
  },
  leftAlignHeader: {
    '& span': {
      left: '0 !important',
    },
  },
  genericName: {
    fontSize: 26,
    fontWeight: 500,
  },
  brandName: {
    fontSize: 26,
    fontWeight: 700,
  },
  headerRow: {
    backgroundColor: colors.greyDeselectedBackground,
    borderBottomColor: colors.black,
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    '& th': {
      fontSize: 18,
      lineHeight: 'normal',
      fontWeight: 500,
      paddingTop: 16,
      paddingBottom: 18,
      paddingLeft: 14,
      paddingRight: 14,
      textAlign: 'left',
      '&:last-child': {
        paddingLeft: 5,
      },
      '& span': {
        position: 'relative',
        left: 10,
      },
    },
  },
  costIconContainer: {
    marginLeft: -6,
    whiteSpace: 'nowrap',
    position: 'relative',
    top: 3,
  },
  costIcon: {
    marginLeft: 6,
  },
  reversalIconContainer: {
    marginLeft: -5,
    whiteSpace: 'nowrap',
    position: 'relative',
    top: 3,
  },
  reversalIcon: {
    marginLeft: 5,
  },
  mobileRowContainer: {
    width: '100%',
    maxWidth: 430,
    paddingBottom: 6,
    marginBottom: 36,
    borderBottomColor: colors.midGrey,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mobileRowHeader: {
    fontSize: 32,
    lineHeight: '44px',
    fontWeight: 700,
    marginBottom: 28,
  },
  mobileCellContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 24,
    lineHeight: '36px',
    marginBottom: 12,
  },
  mobileCellValue: {
    fontWeight: '700',
  },
  mobileReversalAgents: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: '600',
    marginBottom: 12,
    marginTop: -1,
  },
  mobileCellKey: {
    marginRight: 24,
  },
  tooltipOverlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  table: {
    position: 'relative',
    zIndex: zIndices.clinicianComparisonTable,
  },
}
