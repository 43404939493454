import React from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'

import Button from 'components/Button'
import {classNamePropType} from 'util/propTypes'
import closeButtonIcon from 'assets/images/close-menu.svg'
import {addTranslationHelpers} from 'util/i18n'

const CloseButton = flow(
  addTranslationHelpers,
  ({onClick, className, t}) => (
    <Button
      mode="plain"
      onClick={onClick}
      className={className}
      css={styles.container}
    >
      <img src={closeButtonIcon} alt={t('buttons.close')} />
    </Button>
  )
)

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: classNamePropType,
}

export default CloseButton

const styles = {
  container: {
    width: 44,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
