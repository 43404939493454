export const playAudioSection = (id, {storePrevious} = {}) => ({
  type: 'PLAY_AUDIO_SECTION',
  id,
  storePrevious,
})

export const finishedPlayingAudioClip = () => ({
  type: 'FINISHED_PLAYING_AUDIO_CLIP',
})

export const toggleAudioMode = () => ({
  type: 'TOGGLE_AUDIO_MODE',
})

export const reset = () => ({
  type: 'RESET',
})

export const restorePreviousAudioSection = () => ({
  type: 'RESTORE_PREVIOUS_AUDIO_SECTION',
})

export const pseudoActivateAudioMode = () => ({
  type: 'PSEUDO_ACTIVATE_AUDIO_MODE',
})

export const pseudoDeactivateAudioMode = () => ({
  type: 'PSEUDO_DEACTIVATE_AUDIO_MODE',
})

export const pauseAudio = () => ({
  type: 'PAUSE_AUDIO',
})

export const unpauseAudio = () => ({
  type: 'UNPAUSE_AUDIO',
})

export const completedSection = sectionNumber => ({
  type: 'COMPLETED_SECTION',
  sectionNumber,
})

export const setLanguage = language => ({
  type: 'SET_LANGUAGE',
  language,
})

export const setSubtitlesLanguage = language => ({
  type: 'SET_SUBTITLES_LANGUAGE',
  language,
})

export const completedQuiz = () => ({
  type: 'COMPLETED_QUIZ',
})
