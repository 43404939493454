import React, {forwardRef} from 'react'
import {Link as RouterLink} from '@reach/router'
import PropTypes from 'prop-types'
import {addProps, flowMax} from 'ad-hok'

import {childrenPropType} from 'util/propTypes'
import {addTrackingOnClick} from 'util/tracking'

const LinkWithRef = flowMax(
  addProps(({mode = 'normal'}) => ({
    css: [styles.overrides, styles[mode]],
  })),
  addTrackingOnClick(({trackAs}) => trackAs),
  ({external, mode, css, to, forwardedRef, trackAs, ...props}) =>
    external ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...props}
        css={css}
        ref={forwardedRef}
      />
    ) : (
      <RouterLink {...props} css={css} to={to} ref={forwardedRef} />
    )
)

const Link = forwardRef((initialProps, ref) => (
  <LinkWithRef {...initialProps} forwardedRef={ref} />
))

/* eslint-disable react/no-unused-prop-types */
Link.propTypes = {
  external: PropTypes.bool,
  children: childrenPropType,
  mode: PropTypes.oneOf(['normal', 'plain']),
  trackAs: PropTypes.string,
}
/* eslint-enable react/no-unused-prop-types */

export default Link

const styles = {
  overrides: {
    color: 'inherit',
  },
  /* eslint-disable styles-object/no-unused-styles */
  plain: {},
  normal: {
    textDecoration: 'underline',
  },
  /* eslint-enable styles-object/no-unused-styles */
}
