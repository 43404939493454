import React from 'react'
import {flow} from 'lodash/fp'

import {addTranslationHelpers} from 'util/i18n'
import {pageContentId} from 'components/Page'
import {a11yStyles} from 'util/a11y'
import zIndices from 'style/zIndices'
import {height as titleBarHeight} from 'components/TitleBar'
import colors from 'style/colors'

const SkipLink = flow(
  addTranslationHelpers,
  ({t}) => (
    <a href={`#${pageContentId}`} css={styles.skipLink}>
      {t('general.skipLink')}
    </a>
  )
)

export default SkipLink

const styles = {
  skipLink: {
    ...a11yStyles.visuallyHidden,
    ':focus': {
      ...a11yStyles.notVisuallyHidden,
      position: 'absolute',
      top: 0,
      left: 8,
      zIndex: zIndices.skipLink,
      height: titleBarHeight,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
      paddingLeft: 24,
      paddingRight: 24,
      color: colors.black,
    },
  },
}
