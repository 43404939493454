import React from 'react'
import PropTypes from 'prop-types'

import {
  childrenPropType,
  cssPropType,
  classNamePropType,
  refPropType,
} from 'util/propTypes'
import CloseButton from 'components/CloseButton'
import Modal from 'components/Modal'
import zIndices from 'style/zIndices'

const Lightbox = ({
  onHide,
  children,
  bodyCss,
  className,
  showCloseButton = true,
  childPortalTargetRef,
  ...props
}) => (
  <Modal
    onHide={onHide}
    centered
    size="lg"
    dialogClassName={className}
    {...props}
  >
    <Modal.Body
      css={[styles.modalBodyContainer, bodyCss]}
      ref={childPortalTargetRef}
    >
      {showCloseButton && (
        <CloseButton onClick={onHide} css={styles.closeButton} />
      )}
      {children}
    </Modal.Body>
  </Modal>
)

Lightbox.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: childrenPropType,
  bodyCss: cssPropType,
  className: classNamePropType,
  showCloseButton: PropTypes.bool,
  audioSectionId: PropTypes.string,
  childPortalTargetRef: refPropType,
}

export default Lightbox

const styles = {
  modalBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 35,
    paddingBottom: 22,
  },
  closeButton: {
    position: 'absolute',
    top: 2,
    right: 4,
    zIndex: zIndices.lightboxCloseButton,
  },
}
