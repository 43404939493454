import React from 'react'
import posed, {PoseGroup} from 'react-pose'
import {Router, Location} from '@reach/router'

// https://popmotion.io/pose/learn/route-transitions-reach-router/
const RouteContainer = posed.div({
  enter: {},
  exit: {},
})

export const getLocationKey = ({location: {pathname} = {}}) =>
  // if (/^\/intro\/key-messages/.test(pathname)) return '/intro/key-messages'
  pathname

const PosedRouter = ({children, ...props}) => (
  <Location>
    {({location}) => (
      <PoseGroup>
        <RouteContainer key={getLocationKey({location})} css={styles.container}>
          <Router {...props} location={location}>
            {children}
          </Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
)

PosedRouter.propTypes = Router.propTypes

export default PosedRouter

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}
