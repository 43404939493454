import React, {createContext} from 'react'
import PropTypes from 'prop-types'
import {addContext} from 'ad-hok'
import {flow} from 'lodash/fp'

import {childrenPropType} from 'util/propTypes'

const AudioSectionContext = createContext()

export const addAudioSectionId = flow(
  addContext(AudioSectionContext, 'audioSectionContext'),
  ({
    audioSectionContext: {
      id: audioSectionId,
      highlightScrollOffset: audioSectionHighlightScrollOffset,
    },
    ...props
  }) => ({
    ...props,
    audioSectionId,
    audioSectionHighlightScrollOffset,
  })
)

const AudioSection = ({id, children, highlightScrollOffset}) => (
  <AudioSectionContext.Provider value={{id, highlightScrollOffset}}>
    {children}
  </AudioSectionContext.Provider>
)

AudioSection.propTypes = {
  id: PropTypes.string.isRequired,
  children: childrenPropType.isRequired,
  highlightScrollOffset: PropTypes.number,
}

export default AudioSection
