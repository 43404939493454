import React from 'react'
import PropTypes from 'prop-types'
import {flow, map as fmap} from 'lodash/fp'
import {addProps, flowMax, addPropTypes} from 'ad-hok'
import color from 'color'
import {keyframes} from '@emotion/core'
import {connect} from 'react-redux'
import {max} from 'lodash'

import colors from 'style/colors'
import {fmapWithKey, feachWithKey} from 'util/fp'
import {addTranslationHelpers, addCurrentLanguage} from 'util/i18n'
import Button, {styles as buttonStyles} from 'components/Button'
import Page from 'components/Page'
import {isClinicalSetting} from 'util/environment'
import BackButton from 'components/BackButton'
import shadows from 'style/shadows'
import rightCaretIcon from 'assets/images/right-caret-green.svg'
import {
  mq,
  BreakpointSwitch,
  addResponsiveBreakpointProps,
} from 'style/mediaQueries'
import zIndices from 'style/zIndices'
import JourneyHillside from 'components/JourneyHillside'
import AudioHighlight from 'components/AudioHighlight'
import {progressSelector} from 'redux-local/selectors'
import {completedSection} from 'redux-local/actions'
import addEffectOnMount from 'util/addEffectOnMount'
import {addTrackingProps} from 'util/tracking'

const getStepButtonTestId = ({disabled, isMenu, index, current}) => {
  if (isMenu) return `menu-start-${disabled ? 'disabled-' : ''}${index + 1}`
  return `steps-${current}-start-${disabled ? 'disabled-' : ''}${index + 1}`
}

const RightCaret = () => (
  <img src={rightCaretIcon} alt="" css={styles.rightArrow} />
)

const StepMarker = flowMax(
  addTranslationHelpers,
  addProps(({isCurrent, isMenu, isCompletedOrNext}) => ({
    disabled: !isMenu && !(isCurrent || isCompletedOrNext),
  })),
  addCurrentLanguage,
  ({
    labelKey,
    isCurrent,
    index,
    t,
    linkTo,
    isMenu,
    disabled,
    onMenuClose,
    isSpanishSelected,
    current,
  }) => (
    <div
      css={[
        styles.stepOuterContainer,
        !isMenu && isCurrent && styles.stepOuterContainerCurrent,
        !isMenu &&
          !isCurrent &&
          !disabled &&
          styles.stepOuterContainerMenuNonCurrent,
        isMenu && !disabled && styles.stepOuterContainerMenuNonCurrent,
        styles[`stepOuterContainer${index}`],
      ]}
    >
      <Button
        mode="plain"
        css={[
          styles.stepContainer,
          isCurrent && styles.stepContainerCurrent,
          !isMenu &&
            !isCurrent &&
            !disabled &&
            styles.stepContainerMenuNonCurrent,
          isMenu &&
            !isCurrent &&
            !disabled &&
            styles.stepContainerMenuNonCurrent,
        ]}
        linkTo={linkTo}
        data-testid={getStepButtonTestId({disabled, index, isMenu, current})}
        disabled={disabled}
        onClick={onMenuClose}
      >
        <span>
          <AudioHighlight clipKey={labelKey} dark={disabled}>
            {index + 1}
          </AudioHighlight>
        </span>
        <span css={styles.stepLabel}>
          <AudioHighlight clipKey={labelKey} dark={disabled}>
            {t(`stepsPage.${labelKey}`)}
          </AudioHighlight>
        </span>
        {!isMenu && isCurrent && (
          <BreakpointSwitch
            mobile={<RightCaret />}
            mobileMax={
              <em
                css={[
                  buttonStyles.button,
                  styles.button,
                  isSpanishSelected && styles.buttonSpanish,
                ]}
              >
                <AudioHighlight clipKey="start" dark>
                  {t('buttons.start')}
                </AudioHighlight>
              </em>
            }
          />
        )}
        {isMenu && !disabled && (
          <BreakpointSwitch mobile={<RightCaret />} desktop={null} />
        )}
      </Button>
    </div>
  )
)

StepMarker.propTypes = {
  labelKey: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  linkTo: PropTypes.string,
  isMenu: PropTypes.bool,
  onMenuClose: PropTypes.func,
  isCompletedOrNext: PropTypes.bool,
  current: PropTypes.number.isRequired,
}

const steps = [
  {labelKey: 'intro', linkTo: '/intro/video'},
  {labelKey: 'faq', linkTo: '/common-questions/intro'},
  {labelKey: 'quiz', linkTo: '/quiz/intro'},
  {
    labelKey: 'wrapUp',
    linkTo: isClinicalSetting() ? '/wrap-up/done' : '/wrap-up/worksheet',
  },
]

const enhanceStepMarkers = connect(progressSelector)

let StepMarkers = ({
  current,
  isMenu,
  onMenuClose,
  furthestCompletedSectionNumber,
}) => (
  <nav css={styles.stepsContainer}>
    {flow(
      () => steps,
      fmapWithKey((step, index) => ({
        ...step,
        isCurrent: current === index + 1,
        index,
      })),
      fmap(step => (
        <StepMarker
          {...step}
          key={step.labelKey}
          isMenu={isMenu}
          onMenuClose={onMenuClose}
          isCompletedOrNext={
            max([current, furthestCompletedSectionNumber + 1]) >= step.index + 1
          }
          current={current}
        />
      ))
    )()}
  </nav>
)

StepMarkers.propTypes = {
  current: PropTypes.number,
  isMenu: PropTypes.bool,
  onMenuClose: PropTypes.func,
  furthestCompletedSectionNumber: PropTypes.number.isRequired,
}

StepMarkers = enhanceStepMarkers(StepMarkers)

const getCurrentForMenu = () => {
  const {pathname} = window.location
  if (/^\/quiz/.test(pathname)) return 2
  if (/^\/common-questions/.test(pathname)) return 3
  if (/^\/wrap-up/.test(pathname)) return 4
  const stepsMatch = /^\/steps\/(\d+)/.exec(pathname)
  if (stepsMatch) return Number(stepsMatch[1])
  return 1
}

const getAudioSectionId = ({isMenu, isBelowMobileMax, current}) => {
  if (isMenu) return null
  if (isBelowMobileMax) return `stepsPageMobile${current}`
  return `stepsPage${current}`
}

const enhance = connect(
  null,
  dispatch => ({
    recordPreviousSectionCompleted: previousSectionNumber => {
      dispatch(completedSection(previousSectionNumber))
    },
  })
)

const Steps = flowMax(
  addProps(({current, isMenu}) => ({
    current: isMenu ? getCurrentForMenu() : Number(current),
  })),
  addPropTypes({
    current: PropTypes.number.isRequired,
  }),
  addEffectOnMount(
    ({isMenu, current, recordPreviousSectionCompleted}) => () => {
      if (isMenu) return

      recordPreviousSectionCompleted(current - 1)
    }
  ),
  addResponsiveBreakpointProps('mobileMax'),
  addTrackingProps(({current, isMenu}) =>
    isMenu
      ? {}
      : {
          'Current step number': current,
        }
  ),
  ({current, isMenu, onMenuClose, isBelowMobileMax}) => (
    <Page
      css={styles.container}
      containerCss={styles.outerContainer}
      backgroundColor={colors.white}
      background="linear-gradient(0deg, rgba(193, 228, 248, 0.3) 25.3%, rgba(148, 217, 255, 0.3) 46.6%, rgba(55, 194, 241, 0.3) 94.16%)"
      footer={<JourneyHillside current={current} isMenu={isMenu} />}
      MainTag={isMenu ? 'section' : undefined}
      audioSectionId={getAudioSectionId({isMenu, isBelowMobileMax, current})}
      name={isMenu ? null : 'Journey'}
    >
      {!isMenu && <BackButton css={styles.backButton} />}
      {isMenu && (
        <h1 css={styles.menuLabel}>
          <AudioHighlight clipKey="menu" />
        </h1>
      )}
      <StepMarkers
        current={current}
        isMenu={isMenu}
        onMenuClose={onMenuClose}
      />
    </Page>
  )
)

Steps.propTypes = {
  isMenu: PropTypes.bool,
  onMenuClose: PropTypes.func,
  recordPreviousSectionCompleted: PropTypes.func,
}

export default enhance(Steps)

const stepGap = 40

const blinkBorder = keyframes({
  '0%, 64%, 100%': {
    borderColor: 'rgba(255, 255, 255, 1)',
  },
  '82%': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
})

const getGradient = firstColor =>
  flow(
    () => color(firstColor).fade(0.5),
    semiTransparentColor =>
      `linear-gradient(135deg, ${semiTransparentColor} 0%, ${semiTransparentColor} 50%, transparent 50%)`
  )()

const styles = {
  container: {
    [mq.mobile]: {
      paddingTop: 174,
    },
    [mq.tablet]: {
      paddingTop: 126,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  outerContainer: {
    position: 'relative',
  },
  stepsContainer: {
    display: 'flex',
    [mq.mobile]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: 129,
    },
    [mq.desktop]: {
      flexDirection: 'row',
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      position: 'absolute',
      bottom: 74,
      left: 0,
      right: 0,
    },
    marginLeft: -stepGap,
    zIndex: zIndices.journeySteps,
  },
  stepOuterContainer: mq({
    borderRadius: {
      mobile: 32,
      desktop: 106,
    },
    borderWidth: {
      mobile: 2,
      desktop: 6,
    },
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch', // TODO: this should be the default, reset browser rule?
    marginLeft: stepGap,
    marginBottom: {
      mobile: 18,
      desktop: 0,
    },
    position: {
      desktop: 'relative',
    },
  }),
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    background: colors.mutedGreen,
    fontSize: 26,
    lineHeight: 'normal',
    fontWeight: 600,
    color: colors.white,
    [mq.mobile]: {
      borderRadius: 30,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingLeft: 28,
      paddingRight: 28,
      height: 85,
    },
    [mq.mobileMax]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
    [mq.desktop]: {
      width: 200,
      height: 200,
      borderRadius: 100,
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 12,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepContainerCurrent: mq({
    color: colors.forestGreen,
    backgroundColor: colors.brighterYellow,
    backgroundImage: getGradient('#fff656'),
    paddingBottom: {
      desktop: 0,
    },
    '&:hover, &:active': {
      backgroundColor: '#f5bf00',
      backgroundImage: getGradient('#ffd84f'),
    },
  }),
  stepContainerMenuNonCurrent: mq({
    color: colors.forestGreen,
    backgroundColor: colors.mustardYellow,
    '&:hover, &:active': {
      backgroundColor: colors.brighterYellow,
    },
  }),
  stepLabel: mq({
    textAlign: {
      mobile: 'left',
      desktop: 'center',
    },
    paddingLeft: {
      mobile: 30,
      desktop: 18,
    },
    paddingRight: 18,
  }),
  button: mq({
    backgroundColor: colors.forestGreen,
    minHeight: 39,
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: {
      desktop: 14,
    },
    marginLeft: {
      mobile: 'auto',
      desktop: 0,
    },
    paddingLeft: 21,
    paddingRight: 21,
  }),
  buttonSpanish: {
    [mq.desktop]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  stepOuterContainerCurrent: {
    boxShadow: shadows.stepButton,
    borderColor: colors.white,
    animation: `${blinkBorder} 3s 2 ease-in-out`,
  },
  stepOuterContainerMenuNonCurrent: {
    boxShadow: shadows.stepButton,
    borderColor: colors.white,
  },
  rightArrow: {
    marginLeft: 'auto',
  },
  backButton: {
    zIndex: zIndices.journeyBackButton,
  },
  menuLabel: {
    position: 'absolute',
    color: colors.forestGreen,
    fontWeight: 700,
    zIndex: zIndices.journeyMenu,
    [mq.mobile]: {
      top: 22,
      left: 27,
      fontSize: 35,
      lineHeight: '47px',
    },
    [mq.tablet]: {
      top: 41,
    },
    [mq.desktop]: {
      top: 57,
      left: 50,
      fontSize: 75,
      lineHeight: '105px',
    },
  },
}

const stepButtonOffsets = [112, 104, 35, 55]

feachWithKey((offset, index) => {
  styles[`stepOuterContainer${index}`] = mq({
    bottom: {
      desktop: offset,
    },
  })
})(stepButtonOffsets)
