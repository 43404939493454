import React from 'react'
import {flow} from 'lodash/fp'
import {keyframes} from '@emotion/core'
import {addProps, flowMax} from 'ad-hok'
import {ceil} from 'lodash'

import largeCloudImage from 'assets/images/journey-cloud-large.svg'
import smallCloudImage from 'assets/images/journey-cloud-small.svg'
import {BreakpointSwitch} from 'style/mediaQueries'
import addWindowSize from 'util/addWindowSize'

const initialLeftPositions = ['40%', '65%', '15%', '85%', '23%']

const animations = [
  keyframes({
    '0%, 100%': {
      left: initialLeftPositions[0],
    },
    '30%, 35%': {
      left: '70%',
    },
    '65%': {
      left: '100%',
      opacity: 1,
    },
    '65.1%': {
      left: '100%',
      opacity: 0,
    },
    '65.2%': {
      left: '-20%',
      opacity: 0,
    },
    '65.3%': {
      left: '-20%',
      opacity: 1,
    },
  }),
  keyframes({
    '0%, 100%': {
      left: initialLeftPositions[1],
    },
    '20%': {
      left: '100%',
      opacity: 1,
    },
    '20.1%': {
      left: '100%',
      opacity: 0,
    },
    '20.2%': {
      left: '-20%',
      opacity: 0,
    },
    '20.3%': {
      left: '-20%',
      opacity: 1,
    },
    '60%': {
      left: '25%',
    },
    '66%': {
      left: '28%',
    },
  }),
  keyframes({
    '0%, 100%': {
      left: initialLeftPositions[2],
    },
    '5%': {
      left: '17%',
    },
    '22%': {
      left: '20%',
    },
    '76%': {
      left: '100%',
      opacity: 1,
    },
    '76.1%': {
      left: '100%',
      opacity: 0,
    },
    '76.2%': {
      left: '-20%',
      opacity: 0,
    },
    '76.3%': {
      left: '-20%',
      opacity: 1,
    },
  }),
  keyframes({
    '0%, 100%': {
      left: initialLeftPositions[3],
    },
    '15%': {
      left: '100%',
      opacity: 1,
    },
    '15.1%': {
      left: '100%',
      opacity: 0,
    },
    '15.2%': {
      left: '-20%',
      opacity: 0,
    },
    '15.3%': {
      left: '-20%',
      opacity: 1,
    },
    '60%': {
      left: '75%',
    },
  }),
  keyframes({
    '0%, 100%': {
      left: initialLeftPositions[4],
    },
    '10%': {
      left: '26%',
    },
    '45%': {
      left: '88%',
    },
    '63%': {
      left: '100%',
      opacity: 1,
    },
    '63.1%': {
      left: '100%',
      opacity: 0,
    },
    '63.2%': {
      left: '-20%',
      opacity: 0,
    },
    '63.3%': {
      left: '-20%',
      opacity: 1,
    },
  }),
]

const getAnimation = flow(
  addProps(({innerWidth}) => ({duration: 60 * (innerWidth / 1024)})),
  ({duration, index}) =>
    `${animations[index]} ${duration}s ${ceil(120 / duration)} linear`
)

const JourneyClouds = flowMax(
  addWindowSize,
  addProps(({innerWidth}) => ({
    dynamicStyles: {
      firstCloud: {
        animation: getAnimation({innerWidth, index: 0}),
        left: initialLeftPositions[0],
      },
      secondCloud: {
        animation: getAnimation({innerWidth, index: 1}),
        left: initialLeftPositions[1],
      },
      thirdCloud: {
        animation: getAnimation({innerWidth, index: 2}),
        left: initialLeftPositions[2],
      },
      fourthCloud: {
        animation: getAnimation({innerWidth, index: 3}),
        left: initialLeftPositions[3],
      },
      fifthCloud: {
        animation: getAnimation({innerWidth, index: 4}),
        left: initialLeftPositions[4],
      },
    },
  })),
  ({dynamicStyles}) => (
    <BreakpointSwitch
      desktop={
        <>
          <img
            src={largeCloudImage}
            css={[styles.cloud, styles.firstCloud, dynamicStyles.firstCloud]}
            alt=""
          />
          <img
            src={smallCloudImage}
            css={[styles.cloud, styles.secondCloud, dynamicStyles.secondCloud]}
            alt=""
          />
          <img
            src={smallCloudImage}
            css={[styles.cloud, styles.thirdCloud, dynamicStyles.thirdCloud]}
            alt=""
          />
          <img
            src={largeCloudImage}
            css={[styles.cloud, styles.fourthCloud, dynamicStyles.fourthCloud]}
            alt=""
          />
          <img
            src={largeCloudImage}
            css={[styles.cloud, styles.fifthCloud, dynamicStyles.fifthCloud]}
            alt=""
          />
        </>
      }
    />
  )
)

export default JourneyClouds

export const journeyHillHeight = 442

const styles = {
  cloud: {
    position: 'absolute',
  },
  firstCloud: {
    bottom: journeyHillHeight + 5,
  },
  secondCloud: {
    bottom: journeyHillHeight + 66,
  },
  thirdCloud: {
    bottom: journeyHillHeight + 66,
  },
  fourthCloud: {
    bottom: journeyHillHeight + 186,
  },
  fifthCloud: {
    bottom: journeyHillHeight + 186,
  },
}
