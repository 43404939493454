import React from 'react'
import ReactDOM from 'react-dom'

import App from 'App'
import ManageScroll from 'components/ManageScroll'
import ErrorPage from 'components/ErrorPage'
import {ErrorBoundary} from 'util/bugsnag'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <ManageScroll />
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
