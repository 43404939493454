import React from 'react'

import {classNamePropType} from 'util/propTypes'

const FactorsName = ({className}) => (
  <span className={className}>
    CHA<sub>2</sub>DS<sub>2</sub>-VASc
  </span>
)

FactorsName.propTypes = {
  className: classNamePropType,
}

export default FactorsName
