import React from 'react'
import {flowMax} from 'ad-hok'

import {addTranslationHelpers} from 'util/i18n'
import Button, {
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import colors from 'style/colors'
import Page from 'components/Page'
import cloudIllustration from 'assets/images/clinician-cover-cloud.svg'
import hillIllustration from 'assets/images/clinician-cover-hillside.svg'
import {classNamePropType} from 'util/propTypes'
import HillsideFooter from 'components/HillsideFooter'
import {mq} from 'style/mediaQueries'
import {precacheAllClinicianAssets, addPrecaching} from 'util/serviceWorker'

const Cloud = ({className}) => (
  <img
    className={className}
    css={styles.cloud}
    src={cloudIllustration}
    alt=""
  />
)

Cloud.propTypes = {
  className: classNamePropType,
}

const ClinicianCover = flowMax(
  addPrecaching(precacheAllClinicianAssets),
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      background="linear-gradient(0deg, #F8E6C1 0%, #FFDB94 22.5%, #F8BC46 59.75%), #FABC46"
      footer={
        <HillsideFooter
          hillContainerCss={styles.hillContainer}
          hillsideContainerCss={styles.hillsideContainer}
          hillsideOuterContainerCss={styles.hillsideOuterContainer}
          hardyCss={styles.hardy}
        />
      }
      name="Cover"
    >
      <div css={styles.contentContainer}>
        <h1 css={styles.header}>{t('clinicianCoverPage.header')}</h1>
        <Cloud css={styles.firstCloud} />
        <Cloud css={styles.secondCloud} />
        <p css={styles.understand}>{t('clinicianCoverPage.understand')}</p>
        <Button
          title={t('clinicianCoverPage.button')}
          linkTo="/clinician/home"
          css={styles.button}
        />
        <p css={styles.subparagraph}>{t('clinicianCoverPage.disclaimer')}</p>
        <p css={styles.subparagraph}>{t('clinicianCoverPage.supported')}</p>
      </div>
    </Page>
  )
)

export default ClinicianCover

const styles = {
  container: {
    [mq.mobile]: {
      paddingTop: 90,
    },
    [mq.tablet]: {
      paddingTop: 133,
    },
    [mq.desktop]: {
      marginBottom: -20,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    [mq.mobile]: {
      alignItems: 'flex-start',
      marginLeft: 28,
      marginRight: 28,
    },
    [mq.tablet]: {
      alignItems: 'center',
      marginLeft: 40,
      marginRight: 40,
    },
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 950,
    flexGrow: 1,
    position: 'relative',
  },
  header: {
    fontSize: 36,
    lineHeight: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  button: {
    [mq.mobile]: {
      ...mobileButtonResponsiveStyles,
      marginBottom: 48,
      minWidth: 162,
    },
    [mq.tablet]: {
      ...normalButtonResponsiveStyles,
      marginBottom: 80,
      minWidth: 212,
    },
    color: colors.white,
    fontWeight: 300,
    backgroundColor: colors.darkBlue,
  },
  understand: {
    [mq.mobile]: {
      fontSize: 24,
      lineHeight: '33px',
    },
    [mq.tablet]: {
      fontSize: 36,
      lineHeight: '50px',
      textAlign: 'center',
    },
    fontWeight: 400,
    marginTop: '0.5em',
    marginBottom: '1em',
  },
  subparagraph: {
    [mq.tablet]: {
      marginLeft: -24,
      textAlign: 'center',
    },
    fontSize: 18,
    fontWeight: 300,
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, 0.7)',
    marginBottom: '1.5em',
    '&:last-child': {
      marginBottom: 0,
    },
    maxWidth: 650,
  },
  cloud: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.desktop]: {
      display: 'flex',
    },
    position: 'absolute',
  },
  firstCloud: {
    top: 241,
    left: -52,
  },
  secondCloud: {
    top: 355,
    right: -40,
  },
  hillContainer: {
    backgroundImage: `url(${hillIllustration})`,
  },
  hillsideContainer: {
    [mq.mobile]: {
      bottom: -380,
    },
  },
  hillsideOuterContainer: {
    [mq.mobile]: {
      minHeight: 220,
    },
    [mq.tablet]: {
      minHeight: 260,
    },
    [mq.desktop]: {
      minHeight: 220,
    },
  },
  hardy: {
    [mq.desktop]: {
      bottom: 420,
      left: '10%',
    },
  },
}
