import React from 'react'
import {connect} from 'react-redux'
import {flow} from 'lodash/fp'
import PropTypes from 'prop-types'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import {mq} from 'style/mediaQueries'
import activeIcon from 'assets/images/audio-mode-active.svg'
import inactiveIcon from 'assets/images/audio-mode-inactive.svg'
import {audioSelector} from 'redux-local/selectors'
import {toggleAudioMode} from 'redux-local/actions'
import {classNamePropType} from 'util/propTypes'

const enhance = connect(
  flow(
    audioSelector,
    ({isAudioModeActive}) => ({isActive: isAudioModeActive})
  ),
  dispatch => ({
    onClick: () => {
      dispatch(toggleAudioMode())
    },
  })
)

const AudioModeToggle = flow(
  addTranslationHelpers,
  ({onClick, isActive, t, className}) => (
    <Button
      mode="plain"
      className={className}
      css={styles.container}
      onClick={onClick}
    >
      <img
        src={isActive ? activeIcon : inactiveIcon}
        css={styles.icon}
        alt=""
      />
      <span css={styles.label}>
        {t(`audioMode.${isActive ? 'stop' : 'start'}`)}
      </span>
    </Button>
  )
)

AudioModeToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  className: classNamePropType,
}

export default enhance(AudioModeToggle)

const styles = {
  container: {
    [mq.tablet]: {
      marginLeft: 'auto',
      marginRight: 32,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 7,
  },
  label: {
    fontSize: 18,
    lineHeight: 'normal',
  },
}
