import {flowMax, addPropTypes, addHandlers} from 'ad-hok'
import PropTypes from 'prop-types'

import addRedux from 'util/addRedux'
import {reset} from 'redux-local/actions'
import {resetTracking} from 'util/tracking'

const addResetApp = flowMax(
  addRedux(null, dispatch => ({
    resetApp: () => {
      dispatch(reset())
    },
  })),
  addPropTypes({
    resetApp: PropTypes.func.isRequired,
  }),
  addHandlers({
    resetApp: ({resetApp}) => () => {
      resetApp()
      resetTracking()
    },
  })
)

export default addResetApp
