import React from 'react'
import i18n from 'i18next'
import {flow} from 'lodash/fp'
import {Trans} from 'react-i18next'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import watchmanImage400 from 'assets/images/clinician-watchman-device-400w.jpg'
import watchmanImage800 from 'assets/images/clinician-watchman-device-800w.jpg'
import watchmanImage1194 from 'assets/images/clinician-watchman-device-1194w.jpg'
import watchmanImage2388 from 'assets/images/clinician-watchman-device-2388w.jpg'
import watchmanImage3582 from 'assets/images/clinician-watchman-device-3582w.jpg'
import watchmanImage400Webp from 'assets/images/clinician-watchman-device-400w.webp'
import watchmanImage800Webp from 'assets/images/clinician-watchman-device-800w.webp'
import watchmanImage1194Webp from 'assets/images/clinician-watchman-device-1194w.webp'
import watchmanImage2388Webp from 'assets/images/clinician-watchman-device-2388w.webp'
import watchmanImage3582Webp from 'assets/images/clinician-watchman-device-3582w.webp'
import Link from 'components/Link'
import {mq} from 'style/mediaQueries'
import ClinicianNav from 'components/ClinicianNav'
import {getImgPropsFromSizes} from 'util/responsive'
import Image from 'components/Image'

const ClinicianWatchman = flow(
  addTranslationHelpers,
  ({t}) => (
    <Page
      css={styles.container}
      footer={<ClinicianNav currentPage="watchman" />}
      name="Watchman"
    >
      <h1 css={styles.header}>{t('clinicianWatchmanPage.header')}</h1>
      <figure css={styles.figure}>
        <picture>
          <source
            type="image/webp"
            {...getImgPropsFromSizes({
              sizes: '(min-width: 1440px) 1440px, 100vw',
              srcSet: {
                400: watchmanImage400Webp,
                800: watchmanImage800Webp,
                1194: watchmanImage1194Webp,
                2388: watchmanImage2388Webp,
                3582: watchmanImage3582Webp,
              },
            })}
          />
          <Image
            {...getImgPropsFromSizes({
              sizes: '(min-width: 1440px) 1440px, 100vw',
              srcSet: {
                400: watchmanImage400,
                800: watchmanImage800,
                1194: watchmanImage1194,
                2388: watchmanImage2388,
                3582: watchmanImage3582,
              },
            })}
            alt="Illustration of Watchman device implanted in heart"
            css={styles.image}
            aspectRatio={3582 / 1722}
          />
        </picture>
        <figcaption css={styles.caption}>
          <p css={styles.captionTitle}>
            {t('clinicianWatchmanPage.deviceName')}
          </p>
          <p css={styles.seeMore}>
            <Trans i18nKey="clinicianWatchmanPage.seeMoreLink" i18n={i18n}>
              <Link to="https://www.watchman.com" external>
                link
              </Link>
            </Trans>
          </p>
        </figcaption>
      </figure>
    </Page>
  )
)

export default ClinicianWatchman

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 90,
  },
  figure: {
    display: 'flex',
    flexDirection: 'column',
  },
  caption: mq({
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
    paddingLeft: {mobile: 20, tablet: 50},
    paddingRight: {mobile: 20, tablet: 50},
    marginTop: 20,
  }),
  image: {
    width: '100%',
  },
  header: mq({
    paddingLeft: {mobile: 20, tablet: 50},
    paddingRight: {mobile: 20, tablet: 50},
    fontSize: 26,
    lineHeight: 'normal',
    fontWeight: 500,
    marginBottom: 40,
  }),
  captionTitle: {
    fontWeight: 600,
  },
  seeMore: {
    lineHeight: 1.2,
    marginTop: 5,
  },
}
