export default {
  dialogReset: {
    maxWidth: 'none',
    margin: 0,
    '& .modal-content': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: 0,
      backgroundColor: 'transparent',
    },
  },
}
