import React from 'react'

import PosedRouter from 'components/PosedRouter'
import ClinicianHome from 'pages/ClinicianHome'
import ClinicianStrokeRiskFactors from 'pages/ClinicianStrokeRiskFactors'
import ClinicianWatchman from 'pages/ClinicianWatchman'
import ClinicianStrokeRiskPercentages from 'pages/ClinicianStrokeRiskPercentages'
import ClinicianMedicationGraphic from 'pages/ClinicianMedicationGraphic'
import ClinicianBloodThinnerTable from 'pages/ClinicianBloodThinnerTable'
import ClinicianRhythm from 'pages/ClinicianRhythm'
import ClinicianLeadsToStroke from 'pages/ClinicianLeadsToStroke'
import ClinicianBalancing from 'pages/ClinicianBalancing'
import ClinicianCover from 'pages/ClinicianCover'
import NotFound from 'pages/NotFound'

const ClinicianRoutes = () => (
  <PosedRouter css={styles.wrapper}>
    <ClinicianCover path="/" />
    <ClinicianCover path="clinician" />
    <ClinicianHome path="home" />
    <ClinicianHome path="clinician/home" />
    <ClinicianRhythm path="rhythm" />
    <ClinicianRhythm path="clinician/rhythm" />
    <ClinicianLeadsToStroke path="leads-to-stroke" />
    <ClinicianLeadsToStroke path="clinician/leads-to-stroke" />
    <ClinicianStrokeRiskFactors path="stroke-risk-factors" />
    <ClinicianStrokeRiskFactors path="clinician/stroke-risk-factors" />
    <ClinicianStrokeRiskPercentages path="stroke-risk-percentages" />
    <ClinicianStrokeRiskPercentages path="clinician/stroke-risk-percentages" />
    <ClinicianBalancing path="balancing" />
    <ClinicianBalancing path="clinician/balancing" />
    <ClinicianMedicationGraphic path="blood-thinner-types" />
    <ClinicianMedicationGraphic path="clinician/blood-thinner-types" />
    <ClinicianBloodThinnerTable path="blood-thinner-comparison" />
    <ClinicianBloodThinnerTable path="clinician/blood-thinner-comparison" />
    <ClinicianWatchman path="watchman" />
    <ClinicianWatchman path="clinician/watchman" />
    <NotFound default homeUrl="/clinician" />
  </PosedRouter>
)

export default ClinicianRoutes

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}
