import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import {BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE} from 'config/bugsnag'
import {childrenPropType} from 'util/propTypes'

// eslint-disable-next-line import/prefer-default-export
export const bugsnagClient = BUGSNAG_API_KEY
  ? bugsnag({
      apiKey: BUGSNAG_API_KEY,
      collectUserIp: false,
      releaseStage: BUGSNAG_RELEASE_STAGE || 'development',
    })
  : null

bugsnagClient && bugsnagClient.use(bugsnagReact, React)

const DummyErrorBoundary = ({children}) => children

DummyErrorBoundary.propTypes = {
  children: childrenPropType.isRequired,
}

export const ErrorBoundary = bugsnagClient
  ? bugsnagClient.getPlugin('react')
  : DummyErrorBoundary

export const bugsnagNotify = error => {
  bugsnagClient && bugsnagClient.notify(error)
}
