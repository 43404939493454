import React from 'react'

import colors from 'style/colors'
import {childrenPropType, classNamePropType} from 'util/propTypes'
import shadows from 'style/shadows'
import {maxWidth} from 'style/layout'
import {mq} from 'style/mediaQueries'
import zIndices from 'style/zIndices'

const TitleBar = ({children, className}) => (
  <header className={className} css={styles.container}>
    <div css={styles.contentContainer}>{children}</div>
  </header>
)

export default TitleBar

TitleBar.propTypes = {
  children: childrenPropType.isRequired,
  className: classNamePropType,
}

export const height = 50

const styles = {
  container: mq({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.white,
    boxShadow: shadows.titleBar,
    paddingLeft: {mobile: 20, tablet: 50},
    paddingRight: {mobile: 20, tablet: 50},
    color: colors.black,
    fontSize: 18,
    zIndex: zIndices.titleBar,
  }),
  contentContainer: {
    display: 'flex',
    maxWidth,
    height,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}
