import React from 'react'
import {addProps, addHandlers, addStateHandlers, flowMax} from 'ad-hok'
import PropTypes from 'prop-types'
import {flow, sum as fsum, map as fmap, get as fget} from 'lodash/fp'
import {map, includes, without, find} from 'lodash'
import {Trans} from 'react-i18next'
import i18n from 'i18next'
import qs from 'qs'
import posed from 'react-pose'
import {Waypoint} from 'react-waypoint'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import Button from 'components/Button'
import colors from 'style/colors'
import {addQueryParams, updateQueryString} from 'util/queryString'
import {cssPropType, classNamePropType} from 'util/propTypes'
import rightCaretIcon from 'assets/images/right-caret-white.svg'
import caretUpIcon from 'assets/images/caret-up-white.svg'
import caretDownIcon from 'assets/images/caret-down-white.svg'
import Factors, {isFactorSelected} from 'components/RiskCalculatorFactors'
import ClinicianNav from 'components/ClinicianNav'
import {mq} from 'style/mediaQueries'
import {height as titleBarHeight} from 'components/TitleBar'
import zIndices from 'style/zIndices'
import PoseGroup from 'components/PoseGroup'
import shadows from 'style/shadows'
import {a11yStyles} from 'util/a11y'
import {addTrackingToHandler} from 'util/tracking'
import FullWidthContainer, {
  MaxWidthContainer,
} from 'components/FullWidthContainer'

const ResetButton = flow(
  addTranslationHelpers,
  ({onClick, t, className, isScrolledPastHeader}) => (
    <Button
      mode="plain"
      className={className}
      css={styles.resetButton}
      onClick={onClick}
      title={t('buttons.reset')}
      disabled={isScrolledPastHeader}
    />
  )
)

ResetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: classNamePropType,
  isScrolledPastHeader: PropTypes.bool,
}

const ReferenceToggle = ({isOpen, onToggle, label, isScrolledPastHeader}) => (
  <Button
    slim
    onClick={onToggle}
    css={styles.referenceButton}
    disabled={isScrolledPastHeader}
  >
    <img
      src={isOpen ? caretUpIcon : caretDownIcon}
      alt="Toggle reference"
      css={
        isOpen
          ? styles.referenceButtonIconOpen
          : styles.referenceButtonIconClosed
      }
    />
    <span css={styles.referenceButtonLabel}>{label}</span>
  </Button>
)

ReferenceToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isScrolledPastHeader: PropTypes.bool.isRequired,
}

const addFactorSelection = flowMax(
  addQueryParams(['selectedFactors']),
  addProps(({selectedFactors = []}) => ({selectedFactors})),
  addHandlers({
    toggleFactor: ({selectedFactors}) => factor => () => {
      updateQueryString({
        selectedFactors: isFactorSelected(selectedFactors)(factor)
          ? without(selectedFactors, factor.key)
          : [...selectedFactors, factor.key],
      })
    },
    onReset: () => () => {
      updateQueryString({selectedFactors: []})
    },
  }),
  addProps(({selectedFactors, factors}) => ({
    score: flow(
      () => selectedFactors,
      fmap(
        flow(
          key => find(factors, {key}),
          fget('points')
        )
      ),
      fsum
    )(),
  }))
)

const addDynamicStyles = flow(
  addProps(({factorGaps}) => {
    const dynamicStyles = {}

    map(factorGaps, ({standard, byColumn = {}}, rowNum) => {
      dynamicStyles[`row${rowNum}`] = {
        marginLeft: -standard,
      }
      dynamicStyles[`factorContainerRow${rowNum}`] = {
        [mq.tablet]: {
          marginLeft: standard,
        },
      }
      map(byColumn, ({gap, top}, columnNum) => {
        const columnStyles = {}
        if (gap) {
          columnStyles.marginLeft = gap
        }
        if (top) {
          columnStyles.top = top
        }
        dynamicStyles[
          `factorContainerRow${rowNum}Column${columnNum}`
        ] = columnStyles
      })
    })
    return {dynamicStyles}
  })
)

const addReferenceToggling = flowMax(
  addStateHandlers(
    {isReferenceOpen: false},
    {
      onReferenceToggle: ({isReferenceOpen}) => () => ({
        isReferenceOpen: !isReferenceOpen,
      }),
    }
  ),
  addTrackingToHandler('onReferenceToggle', ({isReferenceOpen}) =>
    !isReferenceOpen ? 'View bleeding risk factors calculator reference' : null
  )
)

const Score = flow(
  addTranslationHelpers,
  ({score, translationKey, className, scoreCss, t}) => (
    <h3 className={className} css={styles.scoreContainer} aria-live="assertive">
      <span
        css={a11yStyles.visuallyHidden}
        aria-label={t('clinicianCalculator.totalScore')}
      />
      <Trans
        i18nKey={`${translationKey}.scoreHeader`}
        i18n={i18n}
        count={score}
      >
        <em
          css={[styles.score, scoreCss]}
          data-testid={`${translationKey}-score`}
        >
          {{score}}
        </em>
      </Trans>
    </h3>
  )
)

Score.propTypes = {
  score: PropTypes.number.isRequired,
  translationKey: PropTypes.string.isRequired,
  className: classNamePropType,
  scoreCss: cssPropType,
}

const SeeRiskButton = flow(
  addTranslationHelpers,
  ({linkTo, translationKey, t, className, isScrolledPastHeader}) => (
    <Button
      slim
      linkTo={linkTo}
      className={className}
      css={styles.detailsButton}
      disabled={isScrolledPastHeader}
    >
      <span css={styles.detailsButtonText}>
        {t(`${translationKey}.detailsButton`)}
      </span>
      <img src={rightCaretIcon} alt="Right arrow" />
    </Button>
  )
)

SeeRiskButton.propTypes = {
  linkTo: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  className: classNamePropType,
  isScrolledPastHeader: PropTypes.bool,
}

const StickyHeaderContainer = posed.div({
  preEnterStickyHeader: {opacity: 0, y: -20},
  enterStickyHeader: {
    opacity: 1,
    y: 0,
    transition: {ease: 'easeOut', duration: 240},
  },
  exitStickyHeader: {opacity: 0, y: -20},
})

const StickyHeader = ({
  score,
  translationKey,
  detailsLinkTo,
  show,
  onReset,
}) => (
  <PoseGroup suffix="stickyHeader">
    {show && (
      <StickyHeaderContainer
        css={styles.stickyHeaderContainer}
        key="stickyHeader"
      >
        <div css={styles.stickyHeaderLeftContainer}>
          <Score
            score={score}
            translationKey={translationKey}
            css={styles.scoreContainerStickyHeader}
            scoreCss={styles.scoreStickyHeader}
          />
          <ResetButton
            onClick={onReset}
            css={[
              styles.resetButtonStickyHeader,
              !!detailsLinkTo && styles.resetButtonStickyHeaderDetailsLink,
            ]}
          />
        </div>
        {detailsLinkTo && (
          <SeeRiskButton
            linkTo={detailsLinkTo}
            translationKey={translationKey}
            css={styles.detailsButtonStickyHeader}
          />
        )}
      </StickyHeaderContainer>
    )}
  </PoseGroup>
)

StickyHeader.propTypes = {
  score: PropTypes.number.isRequired,
  translationKey: PropTypes.string.isRequired,
  detailsLinkTo: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
}

const RiskCalculatorPage = flowMax(
  addTranslationHelpers,
  addFactorSelection,
  addDynamicStyles,
  addProps(({largeRows}) => ({
    isLargeRow: rowNum => includes(largeRows, rowNum),
  })),
  addReferenceToggling,
  addProps(({detailsLinkTo, score}) => ({
    detailsLinkTo: detailsLinkTo && `${detailsLinkTo}?${qs.stringify({score})}`,
  })),
  addStateHandlers(
    {isScrolledPastHeader: true},
    {
      onScrollPastHeader: () => () => ({isScrolledPastHeader: true}),
      onScrollBackIntoHeader: () => () => ({isScrolledPastHeader: false}),
    }
  ),
  ({
    t,
    selectedFactors,
    score,
    toggleFactor,
    translationKey,
    factors,
    dynamicStyles,
    isLargeRow,
    detailsLinkTo,
    headerRightColumnCss,
    contentContainerCss,
    headerCss,
    containerCss,
    headerText,
    onReset,
    reference,
    isReferenceOpen,
    onReferenceToggle,
    pageKey,
    isScrolledPastHeader,
    onScrollPastHeader,
    onScrollBackIntoHeader,
    name,
  }) => (
    <Page
      css={[styles.container, containerCss]}
      background={colors.lightishGreyBackground}
      footer={<ClinicianNav css={styles.bottomNav} currentPage={pageKey} />}
      name={name}
      containerCss={styles.pageContainer}
    >
      <FullWidthContainer css={styles.headerBarFullWidthContainer}>
        <MaxWidthContainer css={[styles.headerBarContainer, headerCss]}>
          <div css={styles.headerLeftColumnContainer}>
            <h1 css={styles.header}>
              {headerText || t(`${translationKey}.header`)}
            </h1>
            <div css={styles.subheaderRowContainer}>
              <h2 css={styles.subheader}>
                {t(`${translationKey}.scorePlaceholder`)}
              </h2>
              {!!reference && (
                <ReferenceToggle
                  isOpen={isReferenceOpen}
                  onToggle={onReferenceToggle}
                  label={t('buttons.reference')}
                  isScrolledPastHeader={isScrolledPastHeader}
                />
              )}
            </div>
            <ResetButton
              onClick={onReset}
              isScrolledPastHeader={isScrolledPastHeader}
            />
          </div>
          <div css={[styles.headerRightColumnContainer, headerRightColumnCss]}>
            <Score score={score} translationKey={translationKey} />
            {detailsLinkTo && (
              <SeeRiskButton
                linkTo={detailsLinkTo}
                translationKey={translationKey}
                isScrolledPastHeader={isScrolledPastHeader}
              />
            )}
          </div>
          <div css={styles.headerWaypoint}>
            <Waypoint
              onLeave={onScrollPastHeader}
              onEnter={onScrollBackIntoHeader}
              topOffset={titleBarHeight}
              scrollableAncestor="window"
            />
          </div>
        </MaxWidthContainer>
      </FullWidthContainer>
      <StickyHeader
        score={score}
        translationKey={translationKey}
        detailsLinkTo={detailsLinkTo}
        show={isScrolledPastHeader}
        onReset={onReset}
      />
      {isReferenceOpen && reference}
      <div css={[styles.contentContainer, contentContainerCss]}>
        <Factors
          selected={selectedFactors}
          toggleFactor={toggleFactor}
          factors={factors}
          dynamicStyles={dynamicStyles}
          isLargeRow={isLargeRow}
          translationKey={translationKey}
        />
      </div>
    </Page>
  )
)

RiskCalculatorPage.propTypes = {
  factors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      row: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  largeRows: PropTypes.arrayOf(PropTypes.number),
  factorGaps: PropTypes.object.isRequired,
  translationKey: PropTypes.string.isRequired,
  detailsLinkTo: PropTypes.string,
  headerRightColumnCss: cssPropType,
  contentContainerCss: cssPropType,
  headerCss: cssPropType,
  containerCss: cssPropType,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  reference: PropTypes.node,
  pageKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default RiskCalculatorPage

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    [mq.mobile]: {
      paddingTop: 14,
      paddingLeft: 12,
      paddingRight: 12,
    },
    [mq.tablet]: {
      paddingTop: 29,
      paddingLeft: 40,
      paddingRight: 40,
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 1100,
    width: '100%',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    [mq.mobile]: {
      marginBottom: 1,
    },
    [mq.tablet]: {
      marginBottom: 17,
    },
    fontSize: 26,
    lineHeight: '38px',
    fontWeight: 500,
  },
  subheader: {
    fontSize: 19,
    lineHeight: '32px',
    marginRight: 13,
  },
  headerBarFullWidthContainer: {
    backgroundColor: colors.darkPurpleBackground,
  },
  headerBarContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
      paddingLeft: 21,
      paddingRight: 21,
      paddingBottom: 17,
    },
    [mq.tablet]: {
      flexDirection: 'row',
      paddingLeft: 49,
      paddingRight: 31,
      paddingBottom: 33,
    },
    display: 'flex',
    paddingTop: 49,
    color: colors.white,
    justifyContent: 'space-between',
    position: 'relative',
  },
  headerLeftColumnContainer: {
    [mq.mobile]: {
      marginTop: 16,
    },
    [mq.tablet]: {
      marginTop: 25,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  headerRightColumnContainer: {
    [mq.mobile]: {
      marginTop: 2,
    },
    [mq.tablet]: {
      marginTop: 14,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  scoreContainer: {
    fontSize: 26,
    lineHeight: '44px',
    [mq.mobile]: {
      textAlign: 'left',
    },
    [mq.tablet]: {
      textAlign: 'right',
    },
    marginBottom: 8,
    marginRight: 3,
  },
  score: {
    fontSize: 36,
    lineHeight: '61px',
    marginRight: 3,
  },
  detailsButton: {
    [mq.mobile]: {
      alignSelf: 'flex-start',
    },
    [mq.tablet]: {
      alignSelf: 'flex-end',
    },
    backgroundColor: colors.blueButtonBackground,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.blueButtonBorder,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 18,
    paddingLeft: 26,
  },
  detailsButtonText: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
    color: colors.white,
    marginRight: 17,
  },
  resetButton: {
    color: colors.white,
    fontSize: 18,
    lineHeight: 'normal',
    textDecoration: 'underline',
    alignSelf: 'flex-start',
  },
  subheaderRowContainer: {
    [mq.mobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 18,
    },
    [mq.tablet]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 0,
    },
    display: 'flex',
  },
  referenceButton: {
    backgroundColor: colors.blueButtonBackground,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.blueButtonBorder,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 27,
    paddingLeft: 18,
    fontSize: 18,
    lineHeight: '26px',
    color: colors.white,
    fontWeight: 400,
  },
  referenceButtonLabel: {
    marginLeft: 19,
  },
  referenceButtonIconOpen: {
    position: 'relative',
    top: -1,
  },
  referenceButtonIconClosed: {
    position: 'relative',
    top: 2,
  },
  headerWaypoint: {
    position: 'absolute',
    bottom: 55,
    height: 0,
  },
  stickyHeaderContainer: {
    position: 'fixed',
    top: titleBarHeight,
    left: 0,
    right: 0,
    backgroundColor: colors.darkPurpleBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    [mq.mobile]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [mq.tablet]: {
      paddingLeft: 49,
      paddingRight: 31,
    },
    color: colors.white,
    zIndex: zIndices.clinicianCalculatorPageStickyHeader,
    boxShadow: shadows.card,
  },
  scoreContainerStickyHeader: {
    [mq.mobile]: {
      fontSize: 18,
      lineHeight: '26px',
      marginRight: 18,
    },
    [mq.mobileMax]: {
      fontSize: 26,
      lineHeight: '44px',
      marginRight: 36,
    },
    marginBottom: 0,
  },
  resetButtonStickyHeader: {
    alignSelf: 'auto',
  },
  resetButtonStickyHeaderDetailsLink: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.mobileMax]: {
      display: 'flex',
    },
  },
  stickyHeaderLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  scoreStickyHeader: {
    [mq.mobile]: {
      fontSize: 24,
      lineHeight: '32px',
    },
    [mq.mobileMax]: {
      fontSize: 36,
      lineHeight: '61px',
    },
  },
  detailsButtonStickyHeader: {
    [mq.mobile]: {
      alignSelf: 'auto',
    },
  },
  bottomNav: {
    color: colors.greyDeselectedText,
  },
  pageContainer: {
    overflowX: 'hidden',
  },
}
