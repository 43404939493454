import React from 'react'

import PosedRouter from 'components/PosedRouter'
import Welcome from 'pages/Welcome'
import Steps from 'pages/Steps'
import IntroReview from 'pages/IntroReview'
import QuizIntro from 'pages/QuizIntro'
import QuizQuestions from 'pages/QuizQuestions'
import FaqIntro from 'pages/FaqIntro'
import FaqQuestions from 'pages/FaqQuestions'
import WrapUpDone from 'pages/WrapUpDone'
import IntroKeyMessages from 'pages/IntroKeyMessages'
import FaqQuestion from 'pages/FaqQuestion'
import IntroVideo from 'pages/IntroVideo'
import WrapUpWorksheet from 'pages/WrapUpWorksheet'
import NotFound from 'pages/NotFound'

const Routes = () => (
  <PosedRouter css={styles.wrapper}>
    <Welcome path="/" />
    <Steps path="steps/:current" />
    <IntroVideo path="intro/video" />
    <IntroReview path="intro/review" />
    <IntroKeyMessages path="intro/key-messages/:number" />
    <QuizIntro path="quiz/intro" />
    <QuizQuestions path="quiz/questions/:number" />
    <FaqIntro path="common-questions/intro" />
    <FaqQuestions path="common-questions/all" />
    <FaqQuestion path="common-questions/:number" />
    <WrapUpWorksheet path="wrap-up/worksheet" />
    <WrapUpDone path="wrap-up/done" />
    <NotFound default />
  </PosedRouter>
)

export default Routes

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}
