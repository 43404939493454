import {combineReducers} from 'redux'
import {max} from 'lodash'
import {getUserLanguage} from '../util/i18n'

const audioInitialState = {
  isAudioModeActive: false,
  sectionId: null,
  clipIndex: null,
  previousSectionId: null,
  previousClipIndex: null,
  isAudioModePseudoActive: false,
  isPaused: false,
}

const audioReducer = (
  state = audioInitialState,
  {type, id, storePrevious = true}
) => {
  switch (type) {
    case 'PLAY_AUDIO_SECTION':
      return {
        ...state,
        sectionId: id,
        clipIndex: 0,
        previousSectionId: storePrevious
          ? state.sectionId
          : state.previousSectionId,
        previousClipIndex: storePrevious
          ? state.clipIndex
          : state.previousClipIndex,
      }
    case 'RESTORE_PREVIOUS_AUDIO_SECTION':
      return {
        ...state,
        sectionId: state.previousSectionId,
        clipIndex: state.previousClipIndex,
      }
    case 'FINISHED_PLAYING_AUDIO_CLIP':
      return {...state, clipIndex: state.clipIndex + 1}
    case 'TOGGLE_AUDIO_MODE':
      return {
        ...state,
        isAudioModeActive: !state.isAudioModeActive,
        clipIndex: 0,
        previousClipIndex: state.previousClipIndex != null ? 0 : null,
      }
    case 'RESET':
      return {
        ...state,
        ...audioInitialState,
      }
    case 'PSEUDO_ACTIVATE_AUDIO_MODE':
      return {
        ...state,
        isAudioModePseudoActive: true,
      }
    case 'PSEUDO_DEACTIVATE_AUDIO_MODE':
      return {
        ...state,
        isAudioModePseudoActive: false,
      }
    case 'PAUSE_AUDIO':
      return {
        ...state,
        isPaused: true,
      }
    case 'UNPAUSE_AUDIO':
      return {
        ...state,
        isPaused: false,
      }
    default:
      return state
  }
}

const progressInitialState = {
  furthestCompletedSectionNumber: 0,
}

const progressReducer = (
  state = progressInitialState,
  {type, sectionNumber}
) => {
  switch (type) {
    case 'COMPLETED_SECTION':
      return {
        ...state,
        furthestCompletedSectionNumber: max([
          state.furthestCompletedSectionNumber,
          sectionNumber,
        ]),
      }
    case 'RESET':
      return {
        ...state,
        ...progressInitialState,
      }
    default:
      return state
  }
}

const languageInitialState = {
  currentLanguage: getUserLanguage(),
}

const languageReducer = (state = languageInitialState, {type, language}) => {
  switch (type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        currentLanguage: language,
      }
    case 'RESET':
      return {
        ...state,
        ...languageInitialState,
      }
    default:
      return state
  }
}

const subtitlesLanguageInitialState = {
  currentSubtitlesLanguage: null,
}

const subtitlesLanguageReducer = (
  state = subtitlesLanguageInitialState,
  {type, language}
) => {
  switch (type) {
    case 'SET_SUBTITLES_LANGUAGE':
      return {
        ...state,
        currentSubtitlesLanguage: language,
      }
    case 'SET_LANGUAGE':
      return {
        ...state,
        currentSubtitlesLanguage: null,
      }
    case 'RESET':
      return {
        ...state,
        ...subtitlesLanguageInitialState,
      }
    default:
      return state
  }
}

const hasCompletedQuizInitialState = {
  hasCompletedQuiz: false,
}

const hasCompletedQuizReducer = (
  state = hasCompletedQuizInitialState,
  {type}
) => {
  switch (type) {
    case 'COMPLETED_QUIZ':
      return {
        ...state,
        hasCompletedQuiz: true,
      }
    case 'RESET':
      return {
        ...state,
        ...hasCompletedQuizInitialState,
      }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  audio: audioReducer,
  progress: progressReducer,
  language: languageReducer,
  subtitlesLanguage: subtitlesLanguageReducer,
  hasCompletedQuiz: hasCompletedQuizReducer,
})

export default rootReducer
