import React from 'react'
import {flowMax, branch, renderNothing, addState} from 'ad-hok'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'

import IntroPage, {AdditionalContentLink} from 'components/IntroPage'
import addRedux from 'util/addRedux'
import {hasCompletedQuizSelector} from 'redux-local/selectors'
import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import introVideoCaptions from 'assets/videos/intro-video-captions-en.vtt'
import introVideoCaptionsEnTraditionalChinese from 'assets/videos/intro-en-zh-Hant.vtt'
import introVideoCaptionsEnSimplifiedChinese from 'assets/videos/intro-en-zh.vtt'
import introVideoCaptionsEnVietnamese from 'assets/videos/intro-en-vi.vtt'
import introVideoCaptionsEnFarsi from 'assets/videos/intro-en-fa.vtt'
import introVideoCaptionsEnTagalog from 'assets/videos/intro-en-tl.vtt'
import introVideoCaptionsEnRussian from 'assets/videos/intro-en-ru.vtt'
import introVideoCaptionsEnPortuguese from 'assets/videos/intro-en-pt.vtt'
import introVideoCaptionsEnKorean from 'assets/videos/intro-en-ko.vtt'
import introVideoCaptionsEnHindi from 'assets/videos/intro-en-hi.vtt'
import introVideoCaptionsEnGerman from 'assets/videos/intro-en-de.vtt'
import introVideoCaptionsEnFrench from 'assets/videos/intro-en-fr.vtt'
import introVideoCaptionsEnArabic from 'assets/videos/intro-en-ar.vtt'
import introVideoCaptionsSpanish from 'assets/videos/intro-video-captions-es.vtt'
import introVideoCaptionsEsTraditionalChinese from 'assets/videos/intro-es-zh-Hant.vtt'
import introVideoCaptionsEsSimplifiedChinese from 'assets/videos/intro-es-zh.vtt'
import introVideoCaptionsEsVietnamese from 'assets/videos/intro-es-vi.vtt'
import introVideoCaptionsEsFarsi from 'assets/videos/intro-es-fa.vtt'
import introVideoCaptionsEsTagalog from 'assets/videos/intro-es-tl.vtt'
import introVideoCaptionsEsRussian from 'assets/videos/intro-es-ru.vtt'
import introVideoCaptionsEsPortuguese from 'assets/videos/intro-es-pt.vtt'
import introVideoCaptionsEsKorean from 'assets/videos/intro-es-ko.vtt'
import introVideoCaptionsEsHindi from 'assets/videos/intro-es-hi.vtt'
import introVideoCaptionsEsGerman from 'assets/videos/intro-es-de.vtt'
import introVideoCaptionsEsFrench from 'assets/videos/intro-es-fr.vtt'
import introVideoCaptionsEsArabic from 'assets/videos/intro-es-ar.vtt'
import introVideoThumbnail from 'assets/images/intro-video-thumbnail.png'
import VideoLightbox from 'components/VideoLightbox'

const SkipQuizLink = flowMax(
  addRedux(hasCompletedQuizSelector),
  branch(({hasCompletedQuiz}) => !hasCompletedQuiz, renderNothing()),
  addTranslationHelpers,
  ({t}) => (
    <Button
      mode="link"
      title={t('quizIntroPage.skip')}
      css={styles.skipButton}
      linkTo="/steps/4"
    />
  )
)

const ReplayIntroVideoLink = ({onClick}) => (
  <AdditionalContentLink clipKey="replayIntroVideo" onClick={onClick} />
)

ReplayIntroVideoLink.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const ReplayIntroVideo = flow(
  addState('showingModal', 'setShowingModal', false),
  ({showingModal, setShowingModal}) => (
    <>
      <ReplayIntroVideoLink onClick={() => setShowingModal(true)} />
      <VideoLightbox
        showingModal={showingModal}
        setShowingModal={setShowingModal}
        videoIdKey="intro"
        videoTestId="quiz-intro-intro-video"
        videoFallbackCacheKey="introVideo"
        videoFallbackCacheCaptionsUrl={{
          en: {
            en: introVideoCaptions,
            ar: introVideoCaptionsEnArabic,
            fr: introVideoCaptionsEnFrench,
            de: introVideoCaptionsEnGerman,
            hi: introVideoCaptionsEnHindi,
            ko: introVideoCaptionsEnKorean,
            pt: introVideoCaptionsEnPortuguese,
            ru: introVideoCaptionsEnRussian,
            tl: introVideoCaptionsEnTagalog,
            fa: introVideoCaptionsEnFarsi,
            vi: introVideoCaptionsEnVietnamese,
            zh: introVideoCaptionsEnSimplifiedChinese,
            'zh-Hant': introVideoCaptionsEnTraditionalChinese,
          },
          es: {
            es: introVideoCaptionsSpanish,
            ar: introVideoCaptionsEsArabic,
            fr: introVideoCaptionsEsFrench,
            de: introVideoCaptionsEsGerman,
            hi: introVideoCaptionsEsHindi,
            ko: introVideoCaptionsEsKorean,
            pt: introVideoCaptionsEsPortuguese,
            ru: introVideoCaptionsEsRussian,
            tl: introVideoCaptionsEsTagalog,
            fa: introVideoCaptionsEsFarsi,
            vi: introVideoCaptionsEsVietnamese,
            zh: introVideoCaptionsEsSimplifiedChinese,
            'zh-Hant': introVideoCaptionsEsTraditionalChinese,
          },
        }}
        videoFallbackCacheThumbnail={introVideoThumbnail}
        closeButtonTestId="faq-answer-modal-video-close-button"
        audioSectionId="quizIntroReplayIntroVideoLightbox"
        videoStartedTrackingEvent="Started rewatching intro video on quiz intro page"
      />
    </>
  )
)

const AdditionalContentAfterButton = () => (
  <>
    <SkipQuizLink />
    <ReplayIntroVideo />
  </>
)

const QuizIntro = props => (
  <IntroPage
    {...props}
    linkTo="/quiz/questions/1"
    translationKey="quizIntroPage"
    questionCss={styles.question}
    containerCss={styles.container}
    audioSectionId="quizIntroPage"
    buttonKey="letsBegin"
    name="Quiz intro"
    additionalContentAfterButton={<AdditionalContentAfterButton />}
  />
)

export default QuizIntro

const styles = {
  container: {
    paddingTop: 167,
  },
  question: {
    marginBottom: 44,
  },
  skipButton: {
    marginTop: 48,
  },
}
