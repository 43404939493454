import React from 'react'
import {Helmet} from 'react-helmet-async'
import {flowMax} from 'ad-hok'

import {addCurrentLanguage} from 'util/i18n'

const HtmlLang = flowMax(addCurrentLanguage, ({currentLanguage}) => (
  <Helmet>
    <html lang={currentLanguage} />
  </Helmet>
))

export default HtmlLang
