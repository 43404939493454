import React from 'react'
import {connect} from 'react-redux'
import {flow} from 'lodash/fp'
import {addProps, addRef, addState, flowMax} from 'ad-hok'
import PropTypes from 'prop-types'
import Scroll from 'react-scroll'
import uuid from 'uuid/v4'

import {audioSelector} from 'redux-local/selectors'
import {childrenPropType} from 'util/propTypes'
import colors from 'style/colors'
import {addAudioSectionId} from 'components/AudioSection'
import {isClipPlaying} from 'components/AudioModePlayer'
import {addTranslationHelpers} from 'util/i18n'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import {height as titleBarHeight} from 'components/TitleBar'

const {scroller} = Scroll

const enhance = connect(audioSelector)

const addDefaultChildren = flow(
  addTranslationHelpers,
  addProps(({children, t, clipKey}) => ({
    children: children || t(clipKey),
  }))
)

const isInView = element => {
  const {top, bottom} = element.getBoundingClientRect()
  const topOffset = titleBarHeight + 7
  const bottomOffset = 10
  if (!(top - topOffset >= 0)) return false
  if (!(bottom + bottomOffset <= window.innerHeight)) return false
  return true
}

const addScrollIntoView = flow(
  addRef('ref'),
  addEffectOnPropChange(
    'isPlaying',
    ({
      isPlaying,
      ref: {current: element},
      audioSectionHighlightScrollOffset = 0,
    }) => {
      if (!isPlaying) return
      if (!element) return
      if (isInView(element)) return
      scroller.scrollTo(element.id, {
        smooth: true,
        duration: distance => 200 + 200 * (Math.abs(distance) / 200),
        offset: -80 + -audioSectionHighlightScrollOffset,
      })
    }
  )
)

const AudioHighlight = flowMax(
  addAudioSectionId,
  addProps(({clipKey, audioSectionId}) => ({
    clipKey: `${audioSectionId}.${clipKey}`,
  })),
  addProps(props => ({
    isPlaying: isClipPlaying(props),
  })),
  addDefaultChildren,
  addScrollIntoView,
  addState('randomId', null, () => uuid()),
  ({isPlaying, children, ref, clipKey, randomId, dark}) => (
    <span
      css={isPlaying && (dark ? styles.highlightedDark : styles.highlighted)}
      ref={ref}
      id={`audio-highlight-${clipKey}-${randomId}`}
    >
      {children}
    </span>
  )
)

AudioHighlight.propTypes = {
  clipKey: PropTypes.string.isRequired,
  children: childrenPropType,
  dark: PropTypes.bool,
}

export default enhance(AudioHighlight)

const styles = {
  highlighted: {
    backgroundColor: colors.highlightYellow,
  },
  highlightedDark: {
    backgroundColor: colors.darkPurpleBackground,
  },
}
