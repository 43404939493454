import React, {Suspense} from 'react'
import {Location} from '@reach/router'
import {createStore} from 'redux'
import {Provider as ReduxProvider} from 'react-redux'
import {HelmetProvider} from 'react-helmet-async'
import amplitude from 'amplitude-js'
import {AmplitudeProvider} from '@amplitude/react-amplitude'
import {flowMax} from 'ad-hok'

import './i18n-init'
import 'config/amplitude'

import 'typeface-montserrat'
import 'typeface-raleway'

import 'style/reset.css'
import 'style/some-bootstrap.css'
import './App.css'
import PatientApp from 'PatientApp'
import ClinicianApp from 'ClinicianApp'
import rootReducer from 'redux-local/rootReducer'
import AudioModePlayer, {addIsAudioModeActive} from 'components/AudioModePlayer'
import DocumentTitle from 'components/DocumentTitle'
import Loading from 'components/Loading'
import WatchLanguage from 'components/WatchLanguage'
import {addCurrentLanguage, getLanguageName} from 'util/i18n'
import {addTrackingUserProps} from 'util/tracking'
import HtmlLang from 'components/HtmlLang'

const TrackGlobalProperties = flowMax(
  addCurrentLanguage,
  addIsAudioModeActive,
  addTrackingUserProps(({currentLanguage, isAudioModeActive}) => ({
    Language: getLanguageName(currentLanguage),
    'Audio mode enabled': isAudioModeActive ? 'yes' : 'no',
  })),
  ({children}) => <>{children}</>
)

const ClinicianOrPatientApp = () => (
  <Location>
    {({location: {pathname, hostname}}) => {
      if (/^\/clinician/.test(pathname) || /clinician\./.test(hostname)) {
        return <ClinicianApp />
      }
      return <PatientApp />
    }}
  </Location>
)

export const store = createStore(
  rootReducer
  // applyMiddleware(createLogger())
)

const App = () => (
  <Suspense fallback={<Loading />}>
    <ReduxProvider store={store}>
      <HelmetProvider>
        <AmplitudeProvider amplitudeInstance={amplitude.getInstance()}>
          <TrackGlobalProperties>
            <div css={styles.container}>
              <ClinicianOrPatientApp />
            </div>
            <AudioModePlayer />
            <WatchLanguage />
            <DocumentTitle />
            <HtmlLang />
          </TrackGlobalProperties>
        </AmplitudeProvider>
      </HelmetProvider>
    </ReduxProvider>
  </Suspense>
)

export default App

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}
