import React from 'react'
import {
  flow,
  get as fget,
  split as fsplit,
  last as flast,
  upperFirst as fupperFirst,
} from 'lodash/fp'
import {
  addStateHandlers,
  addProps,
  addState,
  addHandlers,
  flowMax,
  addRef,
  addEffect,
} from 'ad-hok'
import {Trans} from 'react-i18next'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import {navigate} from '@reach/router'
import posed, {PoseGroup} from 'react-pose'
import Modal from 'react-bootstrap/Modal'
import {ClassNames, keyframes} from '@emotion/core'
import {connect} from 'react-redux'
import {findIndex} from 'lodash'

import {addTranslationHelpers} from 'util/i18n'
import Page from 'components/Page'
import colors from 'style/colors'
import hardyHooray from 'assets/images/hardy-quizquestions-hooray.svg'
import hardyThinking from 'assets/images/hardy-thinking.svg'
import Button, {
  mobileButtonResponsiveStyles,
  normalButtonResponsiveStyles,
} from 'components/Button'
import ProgressBar from 'components/QuizProgressBar'
import QuestionTooltip from 'components/QuizQuestionTooltip'
import Answer from 'components/QuizAnswer'
import questions from 'data/quizQuestions'
import shadows from 'style/shadows'
import Video from 'components/Video'
import BackButton from 'components/BackButton'
import {
  mq,
  addResponsiveBreakpointProps,
  BreakpointSwitch,
} from 'style/mediaQueries'
import addEffectOnMount from 'util/addEffectOnMount'
import withAudioModeSectionRestoration from 'hocs/withAudioModeSectionRestoration'
import AudioHighlight from 'components/AudioHighlight'
import AudioSection from 'components/AudioSection'
import modalStyles from 'style/modalStyles'
import {audioSelector} from 'redux-local/selectors'
/* eslint-disable known-imports/no-unused-vars */
import hintVideo1 from 'assets/videos/quiz-hint-1-en-1080p.mp4'
import hintVideo2 from 'assets/videos/quiz-hint-2-en-1080p.mp4'
import hintVideo3 from 'assets/videos/quiz-hint-3-en-1080p.mp4'
import hintVideo1Spanish from 'assets/videos/quiz-hint-1-es-1080p.mp4'
import hintVideo2Spanish from 'assets/videos/quiz-hint-2-es-1080p.mp4'
import hintVideo3Spanish from 'assets/videos/quiz-hint-3-es-1080p.mp4'
/* eslint-enable known-imports/no-unused-vars */
import hintVideo1Thumbnail from 'assets/images/quiz-hint-1-video-thumbnail.jpg'
import hintVideo2Thumbnail from 'assets/images/quiz-hint-2-video-thumbnail.jpg'
import hintVideo3Thumbnail from 'assets/images/quiz-hint-3-video-thumbnail.jpg'
import hintVideo1CaptionsUrl from 'assets/videos/quiz-hint-1-en.vtt'
import hintVideo2CaptionsUrl from 'assets/videos/quiz-hint-2-en.vtt'
import hintVideo3CaptionsUrl from 'assets/videos/quiz-hint-3-en.vtt'
import hintVideo1CaptionsUrlSpanish from 'assets/videos/quiz-hint-1-es.vtt'
import hintVideo2CaptionsUrlSpanish from 'assets/videos/quiz-hint-2-es.vtt'
import hintVideo3CaptionsUrlSpanish from 'assets/videos/quiz-hint-3-es.vtt'
import {
  addTrackingProps,
  addTrackingToHandler,
  addLogTrackingEvent,
} from 'util/tracking'
import addRedux from 'util/addRedux'
import {completedQuiz} from 'redux-local/actions'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import {callWith} from 'util/fp'

const IncorrectContainer = posed.div({
  enter: {x: 0, y: 0},
  preEnter: {x: 100, y: 0},
  exit: {opacity: 0},
})

const CorrectContainer = IncorrectContainer

const getVideoHintIdKey = flow(
  fget('currentQuestion'),
  fget('key'),
  fsplit('.'),
  flast,
  fupperFirst,
  key => `quizHint${key}`
)

const addAudioModeRestorationOnMount = flow(
  addState('restoredAudioMode', 'setRestoredAudioMode', false),
  addHandlers({
    onAudioModeRestore: ({
      onAudioModeRestore,
      restoredAudioMode,
      setRestoredAudioMode,
    }) => () => {
      if (restoredAudioMode) return
      setRestoredAudioMode(true)
      onAudioModeRestore()
    },
  }),
  addEffectOnMount(({onAudioModeStart, onAudioModeRestore}) => () => {
    onAudioModeStart()
    return () => {
      onAudioModeRestore()
    }
  })
)

const enhanceIncorrectContent = connect(audioSelector)

const cachedHintVideoThumbnails = [
  hintVideo1Thumbnail,
  hintVideo2Thumbnail,
  hintVideo3Thumbnail,
]
const getCachedHintVideoThumbnail = ({index}) =>
  cachedHintVideoThumbnails[index]

const cachedHintVideoCaptionsUrls = [
  {
    en: {
      en: hintVideo1CaptionsUrl,
    },
    es: {
      es: hintVideo1CaptionsUrlSpanish,
    },
  },
  {
    en: {
      en: hintVideo2CaptionsUrl,
    },
    es: {
      es: hintVideo2CaptionsUrlSpanish,
    },
  },
  {
    en: {
      en: hintVideo3CaptionsUrl,
    },
    es: {
      es: hintVideo3CaptionsUrlSpanish,
    },
  },
]
const getCachedHintVideoCaptionsUrl = ({index}) =>
  cachedHintVideoCaptionsUrls[index]

let IncorrectContent = flow(
  addTranslationHelpers,
  ({currentQuestion, onDismiss, isAudioModeActive, t}) => (
    <>
      <em css={styles.tryAgain}>
        <AudioHighlight clipKey="tryAgain" dark>
          {t('quizQuestionsPage.tryAgain')}
        </AudioHighlight>
      </em>
      <Video
        idKey={getVideoHintIdKey({currentQuestion})}
        containerCss={styles.hintVideoWrapper}
        playing={!isAudioModeActive}
        fallbackCacheKey={`quizHint${currentQuestion.index + 1}Video`}
        fallbackCacheThumbnail={getCachedHintVideoThumbnail(currentQuestion)}
        fallbackCacheCaptionsUrl={getCachedHintVideoCaptionsUrl(
          currentQuestion
        )}
        hasSpanishVersion
      />
      <Button
        mode="transparent"
        css={styles.dismissIncorrectButton}
        title={t('buttons.dismiss')}
        onClick={onDismiss}
      />
    </>
  )
)

IncorrectContent.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isAudioModeActive: PropTypes.bool.isRequired,
}

IncorrectContent = enhanceIncorrectContent(IncorrectContent)

let IncorrectFeedback = flowMax(
  addAudioModeRestorationOnMount,
  addStateHandlers(
    {isShowing: true},
    {onDismiss: () => () => ({isShowing: false})}
  ),
  addHandlers({
    onDismiss: ({onDismiss, onAudioModeRestore}) => () => {
      onAudioModeRestore()
      onDismiss()
    },
  }),
  ({
    currentQuestion,
    isShowing,
    onDismiss,
    audioSectionId,
    selectedAnswerIndex,
  }) =>
    isShowing ? (
      <AudioSection id={audioSectionId}>
        <BreakpointSwitch
          mobile={
            <ClassNames>
              {({css}) => (
                <Modal
                  show
                  onHide={onDismiss}
                  dialogClassName={css([
                    modalStyles.dialogReset,
                    styles.rightColumnIncorrectContainerModal,
                  ])}
                >
                  <div css={styles.rightColumnIncorrectPrecedingSpacer} />
                  <div css={styles.rightColumnIncorrectContainer}>
                    <IncorrectContent
                      currentQuestion={currentQuestion}
                      onDismiss={onDismiss}
                    />
                  </div>
                </Modal>
              )}
            </ClassNames>
          }
          desktop={
            <PoseGroup preEnterPose="preEnter" animateOnMount>
              <IncorrectContainer
                css={styles.rightColumnIncorrectContainer}
                key={`incorrectContainer-${selectedAnswerIndex}`}
              >
                <IncorrectContent
                  currentQuestion={currentQuestion}
                  onDismiss={onDismiss}
                />
              </IncorrectContainer>
            </PoseGroup>
          }
        />
      </AudioSection>
    ) : null
)

IncorrectFeedback.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  audioSectionId: PropTypes.string.isRequired,
  selectedAnswerIndex: PropTypes.number,
}

IncorrectFeedback = withAudioModeSectionRestoration(IncorrectFeedback)

const hardyBounce = keyframes({
  '0%, 100%': {
    transform: 'translateY(0px)',
  },
  '50%': {
    transform: 'translateY(-17px)',
  },
})

const CorrectContent = flowMax(
  addTranslationHelpers,
  addProps(({isLastQuestion}) => ({
    buttonKey: isLastQuestion ? 'continueJourney' : 'next',
  })),
  addRedux(null, dispatch => ({
    onFinishLastQuestion: () => dispatch(completedQuiz()),
  })),
  addHandlers({
    onClick: ({buttonLinkTo, onNextQuestion, onFinishLastQuestion}) => () => {
      if (buttonLinkTo) {
        onFinishLastQuestion()
      } else {
        onNextQuestion()
      }
    },
  }),
  ({currentQuestion, buttonLinkTo, onClick, buttonKey, t}) => (
    <>
      <em css={styles.correct}>
        <AudioHighlight clipKey="correct">
          {t('quizQuestionsPage.correct')}
        </AudioHighlight>
      </em>
      <span css={styles.correctAnswer}>
        <AudioHighlight clipKey="answer">
          {t(
            `${currentQuestion.key}.answers.${
              currentQuestion.answers[currentQuestion.correctAnswerIndex]
            }`
          )}
        </AudioHighlight>
      </span>
      <img src={hardyHooray} alt="" css={styles.hardyHooray} />
      <Button
        title={t(`buttons.${buttonKey}`)}
        css={styles.nextButton}
        linkTo={buttonLinkTo}
        onClick={onClick}
        data-testid={`quiz-questions-next-${currentQuestion.index + 1}`}
        audioClipKey={buttonKey}
      />
    </>
  )
)

CorrectContent.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  buttonLinkTo: PropTypes.string,
}

const getCorrectAudioSection = ({
  isLastQuestion,
  isBelowDesktop,
  currentQuestion,
}) => {
  if (isBelowDesktop) return `quizCorrectMobile${currentQuestion.index + 1}`
  if (isLastQuestion) return 'quizCorrectLast'
  return 'quizCorrect'
}

let CorrectFeedback = flow(
  addEffectOnMount(({onAudioModeStart}) => () => {
    onAudioModeStart()
  }),
  ({
    currentQuestion,
    isLastQuestion,
    buttonLinkTo,
    onNextQuestion,
    audioSectionId,
  }) => (
    <AudioSection id={audioSectionId}>
      <BreakpointSwitch
        mobile={
          <ClassNames>
            {({css}) => (
              <Modal
                show
                onHide={() => {}}
                dialogClassName={css([
                  modalStyles.dialogReset,
                  styles.rightColumnCorrectContainerModal,
                ])}
              >
                <div css={styles.rightColumnCorrectPrecedingSpacer} />
                <div css={styles.rightColumnCorrectContainer}>
                  <CorrectContent
                    currentQuestion={currentQuestion}
                    onNextQuestion={onNextQuestion}
                    buttonLinkTo={buttonLinkTo}
                    isLastQuestion={isLastQuestion}
                  />
                </div>
              </Modal>
            )}
          </ClassNames>
        }
        desktop={
          <PoseGroup preEnterPose="preEnter" animateOnMount>
            <CorrectContainer
              css={styles.rightColumnCorrectContainer}
              key="correctContainer"
            >
              <CorrectContent
                currentQuestion={currentQuestion}
                onNextQuestion={onNextQuestion}
                buttonLinkTo={buttonLinkTo}
                isLastQuestion={isLastQuestion}
              />
            </CorrectContainer>
          </PoseGroup>
        }
      />
    </AudioSection>
  )
)

CorrectFeedback.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  buttonLinkTo: PropTypes.string,
  audioSectionId: PropTypes.string.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
}

CorrectFeedback = withAudioModeSectionRestoration(CorrectFeedback)

const RightColumn = flowMax(
  addResponsiveBreakpointProps('desktop'),
  ({
    isCorrect,
    isIncorrect,
    isLastQuestion,
    onNextQuestion,
    buttonLinkTo,
    currentQuestion,
    clickingAnswer,
    isBelowDesktop,
    selectedAnswerIndex,
  }) => {
    if (isCorrect)
      return (
        <CorrectFeedback
          currentQuestion={currentQuestion}
          buttonLinkTo={buttonLinkTo}
          onNextQuestion={onNextQuestion}
          audioSectionId={getCorrectAudioSection({
            isLastQuestion,
            isBelowDesktop,
            currentQuestion,
          })}
          isLastQuestion={isLastQuestion}
        />
      )
    if (isIncorrect)
      return clickingAnswer == null ? (
        <IncorrectFeedback
          currentQuestion={currentQuestion}
          audioSectionId="quizIncorrect"
          selectedAnswerIndex={selectedAnswerIndex}
          key={selectedAnswerIndex}
        />
      ) : null
    return (
      <img
        src={hardyThinking}
        css={[
          styles.hardyThinking,
          currentQuestion.questionRendersMultilineOnIpadInEnglish &&
            styles.rightColumnContainerShifted,
        ]}
        alt=""
      />
    )
  }
)

RightColumn.propTypes = {
  isCorrect: PropTypes.bool.isRequired,
  isIncorrect: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  buttonLinkTo: PropTypes.string,
  currentQuestion: PropTypes.object.isRequired,
  clickingAnswer: PropTypes.number,
  selectedAnswerIndex: PropTypes.number,
}

const addCurrentQuestion = flow(
  addProps(({number}) => ({currentQuestionIndex: Number(number - 1)})),
  addProps(({currentQuestionIndex}) => ({
    currentQuestion: {
      ...questions[currentQuestionIndex],
      index: currentQuestionIndex,
    },
  })),
  addProps(({currentQuestion}) => ({
    currentQuestion: {
      ...currentQuestion,
      key: `quizQuestionsPage.questions.${currentQuestion.key}`,
    },
  }))
)

const UP_ARROW = 38
const DOWN_ARROW = 40
const addRadioButtonKeyboardNavigationFocusControl = flow(
  addRef('answerRefs', []),
  addEffectOnPropChange(
    'currentQuestion',
    (
      {currentQuestion: {index}, answerRefs},
      {currentQuestion: {index: prevIndex} = {}}
    ) => {
      if (index === prevIndex) return
      answerRefs.current = []
    }
  ),
  addEffect(
    ({answerRefs}) => () => {
      const keydownHandler = event => {
        const {keyCode} = event
        if (keyCode !== DOWN_ARROW && keyCode !== UP_ARROW) return
        const radioButtonElements = answerRefs.current
        const currentlyFocusedElement = window.document.activeElement
        const currentlyFocusedRadioButtonIndex = findIndex(
          radioButtonElements,
          element => currentlyFocusedElement === element
        )
        if (!(currentlyFocusedRadioButtonIndex >= 0)) return
        callWith(currentlyFocusedRadioButtonIndex)(
          flow(
            index =>
              keyCode === DOWN_ARROW
                ? (index + 1) % radioButtonElements.length
                : (index - 1 + radioButtonElements.length) %
                  radioButtonElements.length,
            nextIndex => radioButtonElements[nextIndex],
            nextElement => {
              nextElement.focus()
              event.preventDefault()
            }
          )
        )
      }
      window.document.addEventListener('keydown', keydownHandler)
      return () => {
        window.document.removeEventListener('keydown', keydownHandler)
      }
    },
    []
  )
)

const QuizQuestions = flowMax(
  addTranslationHelpers,
  addCurrentQuestion,
  addTrackingProps(({currentQuestion: {index: currentQuestionIndex}}) => ({
    // isCorrect, isIncorrect, selectedAnswerIndex
    'Question number': currentQuestionIndex + 1,
  })),
  addState('selectedAnswerIndex', 'setSelectedAnswerIndex'),
  addState('clickingAnswer', 'setClickingAnswer', null),
  addProps(({currentQuestion, selectedAnswerIndex}) => ({
    isCorrect: selectedAnswerIndex === currentQuestion.correctAnswerIndex,
    isIncorrect:
      selectedAnswerIndex != null &&
      selectedAnswerIndex !== currentQuestion.correctAnswerIndex,
    isLastQuestion: currentQuestion.index === questions.length - 1,
  })),
  addLogTrackingEvent,
  addHandlers({
    onNextQuestion: ({currentQuestion, setSelectedAnswerIndex}) => () => {
      navigate(`/quiz/questions/${currentQuestion.index + 2}`)
      setSelectedAnswerIndex(null)
    },
    onAnswerMouseDown: ({setClickingAnswer, isCorrect}) => index => () => {
      if (isCorrect) return
      setClickingAnswer(index)
    },
    onAnswerClick: ({
      setSelectedAnswerIndex,
      setClickingAnswer,
      isCorrect,
      currentQuestion: {correctAnswerIndex},
      logTrackingEvent,
    }) => index => () => {
      if (isCorrect) return
      setSelectedAnswerIndex(index)
      logTrackingEvent(
        index === correctAnswerIndex
          ? 'Answered quiz question correctly'
          : 'Answered quiz question incorrectly'
      )
      setClickingAnswer(null)
    },
  }),
  addProps(({isLastQuestion}) => ({
    buttonLinkTo: isLastQuestion ? '/steps/4' : null,
  })),
  addStateHandlers(
    {isTooltipShowing: false},
    {
      onTooltipClick: ({isTooltipShowing}) => () => ({
        isTooltipShowing: !isTooltipShowing,
      }),
      onClickAnywhere: ({isTooltipShowing}) => () =>
        isTooltipShowing
          ? {
              isTooltipShowing: false,
            }
          : {},
    }
  ),
  addTrackingToHandler('onTooltipClick', ({isTooltipShowing}) =>
    !isTooltipShowing ? 'View quiz tooltip' : null
  ),
  addRadioButtonKeyboardNavigationFocusControl,
  ({
    currentQuestion,
    t,
    selectedAnswerIndex,
    onAnswerClick,
    onAnswerMouseDown,
    isCorrect,
    isIncorrect,
    isLastQuestion,
    onNextQuestion,
    buttonLinkTo,
    isTooltipShowing,
    onTooltipClick,
    onClickAnywhere,
    clickingAnswer,
    answerRefs,
  }) => (
    <Page
      css={styles.container}
      background={colors.lightBlueBackground}
      onClick={onClickAnywhere}
      audioSectionId={`quizQuestionsPage${currentQuestion.index + 1}`}
      exposeRef
      name="Quiz"
    >
      <BackButton />
      <div css={styles.contentContainer}>
        <h1 css={styles.question}>
          <AudioHighlight clipKey="question">
            {currentQuestion.index + 1}.{' '}
            <Trans i18nKey={`${currentQuestion.key}.question`} i18n={i18n}>
              <QuestionTooltip
                text={t(`${currentQuestion.key}.tooltip`)}
                showing={isTooltipShowing}
                onClick={onTooltipClick}
                css={styles.question}
                audioSectionId={`quizTooltip${currentQuestion.index + 1}`}
              >
                tooltip
              </QuestionTooltip>
            </Trans>
          </AudioHighlight>
        </h1>
        <div css={styles.mainRowContainer}>
          <div
            css={[
              styles.leftColumnContainer,
              isCorrect && styles.leftColumnContainerCorrect,
            ]}
            role="radiogroup"
            aria-label={t('quizQuestionsPage.answers')}
          >
            {currentQuestion.answers.map(
              flow(
                (answer, index) => ({
                  answer,
                  index,
                  isCorrect: index === currentQuestion.correctAnswerIndex,
                  isSelected: index === selectedAnswerIndex,
                }),
                addProps(({isCorrectAnswer}) => ({
                  isOtherCorrectAnswerSelected: isCorrect && !isCorrectAnswer,
                })),
                ({
                  answer,
                  index,
                  isCorrect: isCorrectAnswer,
                  isSelected,
                  isOtherCorrectAnswerSelected,
                }) => (
                  <Answer
                    isCorrect={isCorrectAnswer}
                    isSelected={isSelected}
                    disabled={isOtherCorrectAnswerSelected}
                    isClicking={clickingAnswer === index}
                    text={t(`${currentQuestion.key}.answers.${answer}`)}
                    onClick={onAnswerClick(index)}
                    onMouseDown={onAnswerMouseDown(index)}
                    index={index}
                    audioClipKey={answer}
                    key={answer}
                    setInputRef={inputEl => {
                      answerRefs.current[index] = inputEl
                    }}
                  />
                )
              )
            )}
          </div>
          <div css={styles.rightColumnContainer} aria-live="assertive">
            <RightColumn
              isCorrect={isCorrect}
              isIncorrect={isIncorrect}
              isLastQuestion={isLastQuestion}
              onNextQuestion={onNextQuestion}
              buttonLinkTo={buttonLinkTo}
              currentQuestion={currentQuestion}
              clickingAnswer={clickingAnswer}
              selectedAnswerIndex={selectedAnswerIndex}
            />
          </div>
        </div>
      </div>
      <ProgressBar numCorrect={currentQuestion.index + (isCorrect ? 1 : 0)} />
    </Page>
  )
)

QuizQuestions.propTypes = {
  number: PropTypes.string,
}

export default QuizQuestions

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 121,
    [mq.mobile]: {
      paddingLeft: 13,
      paddingRight: 12,
    },
    [mq.tablet]: {
      paddingLeft: 30,
      paddingRight: 28,
    },
    paddingBottom: 78,
  },
  question: {
    [mq.mobile]: {
      fontSize: 32,
      lineHeight: '43px',
    },
    [mq.desktop]: {
      fontSize: 42,
      lineHeight: '56px',
    },
    fontWeight: 700,
    marginBottom: 25,
    maxWidth: 816,
  },
  mainRowContainer: {
    display: 'flex',
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.desktop]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  rightColumnCorrectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.greenBackground,
    borderRadius: 10,
    boxShadow: shadows.card,
    paddingBottom: 32,
    overflow: 'auto',
    [mq.mobile]: {
      paddingTop: 25,
      paddingLeft: 20,
      paddingRight: 12,
      flexGrow: 0,
      flexShrink: 0,
      maxHeight: '100%',
    },
    [mq.desktop]: {
      position: 'relative',
      paddingTop: 33,
      paddingLeft: 37,
      paddingRight: 31,
      flexGrow: 1,
    },
  },
  rightColumnIncorrectContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.darkGreyBackground,
    borderRadius: 10,
    boxShadow: shadows.card,
    paddingTop: 28,
    paddingLeft: 26,
    paddingRight: 26,
    paddingBottom: 32,
    overflow: 'auto',
    [mq.mobile]: {
      flexGrow: 0,
      flexShrink: 0,
      maxHeight: '100%',
    },
    [mq.desktop]: {
      position: 'relative',
      top: 2,
      zIndex: 0,
      flexGrow: 1,
      width: '100%',
      maxWidth: 650,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  rightColumnIncorrectContainerModal: {
    position: 'fixed',
    top: 4,
    left: 13,
    right: 10,
    bottom: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  rightColumnCorrectContainerModal: {
    position: 'fixed',
    top: 4,
    left: 10,
    right: 12,
    bottom: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  correct: {
    [mq.mobile]: {
      fontSize: 20,
      lineHeight: '27px',
      alignSelf: 'flex-start',
      marginBottom: 8,
    },
    [mq.desktop]: {
      fontSize: 36,
      lineHeight: '48px',
      alignSelf: 'auto',
      marginBottom: 27,
    },
    fontWeight: 600,
  },
  nextButton: {
    backgroundColor: colors.blue,
    boxShadow: shadows.card,
    [mq.mobile]: {
      ...mobileButtonResponsiveStyles,
      minWidth: 164,
      flexShrink: 0,
      paddingLeft: 27,
      paddingRight: 27,
    },
    [mq.desktop]: {
      ...normalButtonResponsiveStyles,
      minWidth: 212,
    },
  },
  tryAgain: {
    [mq.mobile]: {
      fontSize: 20,
      lineHeight: '27px',
      marginBottom: 13,
      alignSelf: 'flex-start',
    },
    [mq.desktop]: {
      fontSize: 26,
      lineHeight: '35px',
      marginBottom: 35,
      alignSelf: 'auto',
    },
    color: colors.white,
    textAlign: 'center',
  },
  hardyThinking: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.desktop]: {
      display: 'block',
    },
    marginTop: 101,
    marginRight: 23,
    alignSelf: 'center',
  },
  hardyHooray: {
    [mq.mobile]: {
      width: 160,
      maxWidth: '25vh',
      animationDelay: '0.5s',
    },
    [mq.desktop]: {
      width: 222,
      maxWidth: 'auto',
      animationDelay: '0.14s',
    },
    marginBottom: 34,
    animation: `${hardyBounce} 0.35s linear`,
  },
  contentContainer: {
    [mq.mobile]: {
      marginBottom: 28,
    },
    [mq.desktop]: {
      marginBottom: 0,
      height: 627,
    },
  },
  rightColumnContainerShifted: {
    position: 'relative',
    top: -41,
  },
  rightColumnContainer: {
    [mq.mobile]: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    [mq.desktop]: {
      position: 'static',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 16,
    },
  },
  leftColumnContainer: {
    [mq.desktop]: {
      marginRight: 15,
    },
  },
  leftColumnContainerCorrect: {
    [mq.desktop]: {
      marginRight: 57,
    },
  },
  dismissIncorrectButton: {
    borderColor: colors.offWhiteBorder,
    borderWidth: 2,
    borderStyle: 'solid',
    ...mobileButtonResponsiveStyles,
    color: colors.white,
    fontSize: 26,
    lineHeight: '38px',
    fontWeight: 500,
    alignSelf: 'center',
    marginTop: 20,
    flexShrink: 0,
    [mq.desktop]: {
      display: 'none',
    },
  },
  correctAnswer: {
    fontSize: 20,
    lineHeight: '27px',
    alignSelf: 'flex-start',
    marginBottom: 23,
    [mq.desktop]: {
      display: 'none',
    },
  },
  hintVideoWrapper: {
    width: '100%',
    maxWidth: '100vh',
    alignSelf: 'center',
    flexShrink: 0,
  },
  rightColumnIncorrectPrecedingSpacer: {
    height: 66,
    flexShrink: 1,
  },
  rightColumnCorrectPrecedingSpacer: {
    height: 71,
    flexShrink: 1,
  },
}
