import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {
  flowMax,
  addProps,
  addStateHandlers,
  branch,
  renderNothing,
  addEffect,
  addRef,
  returns,
  branchPure,
} from 'ad-hok'
import {flow, split as fsplit} from 'lodash/fp'

import {audioSelector} from 'redux-local/selectors'
import {finishedPlayingAudioClip} from 'redux-local/actions'
import {addCurrentLanguage} from 'util/i18n'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import audioSections from 'data/audioSections'
import addRedux from 'util/addRedux'
import {
  addTrackingOnPropBecomingTruthy,
  addTrackingOnPropBecomingFalsy,
} from 'util/tracking'

export const addIsAudioModeActive = addRedux(audioSelector)

const getClipSource = ({currentLanguage, sectionId, clipIndex}) => {
  const section = audioSections[sectionId]
  if (!section) return null
  const clip = section.clips[clipIndex]
  if (!clip) return null
  return clip.source[currentLanguage]
}

const getClipKey = ({sectionId, clipIndex}) => {
  const section = audioSections[sectionId]
  if (!section) return null
  const clip = section.clips[clipIndex]
  if (!clip) return null
  return clip.key
}

export const isClipPlaying = flowMax(
  branchPure(
    ({isAudioModeActive, isAudioModePseudoActive}) =>
      !(isAudioModeActive || isAudioModePseudoActive),
    returns(() => false)
  ),
  addProps(
    flow(
      ({clipKey}) => clipKey,
      fsplit('.'),
      ([checkClipSectionId, checkClipKey]) => ({
        checkClipSectionId,
        checkClipKey,
      })
    )
  ),
  addProps(({sectionId, clipIndex}) => ({
    clipKey: getClipKey({sectionId, clipIndex}),
  })),
  ({sectionId, clipKey, checkClipSectionId, checkClipKey}) => {
    if (sectionId !== checkClipSectionId) return false
    if (clipKey !== checkClipKey) return false
    return true
  }
)

const isPlaying = audioElement =>
  audioElement &&
  audioElement.currentTime > 0 &&
  !audioElement.paused &&
  !audioElement.ended &&
  audioElement.readyState > 2

const Sound = flow(
  addRef('audioRef'),
  addEffectOnPropChange(['url'], ({url, audioRef: {current: audioElement}}) => {
    if (url) return
    if (!isPlaying(audioElement)) return
    audioElement.pause()
  }),
  addEffectOnPropChange(
    'isPaused',
    ({isPaused, audioRef: {current: audioElement}}) => {
      if (isPaused == null) return
      if (isPaused) {
        if (!isPlaying(audioElement)) return
        audioElement.pause()
      } else {
        if (isPlaying(audioElement)) return
        audioElement.play()
      }
    }
  ),
  ({url, onFinishedPlaying, audioRef}) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio src={url} autoPlay onEnded={onFinishedPlaying} ref={audioRef} />
  )
)

Sound.propTypes = {
  url: PropTypes.string,
  onFinishedPlaying: PropTypes.func.isRequired,
  isPaused: PropTypes.bool,
}

const enhance = connect(
  audioSelector,
  dispatch => ({
    onFinishedPlayingClip: () => {
      dispatch(finishedPlayingAudioClip())
    },
  })
)

const AudioModePlayer = flowMax(
  addTrackingOnPropBecomingTruthy('isAudioModeActive', 'Turn on audio mode'),
  addTrackingOnPropBecomingFalsy('isAudioModeActive', 'Turn off audio mode'),
  addCurrentLanguage,
  addStateHandlers(
    {hasMounted: false},
    {
      onMounted: () => () => ({hasMounted: true}),
    }
  ),
  addProps(({isAudioModeActive, isAudioModePseudoActive, isPaused}) => ({
    isAudioModeActive: isAudioModeActive || isAudioModePseudoActive,
    isPaused: isAudioModePseudoActive ? isPaused : null,
  })),
  branch(
    ({isAudioModeActive, hasMounted}) => !isAudioModeActive && !hasMounted,
    renderNothing()
  ),
  addProps(({currentLanguage, sectionId, clipIndex}) => ({
    clipSource: getClipSource({currentLanguage, sectionId, clipIndex}),
  })),
  addEffect(
    ({onMounted}) => () => {
      onMounted()
    },
    []
  ),
  ({clipSource, onFinishedPlayingClip, isAudioModeActive, isPaused}) => (
    <Sound
      url={isAudioModeActive ? clipSource : null}
      onFinishedPlaying={onFinishedPlayingClip}
      isPaused={isPaused}
    />
  )
)

AudioModePlayer.propTypes = {
  isAudioModeActive: PropTypes.bool.isRequired,
  isAudioModePseudoActive: PropTypes.bool.isRequired,
  sectionId: PropTypes.string,
  clipIndex: PropTypes.number,
  onFinishedPlayingClip: PropTypes.func.isRequired,
  isPaused: PropTypes.bool.isRequired,
}

export default enhance(AudioModePlayer)
