import React from 'react'

import {maxWidth} from 'style/layout'

export const MaxWidthContainer = props => (
  <div css={styles.maxWidthContainer} {...props} />
)

const FullWidthContainer = props => (
  <div css={styles.fullWidthContainer} {...props} />
)

const styles = {
  fullWidthContainer: {
    width: '100vw',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  maxWidthContainer: {
    maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export default FullWidthContainer
