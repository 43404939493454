import React from 'react'
import {flow} from 'lodash/fp'
import {Helmet} from 'react-helmet-async'

import {addTranslationHelpers} from 'util/i18n'

const DocumentTitle = flow(
  addTranslationHelpers,
  ({t}) => (
    <Helmet>
      <title>{t('general.appTitle').replace('\n', ' ')}</title>
    </Helmet>
  )
)

export default DocumentTitle
