import {createContext} from 'react'
import {flow} from 'lodash/fp'
import {addContext} from 'ad-hok'

export const InactivityContext = createContext()

export const addInactivityContext = flow(
  addContext(InactivityContext, 'inactivityContext'),
  ({inactivityContext: {onInactivityReset = () => {}} = {}, ...props}) => ({
    ...props,
    onInactivityReset,
  })
)
