import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {flow} from 'lodash/fp'

import TitleBar from 'components/TitleBar'
import {BreakpointSwitch, mq} from 'style/mediaQueries'
import LanguageSelector from 'components/LanguageSelector'
import AudioModeToggle from 'components/AudioModeToggle'
import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import hamburgerIcon from 'assets/images/hamburger-menu-patient.svg'
import {a11yStyles} from 'util/a11y'

const HamburgerMenu = flow(
  addTranslationHelpers,
  ({onClick, t}) => (
    <Button
      mode="plain"
      css={styles.hamburgerMenuContainer}
      onClick={onClick}
      data-testid="menu-hamburger"
    >
      <img src={hamburgerIcon} alt="" />
      <span css={styles.hamburgerMenuLabel}>{t('menu.menu')}</span>
    </Button>
  )
)

HamburgerMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const getFormattedAppTitle = ({t}) => {
  const lines = t('general.appTitle').split('\n')
  return lines.map((titleLine, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={index}>
      <span css={index === 1 && styles.appTitleSecondLine}>{titleLine}</span>
      {index < lines.length - 1 && <br />}
    </Fragment>
  ))
}

const PatientTitleBar = flow(
  addTranslationHelpers,
  ({onMenuOpen, t}) => (
    <TitleBar css={styles.titleBar}>
      <div css={styles.titleBarContent}>
        <Button
          mode="plain"
          css={styles.appTitle}
          linkTo="/"
          data-testid="app-title"
        >
          {getFormattedAppTitle({t})}
        </Button>
        <BreakpointSwitch
          tablet={
            <>
              <AudioModeToggle css={styles.audioModeToggle} />
              <LanguageSelector css={styles.languageSelector} />
            </>
          }
        />
        <HamburgerMenu onClick={onMenuOpen} />
      </div>
    </TitleBar>
  )
)

PatientTitleBar.propTypes = {
  onMenuOpen: PropTypes.func.isRequired,
}

export default PatientTitleBar

const styles = {
  titleBarContent: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  appTitle: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 400,
    position: 'relative',
    top: 1,
  },
  appTitleSecondLine: {
    fontWeight: 600,
  },
  hamburgerMenuContainer: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [mq.mobile]: {
      marginLeft: 'auto',
      width: 44,
      marginRight: -11,
    },
    [mq.mobileMax]: {
      width: 'auto',
      marginRight: 0,
    },
    [mq.tablet]: {
      marginLeft: 0,
    },
  },
  hamburgerMenuLabel: {
    fontSize: 18,
    lineHeight: 'normal',
    marginLeft: 12,
    [mq.mobile]: a11yStyles.visuallyHidden,
    [mq.mobileMax]: a11yStyles.notVisuallyHidden,
  },
  audioModeToggle: {
    position: 'relative',
    top: 1,
  },
  languageSelector: {
    position: 'relative',
    top: 1,
  },
  titleBar: {
    [mq.tablet]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [mq.desktop]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
}
