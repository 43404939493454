import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import {flow} from 'lodash/fp'
import {
  addState,
  addProps,
  flowMax,
  addRef,
  branch,
  renderNothing,
} from 'ad-hok'
import numeral from 'numeral'
import {get as getIdbKey} from 'idb-keyval'
import {isPlainObject, map, findKey, keys, includes, some} from 'lodash'
import {connect} from 'react-redux'

import {addTranslationHelpers, addCurrentLanguage} from 'util/i18n'
import {cssPropType, imageSourcePropType} from 'util/propTypes'
import {mq} from 'style/mediaQueries'
import AudioHighlight from 'components/AudioHighlight'
import addEffectOnMount from 'util/addEffectOnMount'
import addEffectOnPropChange from 'util/addEffectOnPropChange'
import {
  getWistiaUrl,
  disableWistiaTracking,
  getWistiaVideoId,
} from 'util/wistia'
import {subtitlesLanguageSelector} from 'redux-local/selectors'
import {setSubtitlesLanguage as setSubtitlesLanguageAction} from 'redux-local/actions'
import videosSpec from 'assets/videos/videos.json'

const formatDuration = flow(
  duration => ({minutes: Math.floor(duration / 60), seconds: duration % 60}),
  ({minutes, seconds}) => `${minutes}:${numeral(seconds).format('00')}`
)

const Caption = flow(
  addTranslationHelpers,
  addProps(({audioClipKey, title}) => ({
    title: audioClipKey ? (
      <AudioHighlight clipKey={audioClipKey}>{title}</AudioHighlight>
    ) : (
      title
    ),
  })),
  ({title, duration, t}) => (
    <figcaption css={styles.headerRowContainer}>
      <h1 css={styles.header}>{title}</h1>
      {
        <h2 css={styles.duration}>
          {t('introVideoPage.duration', {
            duration: formatDuration(duration),
          })}
        </h2>
      }
    </figcaption>
  )
)

Caption.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  audioClipKey: PropTypes.string,
}

const setUrlIfCached = async ({
  fallbackCacheKey,
  setCachedVideoUrl,
  hasSpanishVersion,
  isSpanishSelected,
  setDidInitializeCachedVideoUrl,
}) => {
  if (!fallbackCacheKey) {
    setDidInitializeCachedVideoUrl(true)
    return
  }

  const useCacheKey =
    isSpanishSelected && hasSpanishVersion
      ? `${fallbackCacheKey}-es`
      : fallbackCacheKey
  const cachedArrayBuffer = await getIdbKey(useCacheKey)
  if (!cachedArrayBuffer) {
    setDidInitializeCachedVideoUrl(true)
    return
  }

  flow(
    () => cachedArrayBuffer,
    buffer => new Blob([buffer], {type: 'video/mp4'}),
    blob => URL.createObjectURL(blob),
    objectUrl => {
      setCachedVideoUrl(objectUrl)
      setDidInitializeCachedVideoUrl(true)
    }
  )()
}

const addCachedVideoUrl = flowMax(
  addState('cachedVideoUrl', 'setCachedVideoUrl'),
  addState(
    'didInitializeCachedVideoUrl',
    'setDidInitializeCachedVideoUrl',
    false
  ),
  addCurrentLanguage,
  addEffectOnMount(props => () => {
    setUrlIfCached(props)
  }),
  addEffectOnPropChange('currentLanguage', props => {
    setUrlIfCached(props)
  }),
  branch(
    ({didInitializeCachedVideoUrl}) => !didInitializeCachedVideoUrl,
    renderNothing()
  )
)

const getCaptionsTrack = (languageCode, src, isDefault) => ({
  default: isDefault,
  kind: 'captions',
  srcLang: languageCode,
  src,
})

const getOfflineSubtitlesTracks = ({
  fallbackCacheCaptionsUrl,
  currentLanguage,
  currentSubtitlesLanguage,
}) => {
  if (!isPlainObject(fallbackCacheCaptionsUrl))
    return [getCaptionsTrack(currentLanguage, fallbackCacheCaptionsUrl, true)]
  const subtitlesForThisGlobalLanguage =
    fallbackCacheCaptionsUrl[currentLanguage]
  const isCurrentSubtitlesLanguageAnOption =
    !!currentSubtitlesLanguage &&
    some(
      keys(subtitlesForThisGlobalLanguage),
      languageCode => languageCode === currentSubtitlesLanguage
    )
  return map(subtitlesForThisGlobalLanguage, (url, languageCode) =>
    getCaptionsTrack(
      languageCode,
      url,
      isCurrentSubtitlesLanguageAnOption
        ? currentSubtitlesLanguage === languageCode
        : currentLanguage === languageCode
    )
  )
}

const getConfigForOffline = ({
  fallbackCacheCaptionsUrl,
  fallbackCacheThumbnail,
  currentLanguage,
  currentSubtitlesLanguage,
}) => {
  if (!(fallbackCacheCaptionsUrl || fallbackCacheThumbnail)) return undefined
  const fileConfig = {}
  if (fallbackCacheCaptionsUrl) {
    fileConfig.tracks = getOfflineSubtitlesTracks({
      fallbackCacheCaptionsUrl,
      currentLanguage,
      currentSubtitlesLanguage,
    })
  }
  if (fallbackCacheThumbnail) {
    fileConfig.attributes = {poster: fallbackCacheThumbnail}
  }
  return {file: fileConfig}
}

const getDefaultSubtitlesLanguage = ({
  currentSubtitlesLanguage,
  currentLanguage,
  idKey,
}) => {
  if (!currentSubtitlesLanguage) return null
  const videoSpec = videosSpec.videos[idKey]
  const videoSubtitlesSpec = videoSpec.subtitles
  if (!videoSubtitlesSpec) return null
  const availableSubtitlesLanguagesForThisVideo = keys(
    videoSubtitlesSpec[currentLanguage]
  )
  if (
    includes(availableSubtitlesLanguagesForThisVideo, currentSubtitlesLanguage)
  ) {
    return currentSubtitlesLanguage
  }
  return null
}

const getConfigForWistia = ({
  currentSubtitlesLanguage,
  currentLanguage,
  idKey,
}) => {
  const defaultSubtitlesLanguage = getDefaultSubtitlesLanguage({
    currentSubtitlesLanguage,
    currentLanguage,
    idKey,
  })
  if (!defaultSubtitlesLanguage) return undefined
  return {
    wistia: {
      options: {
        plugin: {
          'captions-v1': {
            language: getISO6392Code(defaultSubtitlesLanguage),
          },
        },
      },
    },
  }
}

const ISO639_2_CODES = {
  en: 'eng',
  es: 'spa',
  ar: 'ara',
  fr: 'fre',
  de: 'ger',
  hi: 'hin',
  ko: 'kor',
  pt: 'por',
  ru: 'rus',
  tl: 'tgl',
  fa: 'per',
  vi: 'vie',
  zh: 'chi',
  'zh-Hant': 'zh-Hant',
}

const getISO6392Code = languageCode => ISO639_2_CODES[languageCode]

const getLanguageCodeFromISO6392Code = iso6392Code => {
  const found = findKey(ISO639_2_CODES, value => iso6392Code === value)
  if (!found)
    throw new Error(`Didn't find subtitles language code: '${iso6392Code}'`)
  return found
}

const registerWistiaSubtitlesChangedListener = ({
  idKey,
  t,
  setSubtitlesLanguage,
}) => {
  window._wq = window._wq || []
  window._wq.push({
    id: getWistiaVideoId({idKey, t}),
    onReady: video => {
      video.bind('captionschange', ({language}) => {
        setSubtitlesLanguage(getLanguageCodeFromISO6392Code(language))
      })
    },
  })
}

const shouldTreatAsARealOfflineSubtitlesChangedEvent = textTracks =>
  textTracks.length > 1

const enhance = connect(
  subtitlesLanguageSelector,
  {setSubtitlesLanguage: setSubtitlesLanguageAction}
)

const Video = flowMax(
  addTranslationHelpers,
  addState('duration', 'setDuration'),
  addCachedVideoUrl,
  addCurrentLanguage,
  addRef('videoRef'),
  addProps(({cachedVideoUrl}) => ({
    isUsingOffline: !!cachedVideoUrl,
  })),
  addEffectOnMount(
    ({isUsingOffline, setSubtitlesLanguage, idKey, t, videoRef}) => () => {
      disableWistiaTracking()
      if (isUsingOffline) {
        videoRef.current
          .getInternalPlayer()
          .textTracks.addEventListener('change', ({target: textTracks}) => {
            if (!shouldTreatAsARealOfflineSubtitlesChangedEvent(textTracks))
              return
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < textTracks.length; i++) {
              if (textTracks[i].mode === 'showing') {
                setSubtitlesLanguage(textTracks[i].language)
              }
            }
          })
      } else {
        registerWistiaSubtitlesChangedListener({
          idKey,
          setSubtitlesLanguage,
          t,
        })
      }
    }
  ),
  ({
    idKey,
    t,
    title,
    duration,
    setDuration,
    containerCss,
    audioClipKey,
    cachedVideoUrl,
    setCachedVideoUrl,
    fallbackCacheKey,
    fallbackCacheCaptionsUrl,
    fallbackCacheThumbnail,
    currentLanguage,
    isSpanishSelected,
    hasSpanishVersion,
    currentSubtitlesLanguage,
    videoRef,
    isUsingOffline,
    didInitializeCachedVideoUrl,
    setSubtitlesLanguage,
    setDidInitializeCachedVideoUrl,
    ...props
  }) => (
    <figure css={[styles.wrapper, containerCss]}>
      {!!title && !!duration && (
        <Caption
          title={title}
          duration={duration}
          audioClipKey={audioClipKey}
        />
      )}

      <div css={styles.aspectRatioWrapperWrapper}>
        <div css={styles.aspectRatioWrapper}>
          <ReactPlayer
            ref={videoRef}
            url={cachedVideoUrl || getWistiaUrl({idKey, t})}
            config={
              isUsingOffline
                ? getConfigForOffline({
                    fallbackCacheCaptionsUrl,
                    fallbackCacheThumbnail,
                    currentLanguage,
                    currentSubtitlesLanguage,
                    videosSpec,
                  })
                : getConfigForWistia({
                    currentSubtitlesLanguage,
                    currentLanguage,
                    idKey,
                  })
            }
            width="100%"
            height="100%"
            css={styles.player}
            onDuration={setDuration}
            controls
            {...props}
          />
        </div>
      </div>
    </figure>
  )
)

Video.propTypes = {
  idKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  duration: PropTypes.number,
  containerCss: cssPropType,
  audioClipKey: PropTypes.string,
  fallbackCacheKey: PropTypes.string,
  fallbackCacheCaptionsUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  fallbackCacheThumbnail: imageSourcePropType,
  hasSpanishVersion: PropTypes.bool,
  currentSubtitlesLanguage: PropTypes.string,
  ...ReactPlayer.propTypes,
}

export default enhance(Video)

const styles = {
  // necessary to support eg Edge 16:
  // https://css-tricks.com/aspect-ratio-boxes/#comment-1652911
  aspectRatioWrapperWrapper: {},
  aspectRatioWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerRowContainer: {
    display: 'flex',
    [mq.mobile]: {
      flexDirection: 'column',
    },
    [mq.tablet]: {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    marginBottom: 10,
  },
  header: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 600,
    marginRight: 19,
  },
  duration: {
    fontSize: 18,
    lineHeight: '24px',
  },
}
