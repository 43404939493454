import React from 'react'
import PropTypes from 'prop-types'
import {addProps} from 'ad-hok'
import {flow, get as fget} from 'lodash/fp'
import {findIndex} from 'lodash'

import {addTranslationHelpers} from 'util/i18n'
import Button from 'components/Button'
import colors from 'style/colors'
import leftCaretIcon from 'assets/images/left-caret-clinician-nav.svg'
import rightCaretIcon from 'assets/images/right-caret-clinician-nav.svg'
import {clinicianPages} from 'pages/ClinicianHome'
import {maxWidth} from 'style/layout'
import {mq} from 'style/mediaQueries'
import {classNamePropType} from 'util/propTypes'

const getPreviousNextPageFromClinicianPage = ({key, linkTo, useTrans}) => ({
  translationKey: `clinicianHomePage.pages.${key}`,
  linkTo,
  useTrans,
})

const getUseCurrentPage = currentPage =>
  currentPage === 'strokeRiskPercentages' ? 'strokeRiskFactors' : currentPage

const findCurrentPageIndex = flow(
  fget('currentPage'),
  getUseCurrentPage,
  useCurrentPage => findIndex(clinicianPages, {key: useCurrentPage})
)

const getPreviousPage = flow(
  findCurrentPageIndex,
  currentPageIndex => {
    if (currentPageIndex === 0)
      return {translationKey: 'clinicianNav.home', linkTo: '/clinician/home'}
    return flow(
      () => clinicianPages[currentPageIndex - 1],
      getPreviousNextPageFromClinicianPage
    )()
  }
)

const getNextPage = flow(
  findCurrentPageIndex,
  currentPageIndex => {
    if (currentPageIndex === clinicianPages.length - 1) return null
    return flow(
      () => clinicianPages[currentPageIndex + 1],
      getPreviousNextPageFromClinicianPage
    )()
  }
)

const ClinicianNav = flow(
  addProps(({currentPage}) => ({
    previousPage: getPreviousPage({currentPage}),
    nextPage: getNextPage({currentPage}),
  })),
  addTranslationHelpers,
  ({previousPage, nextPage, currentPage, lightText, className, t}) => (
    <footer css={styles.maxWidthContainer}>
      <nav
        className={className}
        css={[styles.container, lightText && styles.containerLightText]}
      >
        <Button
          mode="plain"
          css={[styles.previousNextContainer, styles.previousContainer]}
          linkTo={previousPage.linkTo}
          disabled={!previousPage}
        >
          {previousPage && (
            <>
              <div
                css={[
                  styles.previousNextRowContainer,
                  styles.previousNextRowContainerFirst,
                ]}
              >
                <div
                  css={[
                    styles.previousNextButtonColumnContainer,
                    styles.previousNextButtonColumnContainerEmpty,
                    styles.previousButtonColumnContainer,
                  ]}
                />
                <em css={[styles.previousNextLabel, styles.previousLabel]}>
                  {t('clinicianNav.previous')}
                </em>
              </div>
              <div
                css={[
                  styles.previousNextRowContainer,
                  styles.previousNextRowContainerSecond,
                ]}
              >
                <div
                  css={[
                    styles.previousNextButtonColumnContainer,
                    styles.previousButtonColumnContainer,
                  ]}
                >
                  <img
                    src={leftCaretIcon}
                    alt=""
                    data-testid={`clinician-nav-back-${currentPage}`}
                  />
                </div>
                <span
                  css={[styles.previousNextPageLabel, styles.previousPageLabel]}
                >
                  {previousPage.useTrans
                    ? previousPage.useTrans({
                        i18nKey: previousPage.translationKey,
                      })
                    : t(previousPage.translationKey)}
                </span>
              </div>
            </>
          )}
        </Button>
        <Button
          mode="plain"
          css={[
            styles.previousNextContainer,
            styles.nextContainer,
            !nextPage && styles.nextContainerDisabled,
          ]}
          linkTo={nextPage && nextPage.linkTo}
          disabled={!nextPage}
        >
          {nextPage && (
            <>
              <div css={styles.previousNextRowContainer}>
                <em css={[styles.previousNextLabel, styles.nextLabel]}>
                  {t('clinicianNav.next')}
                </em>
                <div
                  css={[
                    styles.previousNextButtonColumnContainer,
                    styles.previousNextButtonColumnContainerEmpty,
                    styles.nextButtonColumnContainer,
                  ]}
                />
              </div>
              <div css={styles.previousNextRowContainer}>
                <span
                  css={[styles.previousNextPageLabel, styles.nextPageLabel]}
                >
                  {nextPage.useTrans
                    ? nextPage.useTrans({i18nKey: nextPage.translationKey})
                    : t(nextPage.translationKey)}
                </span>
                <div
                  css={[
                    styles.previousNextButtonColumnContainer,
                    styles.nextButtonColumnContainer,
                  ]}
                >
                  <img
                    src={rightCaretIcon}
                    alt=""
                    data-testid={`clinician-nav-forward-${currentPage}`}
                  />
                </div>
              </div>
            </>
          )}
        </Button>
      </nav>
    </footer>
  )
)

ClinicianNav.propTypes = {
  currentPage: PropTypes.string.isRequired,
  lightText: PropTypes.bool,
  className: classNamePropType,
}

export default ClinicianNav

const styles = {
  maxWidthContainer: {
    width: '100%',
    maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    [mq.mobile]: {
      marginLeft: 10,
      marginRight: 10,
    },
    [mq.tablet]: {
      marginLeft: 25,
      marginRight: 25,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: colors.darkerGrey,
    marginBottom: 20,
    marginTop: 40,
  },
  containerLightText: {
    color: colors.white,
  },
  previousNextContainer: {
    [mq.mobile]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [mq.tablet]: {
      flexDirection: 'column',
      alignItems: 'normal',
    },
    display: 'flex',
  },
  previousContainer: {
    [mq.tablet]: {
      alignItems: 'flex-start',
    },
  },
  nextContainer: {
    [mq.tablet]: {
      alignItems: 'flex-end',
    },
    marginLeft: 40,
  },
  previousNextRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  previousNextButtonColumnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 51,
  },
  previousButtonColumnContainer: {
    justifyContent: 'flex-start',
    marginRight: 5,
  },
  nextButtonColumnContainer: {
    [mq.mobile]: {
      marginLeft: 5,
    },
    [mq.tablet]: {
      marginLeft: 14,
    },
    justifyContent: 'flex-end',
  },
  previousNextLabel: {
    [mq.mobile]: {
      fontWeight: 500,
    },
    [mq.tablet]: {
      fontWeight: 700,
    },
    fontSize: 18,
    lineHeight: 'normal',
  },
  previousLabel: {
    textAlign: 'left',
  },
  nextLabel: {
    textAlign: 'right',
  },
  previousNextPageLabel: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.tablet]: {
      display: 'inline',
    },
    fontSize: 18,
    lineHeight: 'normal',
    fontWeight: 500,
    maxWidth: '20em',
  },
  previousPageLabel: {
    textAlign: 'left',
  },
  nextPageLabel: {
    textAlign: 'right',
  },
  nextContainerDisabled: {
    display: 'none',
  },
  previousNextRowContainerFirst: {
    [mq.mobile]: {
      order: 2,
    },
    [mq.tablet]: {
      order: 1,
    },
  },
  previousNextRowContainerSecond: {
    [mq.mobile]: {
      order: 1,
    },
    [mq.tablet]: {
      order: 2,
    },
  },
  previousNextButtonColumnContainerEmpty: {
    [mq.mobile]: {
      display: 'none',
    },
    [mq.tablet]: {
      display: 'flex',
    },
  },
}
